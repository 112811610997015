export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date (isoformat) */
  Date: any;
  /** Date with time (isoformat) */
  DateTime: any;
  /** Decimal (fixed-point) */
  Decimal: any;
  UUID: any;
  /** BigInt field */
  Union: any;
  join__FieldSet: any;
  link__Import: any;
};

export type AsinCoordinatorMapping = {
  __typename?: 'ASINCoordinatorMapping';
  asin?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  coordinatorName?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  sellerCentralAccount?: Maybe<Scalars['String']>;
};

export type AcosDeleteExcludedAsinsRequestInput = {
  excludedAsinIds: Array<Scalars['UUID']>;
};

export type AcosDeleteExcludedAsinsResponse = {
  __typename?: 'AcosDeleteExcludedAsinsResponse';
  errors: Array<Scalars['String']>;
};

export type AcosExcludedAsin = Timestamped & {
  __typename?: 'AcosExcludedAsin';
  asin: Scalars['String'];
  countryCode: Scalars['String'];
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  task?: Maybe<AcosExclusionTask>;
  updatedOn: Scalars['DateTime'];
  userEmail: Scalars['String'];
  userName: Scalars['String'];
};

export type AcosExcludedAsinsResponse = Pageable & {
  __typename?: 'AcosExcludedAsinsResponse';
  errors: Array<Scalars['String']>;
  results: Array<AcosExcludedAsin>;
  totalResults: Scalars['Int'];
};

export type AcosExclusionTask = Timestamped & {
  __typename?: 'AcosExclusionTask';
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  s3Path: Scalars['String'];
  status: Scalars['String'];
  updatedOn: Scalars['DateTime'];
  userEmail: Scalars['String'];
  userName: Scalars['String'];
};

export type AcosTargetAsinChangeReport = {
  __typename?: 'AcosTargetAsinChangeReport';
  brand?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  currentAspAssumed?: Maybe<Scalars['Float']>;
  engineAdjustedAcos?: Maybe<Scalars['Float']>;
  heroDesignation?: Maybe<Scalars['String']>;
  newAcosTarget?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['String']>;
  pricingStrategy?: Maybe<Scalars['String']>;
  quartileAcosTarget?: Maybe<Scalars['Float']>;
  ruleIgnoredReason?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  syndicatedTarget?: Maybe<Scalars['Float']>;
};

export type AcosTargetAuditLog = {
  __typename?: 'AcosTargetAuditLog';
  asin: Scalars['ID'];
  asinTier?: Maybe<Scalars['String']>;
  assumedPrice?: Maybe<Scalars['Float']>;
  brandName: Scalars['String'];
  changeId: Scalars['UUID'];
  countryCode: Scalars['String'];
  currentAcos?: Maybe<Scalars['Float']>;
  currentPrice?: Maybe<Scalars['Float']>;
  newAcos: Scalars['Float'];
  parentAsin?: Maybe<Scalars['String']>;
  segmentName: Scalars['String'];
  status: Scalars['String'];
  updatedOn: Scalars['DateTime'];
  user: Scalars['String'];
};

export type AcosTargetAuditLogsResponse = {
  __typename?: 'AcosTargetAuditLogsResponse';
  auditLogs: Array<AcosTargetAuditLog>;
  errors: Array<Scalars['String']>;
};

export type AcosTargetChangeReportResponse = {
  __typename?: 'AcosTargetChangeReportResponse';
  changeReport: Array<AcosTargetAsinChangeReport>;
};

export type AcosTargetDeclineReasonType = ActiveTimestamped & {
  __typename?: 'AcosTargetDeclineReasonType';
  active: Scalars['Boolean'];
  createdOn: Scalars['DateTime'];
  displayText: Scalars['String'];
  id: Scalars['ID'];
  type: Scalars['String'];
  updatedOn: Scalars['DateTime'];
};

export type AcosTargetDeclineReasonsResponse = {
  __typename?: 'AcosTargetDeclineReasonsResponse';
  declineReasonTypes: Array<AcosTargetDeclineReasonType>;
  errors: Array<Scalars['String']>;
};

export type AcosTargetExcludedAsinQuickAddInput = {
  asinMarkets: Array<AsinMarket>;
  user: AcosTargetUserData;
};

export type AcosTargetExcludedAsinQuickAddResponse = {
  __typename?: 'AcosTargetExcludedAsinQuickAddResponse';
  errors: Array<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type AcosTargetExclusionImportInput = {
  exclusionTaskId: Scalars['UUID'];
};

export type AcosTargetExclusionUpdateResponse = {
  __typename?: 'AcosTargetExclusionUpdateResponse';
  taskId: Scalars['UUID'];
};

export type AcosTargetExclusionUpdateResultResponse = {
  __typename?: 'AcosTargetExclusionUpdateResultResponse';
  errors: Array<Scalars['String']>;
  numAsinsAdded: Scalars['Int'];
};

export type AcosTargetExclusionUpdateStatusRequestInput = {
  taskId: Scalars['UUID'];
};

export type AcosTargetExclusionUpdateStatusResponse = {
  __typename?: 'AcosTargetExclusionUpdateStatusResponse';
  taskId: Scalars['UUID'];
  taskResult?: Maybe<AcosTargetExclusionUpdateResultResponse>;
  taskStatus: Scalars['String'];
};

export type AcosTargetExclusionUploadRequestInput = {
  user: AcosTargetUserData;
};

export type AcosTargetExclusionUploadResponse = {
  __typename?: 'AcosTargetExclusionUploadResponse';
  exclusionSubmitTaskId: Scalars['UUID'];
  s3PresignedPostUrl: S3PresignedPostUrl;
};

export type AcosTargetExclusionValidationInput = {
  exclusionTaskId: Scalars['UUID'];
  fileKey: Scalars['String'];
  user: AcosTargetUserData;
};

export type AcosTargetExclusionValidationResponse = {
  __typename?: 'AcosTargetExclusionValidationResponse';
  asinsToUpdate: Scalars['Int'];
  errors: Array<Scalars['String']>;
  isValid: Scalars['Boolean'];
};

export type AcosTargetReviewAcceptInput = {
  changeIds: Array<Scalars['UUID']>;
  user: AcosTargetUserData;
};

export type AcosTargetReviewChangeResponse = {
  __typename?: 'AcosTargetReviewChangeResponse';
  asin: Scalars['ID'];
  asinTier?: Maybe<Scalars['String']>;
  assumedPrice?: Maybe<Scalars['Float']>;
  brandName: Scalars['String'];
  changeBps: Scalars['Int'];
  changeId: Scalars['UUID'];
  countryCode: Scalars['String'];
  currentAcos?: Maybe<Scalars['Float']>;
  currentPrice?: Maybe<Scalars['Float']>;
  grossMargin?: Maybe<Scalars['Float']>;
  newAcos: Scalars['Float'];
  parentAsin?: Maybe<Scalars['String']>;
  ppcProfitMargin?: Maybe<Scalars['Float']>;
  rawNewAcos?: Maybe<Scalars['Float']>;
  segmentName: Scalars['String'];
  trailingAcos: Scalars['Float'];
  trailingSpend: Scalars['Float'];
};

export type AcosTargetReviewChangesResponse = {
  __typename?: 'AcosTargetReviewChangesResponse';
  errors: Array<Scalars['String']>;
  lastRunTime: Scalars['String'];
  results: Array<AcosTargetReviewChangeResponse>;
};

export type AcosTargetReviewDeclineInput = {
  changeId: Scalars['UUID'];
  reason?: InputMaybe<Scalars['String']>;
  reasonType: Scalars['String'];
};

export type AcosTargetReviewDeclinesInput = {
  declines: Array<AcosTargetReviewDeclineInput>;
  user: AcosTargetUserData;
};

export type AcosTargetSettingsResponse = {
  __typename?: 'AcosTargetSettingsResponse';
  errors: Array<Scalars['String']>;
  settings: SegmentToolSettings;
  taskStatus?: Maybe<AcosTargetTaskStatusData>;
};

export type AcosTargetSubmissionResponse = {
  __typename?: 'AcosTargetSubmissionResponse';
  errors: Array<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<AcosTargetTaskStatusData>;
};

export type AcosTargetTaskStatusData = {
  __typename?: 'AcosTargetTaskStatusData';
  averageCompletionTimeSeconds?: Maybe<Scalars['Int']>;
  elapsedTimeSeconds?: Maybe<Scalars['Int']>;
  isComplete: Scalars['Boolean'];
  syndicationCompletedOn?: Maybe<Scalars['DateTime']>;
  taskId?: Maybe<Scalars['UUID']>;
  timeTillNextRunSeconds: Scalars['Int'];
};

export type AcosTargetTaskStatusResponse = {
  __typename?: 'AcosTargetTaskStatusResponse';
  errors: Array<Scalars['String']>;
  taskStatus: AcosTargetTaskStatusData;
};

export type AcosTargetUserData = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export type ActiveTimestamped = {
  active: Scalars['Boolean'];
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedOn: Scalars['DateTime'];
};

export type AffiliateDownloadUrlRequest = {
  ingestionFile: Scalars['Boolean'];
  validationId: Scalars['Int'];
};

export type AffiliatePartner = {
  __typename?: 'AffiliatePartner';
  partnerId: Scalars['String'];
  partnerName: Scalars['String'];
  validationId?: Maybe<Scalars['Int']>;
  validationStatus?: Maybe<Scalars['String']>;
};

export type AffiliatePartnerRequestInput = {
  invoiceDate: Scalars['Date'];
  partnerType: Scalars['String'];
};

export type AffiliatePartnerRequestResponse = {
  __typename?: 'AffiliatePartnerRequestResponse';
  partners?: Maybe<Array<AffiliatePartner>>;
};

export type AffiliateRequestValidationErrors = {
  __typename?: 'AffiliateRequestValidationErrors';
  message: Scalars['String'];
  status: Scalars['String'];
};

export type AffiliateRequestValidationResponse = {
  __typename?: 'AffiliateRequestValidationResponse';
  message: Scalars['String'];
};

export type AffiliateRequestValidationResponseAffiliateRequestValidationErrors = AffiliateRequestValidationErrors | AffiliateRequestValidationResponse;

export type AffiliateS3PresignedDownloadUrl = {
  __typename?: 'AffiliateS3PresignedDownloadUrl';
  error: Scalars['String'];
  urls: Array<Scalars['String']>;
};

export type AffiliateS3PresignedPostUrl = {
  __typename?: 'AffiliateS3PresignedPostUrl';
  fields: Scalars['String'];
  key: Scalars['String'];
  url: Scalars['String'];
};

export type AffiliateS3PresignedPostUrlInput = {
  fileType: Scalars['String'];
  validationId: Scalars['Int'];
};

export type AffiliateValidation = {
  __typename?: 'AffiliateValidation';
  createdOn: Scalars['Date'];
  id: Scalars['Int'];
  invoiceDate: Scalars['Date'];
  market: Scalars['String'];
  partnerId: Scalars['String'];
  partnerName: Scalars['String'];
  partnerType: Scalars['String'];
  s3DestinationUri: Scalars['String'];
  s3IngestionUri: Scalars['String'];
  source: Scalars['String'];
  status: Scalars['String'];
  useDefault: Scalars['Boolean'];
};

export type AffiliateValidationErrorRequestInput = {
  validationId: Scalars['Int'];
};

export type AffiliateValidationErrorResponse = {
  __typename?: 'AffiliateValidationErrorResponse';
  results: Array<AffiliateValidationErrorResult>;
};

export type AffiliateValidationErrorResult = {
  __typename?: 'AffiliateValidationErrorResult';
  column: Scalars['String'];
  errorDescription: Scalars['String'];
  errorType: Scalars['String'];
  rows: Array<Scalars['String']>;
};

export type AffiliateValidationRequestInput = {
  invoiceDate?: InputMaybe<Scalars['Date']>;
  market?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['String']>;
  partnerName?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  validationId?: InputMaybe<Scalars['Int']>;
};

export type AffiliateValidationUpdateInput = {
  invoiceDate?: InputMaybe<Scalars['String']>;
  market?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['String']>;
  s3IngestionUrl?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  useDefault?: InputMaybe<Scalars['Boolean']>;
  validationId?: InputMaybe<Scalars['Int']>;
};

export type AffiliateValidationUpdateResponse = {
  __typename?: 'AffiliateValidationUpdateResponse';
  message: Scalars['String'];
};

export type AffiliateValidationUpdateResponseAffiliateValidationUpdateResponseError = AffiliateValidationUpdateResponse | AffiliateValidationUpdateResponseError;

export type AffiliateValidationUpdateResponseError = {
  __typename?: 'AffiliateValidationUpdateResponseError';
  message: Scalars['String'];
  status: Scalars['String'];
};

export type AkeneoDeleteResponse = {
  __typename?: 'AkeneoDeleteResponse';
  errors?: Maybe<Array<Scalars['String']>>;
  success?: Maybe<Array<Scalars['String']>>;
};

export type AmazonBestSellerRank = {
  __typename?: 'AmazonBestSellerRank';
  category: Scalars['String'];
  rank: Scalars['Int'];
  rankScrapedTimestamp: Scalars['DateTime'];
};

export type AmazonListing = {
  __typename?: 'AmazonListing';
  aPlusAboutBrand?: Maybe<Scalars['String']>;
  aPlusDescription?: Maybe<Scalars['String']>;
  aPlusProductDescription?: Maybe<Scalars['String']>;
  /** Amazon url showcasing the amazon choice badge for the listing */
  amazonChoiceBadge?: Maybe<Scalars['String']>;
  /** Matching violations for content in the listing */
  complianceViolations: Array<ComplianceViolation>;
  currentPriceInCents?: Maybe<Scalars['Int']>;
  /** Bullet points describing the features of the product listing */
  featureBulletsContent?: Maybe<Array<Scalars['String']>>;
  featureBulletsCount?: Maybe<Scalars['Int']>;
  hasAPlusContent?: Maybe<Scalars['Boolean']>;
  hasPromotion?: Maybe<Scalars['Boolean']>;
  imageDimension?: Maybe<Scalars['String']>;
  imagesCount?: Maybe<Scalars['Int']>;
  keywords?: Maybe<Array<Scalars['String']>>;
  lastReviewDate?: Maybe<Scalars['DateTime']>;
  /** Calculated overall score for the health of the listing */
  listingScore: Scalars['Float'];
  listingUrl?: Maybe<Scalars['String']>;
  parentAsin?: Maybe<Scalars['String']>;
  priceCurrency?: Maybe<Scalars['String']>;
  priceCurrencySymbol?: Maybe<Scalars['String']>;
  /** Text content describing any current promotion of the listing on Amazon */
  promotionContent?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  ratingsCount?: Maybe<Scalars['Int']>;
  /** Caluclated recommended price for the product listing */
  recommendedPrice?: Maybe<Scalars['Float']>;
  reviewsCount?: Maybe<Scalars['Int']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** Issues with the text of the title for the listing */
  titleIssues?: Maybe<ListingTitleIssues>;
  updatedOn?: Maybe<Scalars['DateTime']>;
  videosCount?: Maybe<Scalars['Int']>;
};

export type AmazonListingResult = {
  __typename?: 'AmazonListingResult';
  results: Array<AmazonListing>;
};

export type AmazonStore = {
  __typename?: 'AmazonStore';
  active: Scalars['Boolean'];
  id: Scalars['ID'];
  marketCode: Scalars['String'];
  merchantToken?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  queen?: Maybe<Scalars['Boolean']>;
  spapiStoreName: Scalars['String'];
};

export enum AnomalyDetectionStatus {
  AnomaliesDetectedAndAccepted = 'ANOMALIES_DETECTED_AND_ACCEPTED',
  AnomaliesDetectedAndRejected = 'ANOMALIES_DETECTED_AND_REJECTED',
  AnomaliesDetectedNotAddressed = 'ANOMALIES_DETECTED_NOT_ADDRESSED',
  NoAnomaliesDetected = 'NO_ANOMALIES_DETECTED',
  PendingDetection = 'PENDING_DETECTION'
}

export type AsinMarket = {
  asin: Scalars['String'];
  market: Scalars['String'];
};

export type AssignTpidToChannelKeyInput = {
  items: Array<AssignTpidToChannelKeyItem>;
  newTpidValue?: InputMaybe<Scalars['String']>;
};

export type AssignTpidToChannelKeyItem = {
  acquiredBrand: Scalars['String'];
  channelKey: Scalars['String'];
  channelName?: InputMaybe<Scalars['String']>;
  channelSku?: InputMaybe<Scalars['String']>;
  fulfillmentType?: InputMaybe<Scalars['String']>;
};

export type AssignTpidToChannelKeyResponse = {
  __typename?: 'AssignTPIDToChannelKeyResponse';
  newTpidValue: Scalars['Union'];
  results: Array<TpidResult>;
};

export type AvatarImageUrl = {
  __typename?: 'AvatarImageURL';
  size: Scalars['String'];
  url: Scalars['String'];
};

export type B2BListingPriceHistory = Timestamped & {
  __typename?: 'B2BListingPriceHistory';
  createdOn: Scalars['DateTime'];
  discountPrices: Array<B2BQuantityDiscountPrice>;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  id: Scalars['ID'];
  listing: PricingCatalogListing;
  priceCurrency: Scalars['String'];
  regularPrice: Scalars['Float'];
  salePrice: Scalars['Float'];
  updatedOn: Scalars['DateTime'];
};

export type B2BQuantityDiscountPrice = Timestamped & {
  __typename?: 'B2BQuantityDiscountPrice';
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  price: Scalars['Float'];
  priceCurrency: Scalars['String'];
  priceHistory: B2BListingPriceHistory;
  quantityDiscountType: Scalars['String'];
  quantityTier: Scalars['Int'];
  updatedOn: Scalars['DateTime'];
};

export type B2CPricingHistoryResponse = Pageable & {
  __typename?: 'B2CPricingHistoryResponse';
  results: Array<ListingPriceHistory>;
  totalResults: Scalars['Int'];
};

export type BarCode = {
  __typename?: 'BarCode';
  createdAt: Scalars['DateTime'];
  id?: Maybe<Scalars['ID']>;
  rawSku: RawSku;
  source: Scalars['String'];
  sourceType: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type BarCodeSource = {
  __typename?: 'BarCodeSource';
  name: Scalars['String'];
  type: Scalars['String'];
};

export type BarCodeSourceType = {
  __typename?: 'BarCodeSourceType';
  name: Scalars['String'];
};

export type BarCodeType = {
  __typename?: 'BarCodeType';
  name: Scalars['String'];
};

export type BarCodeTypeResponse = Pageable & {
  __typename?: 'BarCodeTypeResponse';
  results: Array<BarCodeType>;
  totalResults: Scalars['Int'];
};

/**
 * Extend BaseError with your own error if you need something more custom.
 * Otherwise, GenericError should be able to cover 80%+ cases.
 */
export type BaseError = {
  message?: Maybe<Scalars['String']>;
};

export type BaseTypeaheadResponse = {
  prefixes: Array<Scalars['String']>;
  suggestions: Array<BaseTypeaheadSuggestion>;
};

export type BaseTypeaheadSuggestion = {
  displayText: Scalars['String'];
  key: Scalars['String'];
};

export type BaseUnitType = {
  __typename?: 'BaseUnitType';
  name: Scalars['String'];
};

export type BasicMutationResponseInterface = {
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
};

export type BidAccountInfo = {
  __typename?: 'BidAccountInfo';
  accountName: Scalars['String'];
  profileId: Scalars['String'];
};

export type BidCalculationInfo = {
  __typename?: 'BidCalculationInfo';
  acos?: Maybe<Price>;
  campaignBudget?: Maybe<Scalars['Float']>;
  clicks?: Maybe<Scalars['Int']>;
  impressions?: Maybe<Scalars['Int']>;
  orders7d?: Maybe<Scalars['Int']>;
  ppcRevenue?: Maybe<Price>;
  sales7d?: Maybe<Price>;
  spend?: Maybe<Price>;
};

export type BidCampaignDetails = {
  __typename?: 'BidCampaignDetails';
  campaignName?: Maybe<Scalars['String']>;
  campaignStatus?: Maybe<BidManagementCampaignStatus>;
  keywordBid: Price;
  parentCampaignStatus?: Maybe<BidManagementCampaignStatus>;
};

export type BidId = {
  __typename?: 'BidID';
  account: Scalars['String'];
  adminType: Scalars['String'];
  brandName: Scalars['String'];
  campaignId: Scalars['String'];
  keywordText?: Maybe<Scalars['String']>;
  profileId: Scalars['String'];
  targetId: Scalars['String'];
  targetType: Scalars['String'];
};

export type BidManagementAccountOptionsResponse = {
  __typename?: 'BidManagementAccountOptionsResponse';
  accounts: Array<BidAccountInfo>;
  errors?: Maybe<BidManagementDataResponseError>;
};

export enum BidManagementCampaignStatus {
  Archived = 'ARCHIVED',
  Enabled = 'ENABLED',
  Paused = 'PAUSED'
}

export type BidManagementData = {
  __typename?: 'BidManagementData';
  asin: Scalars['ID'];
  bidId: BidId;
  calculated?: Maybe<BidCalculationInfo>;
  details: BidCampaignDetails;
  pulledTime: Scalars['DateTime'];
};

export type BidManagementDataResponse = Pageable & {
  __typename?: 'BidManagementDataResponse';
  results: Array<BidManagementData>;
  totalResults: Scalars['Int'];
};

export type BidManagementDataResponseError = {
  __typename?: 'BidManagementDataResponseError';
  message: Scalars['String'];
  status: Scalars['String'];
};

export type BidManagementDataResponseResult = {
  __typename?: 'BidManagementDataResponseResult';
  data: BidManagementDataResponse;
  errors?: Maybe<Array<BidManagementDataResponseError>>;
};

export type BidManagementDataStatusRequestInput = {
  taskId: Scalars['String'];
};

export type BidManagementDataStatusResponse = {
  __typename?: 'BidManagementDataStatusResponse';
  taskId: Scalars['String'];
  taskResult?: Maybe<BidManagementDataResponseResult>;
  taskStatus: Scalars['String'];
};

export type BidManagementDataTaskResponse = {
  __typename?: 'BidManagementDataTaskResponse';
  taskId: Scalars['String'];
};

export type BidManagementKeywordUpdateRequestInput = {
  user: BidManagementUserData;
  validationRecordId: Scalars['Int'];
};

export type BidManagementKeywordUpdateResponse = {
  __typename?: 'BidManagementKeywordUpdateResponse';
  errors: Array<Scalars['String']>;
  isSuccessful: Scalars['Boolean'];
  targetIds: Array<Scalars['String']>;
};

export type BidManagementRequestInput = {
  acosMax?: InputMaybe<Scalars['Float']>;
  acosMin?: InputMaybe<Scalars['Float']>;
  adminType?: InputMaybe<Scalars['String']>;
  asin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bidMax?: InputMaybe<Scalars['Float']>;
  bidMin?: InputMaybe<Scalars['Float']>;
  campaignFilterText?: InputMaybe<Scalars['String']>;
  campaignFilterType?: InputMaybe<Scalars['String']>;
  clicksMin?: InputMaybe<Scalars['Int']>;
  impressionsMin?: InputMaybe<Scalars['Int']>;
  keywordFilter?: InputMaybe<Scalars['String']>;
  parentCampaignStatus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  profileId: Array<Scalars['String']>;
  spendMin?: InputMaybe<Scalars['Float']>;
  statusFilter?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targetType?: InputMaybe<Scalars['String']>;
};

export type BidManagementUserData = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export type BidManagementValStatusRequestInput = {
  taskId: Scalars['String'];
};

export type BidManagementValStatusResponse = {
  __typename?: 'BidManagementValStatusResponse';
  taskId: Scalars['String'];
  taskResult?: Maybe<BidManagementValidationResponse>;
  taskStatus: Scalars['String'];
};

export type BidManagementValTaskResponse = {
  __typename?: 'BidManagementValTaskResponse';
  taskId: Scalars['String'];
};

export type BidManagementValidationNotificationTypeCount = {
  __typename?: 'BidManagementValidationNotificationTypeCount';
  count: Scalars['Int'];
  message: Scalars['String'];
};

export type BidManagementValidationRequestInput = {
  fileKey: Scalars['String'];
  user: BidManagementUserData;
  validationRecordId: Scalars['Int'];
};

export type BidManagementValidationResponse = {
  __typename?: 'BidManagementValidationResponse';
  errors: Array<BidManagementValidationNotificationTypeCount>;
  isValid: Scalars['Boolean'];
  totalCampaignUpdates: Scalars['Int'];
  totalStatusUpdates: Scalars['Int'];
  warnings: Array<BidManagementValidationNotificationTypeCount>;
};

export type BidManagementValidationUploadRequestInput = {
  user: BidManagementUserData;
};

export type BidManagementValidationUploadResponse = {
  __typename?: 'BidManagementValidationUploadResponse';
  s3PresignedPostUrl: S3PresignedPostUrl;
  validationRecordId: Scalars['Int'];
};

export type BooleanChangeEventData = {
  __typename?: 'BooleanChangeEventData';
  fromValue?: Maybe<Scalars['Boolean']>;
  toValue?: Maybe<Scalars['Boolean']>;
};

export type Brand = {
  __typename?: 'Brand';
  createdOn: Scalars['DateTime'];
  /** Unique brand UUID */
  id: Scalars['ID'];
  name: Scalars['String'];
  products: Array<ProductCatalogProduct>;
  /** System ID for linking to other objects */
  thrasioId: Scalars['ID'];
  updatedOn: Scalars['DateTime'];
};

export type BrandAlias = {
  __typename?: 'BrandAlias';
  brand: ProductCatalogBrand;
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type BrandAliasMutation = {
  brand?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type BrandAliasMutationResponse = {
  __typename?: 'BrandAliasMutationResponse';
  error?: Maybe<Scalars['String']>;
  results?: Maybe<Array<BrandAlias>>;
  success: Scalars['Boolean'];
};

export type BrandAliasResponse = Pageable & {
  __typename?: 'BrandAliasResponse';
  results: Array<BrandAlias>;
  totalResults: Scalars['Int'];
};

export type BrandCreate = {
  acquisitionDate?: InputMaybe<Scalars['Date']>;
  divestedDate?: InputMaybe<Scalars['Date']>;
  legacyBrand?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  revenueStartDate?: InputMaybe<Scalars['Date']>;
  status: Scalars['String'];
  wholeBusiness?: InputMaybe<EnumYesNoNa>;
  wholeDivestBusiness?: InputMaybe<EnumYesNoNa>;
};

export type BrandMutationResponse = {
  __typename?: 'BrandMutationResponse';
  error?: Maybe<Scalars['String']>;
  results?: Maybe<Array<ProductCatalogBrand>>;
  success: Scalars['Boolean'];
};

export type BrandResponse = Pageable & {
  __typename?: 'BrandResponse';
  results: Array<ProductCatalogBrand>;
  totalResults: Scalars['Int'];
};

export type BrandResult = Pageable & {
  __typename?: 'BrandResult';
  results: Array<Brand>;
  totalResults: Scalars['Int'];
};

export type BrandStatus = {
  __typename?: 'BrandStatus';
  name: Scalars['String'];
};

export type BrandStatusResponse = Pageable & {
  __typename?: 'BrandStatusResponse';
  results: Array<BrandStatus>;
  totalResults: Scalars['Int'];
};

export type BrandUpdate = {
  acquisitionDate?: InputMaybe<Scalars['Date']>;
  divestedDate?: InputMaybe<Scalars['Date']>;
  legacyBrand?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  newName?: InputMaybe<Scalars['String']>;
  revenueStartDate?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<Scalars['String']>;
  wholeBusiness?: InputMaybe<EnumYesNoNa>;
  wholeDivestBusiness?: InputMaybe<EnumYesNoNa>;
};

export type BrandUserAssignment = {
  __typename?: 'BrandUserAssignment';
  brand: Brand;
  id: Scalars['ID'];
  role: Role;
  team: Team;
  user?: Maybe<InternalUser>;
  userId: Scalars['ID'];
};

export type BrandUserAssignmentCreateInput = {
  brandId: Scalars['ID'];
  roleId: Scalars['ID'];
  teamId: Scalars['ID'];
  userId: Scalars['String'];
};

export type BrandUserAssignmentDeleteInput = {
  id: Scalars['ID'];
};

export type BrandUserAssignmentDeleteResult = {
  __typename?: 'BrandUserAssignmentDeleteResult';
  success: Scalars['Boolean'];
};

export type BrandUserAssignmentResult = Pageable & {
  __typename?: 'BrandUserAssignmentResult';
  results: Array<BrandUserAssignment>;
  totalResults: Scalars['Int'];
};

export type BrandUserAssignmentUpdateInput = {
  brandId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  roleId?: InputMaybe<Scalars['ID']>;
  teamId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type BulkEditProductReportingSubscriptionResponse = GenericError | ProductReportingSubscriptionBulkEdited;

export type BulkUpdateResponse = {
  __typename?: 'BulkUpdateResponse';
  error?: Maybe<Scalars['String']>;
  results?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type BusinessUnit = {
  __typename?: 'BusinessUnit';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CsvUploadInput = {
  action: Scalars['String'];
  fileName: Scalars['String'];
  userEmail: Scalars['String'];
  userName: Scalars['String'];
};

export type CalculatedPrice = Timestamped & {
  __typename?: 'CalculatedPrice';
  calculationSummary: Scalars['String'];
  createdOn: Scalars['DateTime'];
  errorMessage?: Maybe<Scalars['String']>;
  errorSource?: Maybe<Scalars['String']>;
  errorType?: Maybe<Scalars['String']>;
  finalMargin?: Maybe<Scalars['Float']>;
  finalPrice?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  marketCode: Scalars['String'];
  priceCalcTargetId: Scalars['String'];
  ruleCalculatedPrice?: Maybe<Scalars['Float']>;
  ruleSetExecutionId: Scalars['String'];
  status: Scalars['String'];
  updatedOn: Scalars['DateTime'];
  violations: Array<CalculatedPriceViolation>;
};

export type CalculatedPriceHistoryResolver = {
  __typename?: 'CalculatedPriceHistoryResolver';
  results: Array<CalculatedPrice>;
};

export type CalculatedPriceOverride = ActiveTimestamped & {
  __typename?: 'CalculatedPriceOverride';
  active: Scalars['Boolean'];
  comments?: Maybe<Array<CalculatedPriceOverrideComment>>;
  createdOn: Scalars['DateTime'];
  creatorId?: Maybe<Scalars['String']>;
  end: Scalars['DateTime'];
  id: Scalars['ID'];
  overrideSource?: Maybe<CalculatedPriceOverrideSource>;
  price: Scalars['Float'];
  priceCalculationTargetId: Scalars['String'];
  start: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
};

export type CalculatedPriceOverrideComment = ActiveTimestamped & {
  __typename?: 'CalculatedPriceOverrideComment';
  active: Scalars['Boolean'];
  comment: Scalars['String'];
  commenter: InternalUser;
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  isEdited: Scalars['Boolean'];
  updatedOn: Scalars['DateTime'];
};

export type CalculatedPriceOverrideCommentsResponse = Pageable & {
  __typename?: 'CalculatedPriceOverrideCommentsResponse';
  results: Array<CalculatedPriceOverrideComment>;
  totalResults: Scalars['Int'];
};

export type CalculatedPriceOverrideMaxDurationResponse = {
  __typename?: 'CalculatedPriceOverrideMaxDurationResponse';
  result: Scalars['Int'];
};

export enum CalculatedPriceOverrideSource {
  HoldingBinOverride = 'HOLDING_BIN_OVERRIDE',
  ManualOverride = 'MANUAL_OVERRIDE',
  StateMachineOverride = 'STATE_MACHINE_OVERRIDE'
}

export type CalculatedPriceOverridesResponse = Pageable & {
  __typename?: 'CalculatedPriceOverridesResponse';
  results: Array<CalculatedPriceOverride>;
  totalResults: Scalars['Int'];
};

export type CalculatedPriceViolation = {
  __typename?: 'CalculatedPriceViolation';
  source: Scalars['String'];
  violationType: Scalars['String'];
};

export enum CalculationActionType {
  Add = 'ADD',
  Multiply = 'MULTIPLY',
  Pin = 'PIN'
}

export enum CalculationAppliesTo {
  Margin = 'MARGIN',
  Price = 'PRICE'
}

export type CanaryResponse = {
  __typename?: 'CanaryResponse';
  content: Scalars['String'];
};

export type CancelUploadNewDataFilesError = {
  __typename?: 'CancelUploadNewDataFilesError';
  message: Scalars['String'];
  status: Scalars['String'];
};

export type CancelUploadNewDataFilesInput = {
  validationId: Scalars['Int'];
};

export type CancelUploadNewDataFilesResponse = {
  __typename?: 'CancelUploadNewDataFilesResponse';
  message: Scalars['String'];
};

export type CancelUploadNewDataFilesResponseCancelUploadNewDataFilesError = CancelUploadNewDataFilesError | CancelUploadNewDataFilesResponse;

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Channel = {
  __typename?: 'Channel';
  id: Scalars['ID'];
};

export type ChannelKeyMultipleTpid = {
  __typename?: 'ChannelKeyMultipleTpid';
  channelId?: Maybe<Scalars['String']>;
  channelMarket?: Maybe<Scalars['String']>;
  tpidCounts?: Maybe<Scalars['String']>;
};

export type ChannelKeyMultipleTpidResponse = Pageable & {
  __typename?: 'ChannelKeyMultipleTpidResponse';
  results: Array<ChannelKeyMultipleTpid>;
  totalResults: Scalars['Int'];
};

export type ChannelSku = {
  __typename?: 'ChannelSku';
  brandAlias?: Maybe<BrandAlias>;
  channel: ProductCatalogChannel;
  channelKey: Scalars['String'];
  channelSku: Scalars['String'];
  createdAt: Scalars['DateTime'];
  fulfillmentSku?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isProductExpirable?: Maybe<Scalars['Boolean']>;
  productTpid?: Maybe<ProductTpid>;
  rawSku?: Maybe<RawSku>;
  updatedAt: Scalars['DateTime'];
};

export type ChannelSkuMutationResponse = {
  __typename?: 'ChannelSkuMutationResponse';
  error?: Maybe<Scalars['String']>;
  results?: Maybe<Array<ChannelSku>>;
  success: Scalars['Boolean'];
};

export type ChannelSkuUpdate = {
  brandAlias?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

export type ChannelType = {
  __typename?: 'ChannelType';
  name: Scalars['String'];
};

export type CheckDocumentProcessedInput = {
  documentId: Scalars['String'];
};

export type CheckDocumentProcessedResponse = {
  __typename?: 'CheckDocumentProcessedResponse';
  isProcessed: Scalars['Boolean'];
  message: Array<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CompanyBarcodeOwnershipVerificationStatus = {
  __typename?: 'CompanyBarcodeOwnershipVerificationStatus';
  barcode: BarCode;
  createdAt: Scalars['DateTime'];
  gs1ClaimedAddress?: Maybe<Scalars['String']>;
  gs1ClaimedOwner?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  thrasioGs1CompanyId?: Maybe<Gs1RegisteredCompany>;
  updatedAt: Scalars['DateTime'];
};

export type CompanyBarcodeOwnershipVerificationStatusResponse = Pageable & {
  __typename?: 'CompanyBarcodeOwnershipVerificationStatusResponse';
  results: Array<CompanyBarcodeOwnershipVerificationStatus>;
  totalResults: Scalars['Int'];
};

export enum ComplianceField {
  FeatureBullets = 'FEATURE_BULLETS',
  Keywords = 'KEYWORDS',
  Promotions = 'PROMOTIONS',
  Subtitle = 'SUBTITLE',
  Title = 'TITLE'
}

export type ComplianceViolation = {
  __typename?: 'ComplianceViolation';
  match: Scalars['String'];
  ruleLabel: Scalars['String'];
  sourceContent: ComplianceField;
};

export enum ConditionalPriceCheckTarget {
  Margin = 'MARGIN',
  Price = 'PRICE'
}

export enum ConditionalPriceCheckType {
  GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL',
  LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
  Max = 'MAX',
  Min = 'MIN',
  MinMaxWithCoefficient = 'MIN_MAX_WITH_COEFFICIENT',
  WithinRange = 'WITHIN_RANGE'
}

export type CopyRuleSetInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  ruleSetId: Scalars['ID'];
  ruleSetType: RuleSetType;
};

export type CopyRuleSetResponse = {
  __typename?: 'CopyRuleSetResponse';
  id: Scalars['ID'];
};

export type CreateCalculatedPriceOverrideCommentInput = {
  calculatedPriceOverrideId: Scalars['String'];
  comment: Scalars['String'];
};

export type CreateCalculatedPriceOverrideInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  bypassIncrementalSyndication?: InputMaybe<Scalars['Boolean']>;
  channel?: InputMaybe<Scalars['String']>;
  channelId?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  end?: InputMaybe<Scalars['DateTime']>;
  fulfillmentChannel?: InputMaybe<Scalars['String']>;
  fullStoreName?: InputMaybe<Scalars['String']>;
  marketCode?: InputMaybe<Scalars['String']>;
  price: Scalars['Decimal'];
  priceCalcTargetId?: InputMaybe<Scalars['String']>;
};

export type CreateHoldingBinEntryCommentInput = {
  comment: Scalars['String'];
  holdingBinEntryId: Scalars['ID'];
};

export type CreateIndividualPriceAdjustmentInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  calcPriceChangeActionType?: InputMaybe<CalculationActionType>;
  calcPriceChangeAmount?: InputMaybe<Scalars['Float']>;
  calcPriceChangeAppliesTo?: InputMaybe<CalculationAppliesTo>;
  calcPriceChangeInputDataSourceId?: InputMaybe<Scalars['ID']>;
  calcPriceChangeInputDataSourceValueId?: InputMaybe<Scalars['ID']>;
  calcPriceChangeInputValueMultiplier?: InputMaybe<Scalars['Float']>;
  checkTarget: ConditionalPriceCheckTarget;
  checkType: ConditionalPriceCheckType;
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  maxValue?: InputMaybe<Scalars['Float']>;
  maxValueInputDataSourceId?: InputMaybe<Scalars['ID']>;
  maxValueInputDataSourceValueId?: InputMaybe<Scalars['ID']>;
  maxValueMultiplier?: InputMaybe<Scalars['Float']>;
  minValue?: InputMaybe<Scalars['Float']>;
  minValueInputDataSourceId?: InputMaybe<Scalars['ID']>;
  minValueInputDataSourceValueId?: InputMaybe<Scalars['ID']>;
  minValueMultiplier?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  negateRequiredRules?: InputMaybe<Scalars['Boolean']>;
  priceMarginFormula?: InputMaybe<PriceMarginFormula>;
  requiredRuleIds?: InputMaybe<Array<Scalars['String']>>;
  ruleSetEntityFilterId?: InputMaybe<Scalars['ID']>;
  ruleSetId: Scalars['ID'];
  skipIfMissingData?: InputMaybe<Scalars['Boolean']>;
  variableAssignments?: InputMaybe<Array<CreateUpdateVariableAssignmentInput>>;
};

export type CreateInputDataSourceInput = {
  applicableEndDateColumnName?: InputMaybe<Scalars['String']>;
  applicableStartDateColumnName?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<SalesChannel>;
  channelColumnName?: InputMaybe<Scalars['String']>;
  channelIdColumnName: Scalars['String'];
  endDateColumnName?: InputMaybe<Scalars['String']>;
  fullStoreNameColumnName?: InputMaybe<Scalars['String']>;
  inputDataSourceValues?: InputMaybe<Array<CreateOrUpdateInputDataSourceValueInput>>;
  market?: InputMaybe<MarketCode>;
  marketColumnName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  refreshTriggers: Array<RefreshTriggerInput>;
  snowflakeDatabaseName: Scalars['String'];
  snowflakeRoleName?: InputMaybe<Scalars['String']>;
  snowflakeSchemaName: Scalars['String'];
  snowflakeTableName: Scalars['String'];
  startDateColumnName?: InputMaybe<Scalars['String']>;
  thrasioProductIdColumnName?: InputMaybe<Scalars['String']>;
  whereClause?: InputMaybe<Scalars['String']>;
};

export type CreateMyEmailSubscriptionInput = {
  listId: Scalars['ID'];
};

export type CreateOrUpdateInputDataSourceValueInput = {
  id?: InputMaybe<Scalars['ID']>;
  inputType?: InputMaybe<InputDataSourceTypes>;
  inputValueColumnName?: InputMaybe<Scalars['String']>;
  tagColumnName?: InputMaybe<Scalars['String']>;
  valueName?: InputMaybe<Scalars['String']>;
};

export type CreatePowerBiDashboardInput = {
  datasetId: Scalars['ID'];
  reportId: Scalars['ID'];
  slug: Scalars['String'];
  workspaceId: Scalars['ID'];
};

export type CreatePriceCalculationTargetGroupInput = {
  groupFollowerIds?: InputMaybe<Array<Scalars['ID']>>;
  groupLeaderId: Scalars['ID'];
};

export type CreatePriceGuardrailInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  checkTarget: ConditionalPriceCheckTarget;
  checkType: ConditionalPriceCheckType;
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  maxValue?: InputMaybe<Scalars['Float']>;
  maxValueInputDataSourceId?: InputMaybe<Scalars['ID']>;
  maxValueInputDataSourceValueId?: InputMaybe<Scalars['ID']>;
  maxValueMultiplier?: InputMaybe<Scalars['Float']>;
  minValue?: InputMaybe<Scalars['Float']>;
  minValueInputDataSourceId?: InputMaybe<Scalars['ID']>;
  minValueInputDataSourceValueId?: InputMaybe<Scalars['ID']>;
  minValueMultiplier?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  negateRequiredRules?: InputMaybe<Scalars['Boolean']>;
  priceMarginFormula?: InputMaybe<PriceMarginFormula>;
  requiredRuleIds?: InputMaybe<Array<Scalars['String']>>;
  ruleSetEntityFilterId?: InputMaybe<Scalars['ID']>;
  ruleSetId: Scalars['ID'];
  skipIfMissingData?: InputMaybe<Scalars['Boolean']>;
  variableAssignments?: InputMaybe<Array<CreateUpdateVariableAssignmentInput>>;
};

export type CreatePriceSyndicationRequestInput = {
  channel?: InputMaybe<SalesChannel>;
  enableCrossChannelSyndication?: InputMaybe<Scalars['Boolean']>;
  isFullUpdate?: InputMaybe<Scalars['Boolean']>;
  pushToFeedonomics?: InputMaybe<Scalars['String']>;
  syndicationReferenceField?: InputMaybe<Scalars['String']>;
  syndicationRequestType: Scalars['String'];
  syndicationUploadedS3Filename?: InputMaybe<Scalars['String']>;
};

export type CreatePriceSyndicationRequestResponse = {
  __typename?: 'CreatePriceSyndicationRequestResponse';
  error?: Maybe<Scalars['String']>;
  listingRequestsCreated: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type CreateProductEventResponse = GenericError | ProductEventCreated;

export type CreateProductReportingSubscriptionResponse = GenericError | ProductReportingSubscriptionCreated;

export type CreatePromotionSyndicationRequestInput = {
  isDryRun?: InputMaybe<Scalars['Boolean']>;
  mutationType: PromotionSyndicationRequestMutationType;
  syndicationUploadedS3Filename?: InputMaybe<Scalars['String']>;
};

export type CreatePromotionSyndicationRequestResponse = {
  __typename?: 'CreatePromotionSyndicationRequestResponse';
  error?: Maybe<Scalars['String']>;
  listingRequestsCreated: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type CreateRuleInput = {
  actionType: CalculationActionType;
  amount?: InputMaybe<Scalars['Decimal']>;
  calcAppliesTo: CalculationAppliesTo;
  calculationAmountInputDataSourceId?: InputMaybe<Scalars['ID']>;
  calculationAmountInputDataSourceValueId?: InputMaybe<Scalars['ID']>;
  description: Scalars['String'];
  enabled?: InputMaybe<Scalars['Boolean']>;
  filterId?: InputMaybe<Scalars['ID']>;
  inputDataSourceId: Scalars['ID'];
  inputDataSourceValueId: Scalars['ID'];
  name: Scalars['String'];
  participateInPriceFormatting?: InputMaybe<Scalars['Boolean']>;
  ruleSetId: Scalars['ID'];
  skipIfMissingData?: InputMaybe<Scalars['Boolean']>;
  useOptimalMarginFormula?: InputMaybe<Scalars['Boolean']>;
  variableAssignments?: InputMaybe<Array<CreateUpdateVariableAssignmentInput>>;
};

export type CreateRuleSetEntityFilterInput = {
  channels?: InputMaybe<Array<SalesChannel>>;
  description?: InputMaybe<Scalars['String']>;
  marketplaces?: InputMaybe<Array<MarketCode>>;
  name: Scalars['String'];
  negateChannels?: Scalars['Boolean'];
  negateMarketplaces?: Scalars['Boolean'];
  negateSegments?: Scalars['Boolean'];
  segmentIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CreateRuleSetInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  ruleSetType: RuleSetType;
};

export type CreateSegmentInput = {
  applicableEndDateColumnName?: InputMaybe<Scalars['String']>;
  applicableStartDateColumnName?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<SalesChannel>;
  channelColumnName?: InputMaybe<Scalars['String']>;
  channelIdColumnName: Scalars['String'];
  endDateColumnName?: InputMaybe<Scalars['String']>;
  fullStoreNameColumnName?: InputMaybe<Scalars['String']>;
  market?: InputMaybe<MarketCode>;
  marketColumnName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  refreshTriggers: Array<RefreshTriggerInput>;
  shouldCreateFilter?: InputMaybe<Scalars['Boolean']>;
  snowflakeDatabaseName: Scalars['String'];
  snowflakeRoleName?: InputMaybe<Scalars['String']>;
  snowflakeSchemaName: Scalars['String'];
  snowflakeTableName: Scalars['String'];
  startDateColumnName?: InputMaybe<Scalars['String']>;
  thrasioProductIdColumnName?: InputMaybe<Scalars['String']>;
  whereClause?: InputMaybe<Scalars['String']>;
};

export type CreateSlackSubscriptionInput = {
  listId: Scalars['ID'];
  slackChannelId: Scalars['ID'];
};

export type CreateUnitEconDataSourceInput = {
  amzFbaFeeColumnName?: InputMaybe<Scalars['String']>;
  applicableEndDateColumnName?: InputMaybe<Scalars['String']>;
  applicableStartDateColumnName?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<SalesChannel>;
  channelColumnName?: InputMaybe<Scalars['String']>;
  channelIdColumnName: Scalars['String'];
  cogsColumnName?: InputMaybe<Scalars['String']>;
  cogsDiscountFactorColumnName?: InputMaybe<Scalars['String']>;
  cogsInflationFactorColumnName?: InputMaybe<Scalars['String']>;
  endDateColumnName?: InputMaybe<Scalars['String']>;
  fullStoreNameColumnName?: InputMaybe<Scalars['String']>;
  inboundHandlingReplacementColumnName?: InputMaybe<Scalars['String']>;
  inboundHandlingVasReplacementColumnName?: InputMaybe<Scalars['String']>;
  market?: InputMaybe<MarketCode>;
  marketColumnName?: InputMaybe<Scalars['String']>;
  middleMileReplacementColumnName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  outboundHandlingReplacementColumnName?: InputMaybe<Scalars['String']>;
  outboundHandlingVasReplacementColumnName?: InputMaybe<Scalars['String']>;
  pricingCogsColumnName?: InputMaybe<Scalars['String']>;
  referralPercentageColumnName?: InputMaybe<Scalars['String']>;
  returnRateColumnName?: InputMaybe<Scalars['String']>;
  snowflakeDatabaseName: Scalars['String'];
  snowflakeRoleName?: InputMaybe<Scalars['String']>;
  snowflakeSchemaName: Scalars['String'];
  snowflakeTableName: Scalars['String'];
  startDateColumnName?: InputMaybe<Scalars['String']>;
  storageTotalColumnName?: InputMaybe<Scalars['String']>;
  thrasioProductIdColumnName?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  whereClause?: InputMaybe<Scalars['String']>;
};

export type CreateUpdatePriceCalculationTargetGroupResponse = {
  __typename?: 'CreateUpdatePriceCalculationTargetGroupResponse';
  error?: Maybe<Scalars['String']>;
  priceCalculationTargetGroup?: Maybe<PriceCalculationTargetGroup>;
  success: Scalars['Boolean'];
};

export type CreateUpdateRuleResponse = {
  __typename?: 'CreateUpdateRuleResponse';
  error?: Maybe<Scalars['String']>;
  rule?: Maybe<Rule>;
  success: Scalars['Boolean'];
};

export type CreateUpdateRuleSetEntityFilterResponse = {
  __typename?: 'CreateUpdateRuleSetEntityFilterResponse';
  error?: Maybe<Scalars['String']>;
  ruleSetEntityFilter?: Maybe<RuleSetEntityFilter>;
  success: Scalars['Boolean'];
};

export type CreateUpdateRuleSetResponse = {
  __typename?: 'CreateUpdateRuleSetResponse';
  error?: Maybe<Scalars['String']>;
  ruleSet?: Maybe<RuleSet>;
  success: Scalars['Boolean'];
};

export type CreateUpdateVariableAssignmentInput = {
  assignmentType?: InputMaybe<VariableAssignmentType>;
  constantValue?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  inputDataSourceValueId?: InputMaybe<Scalars['ID']>;
  variableName?: InputMaybe<Scalars['String']>;
};

export enum Currency {
  Aed = 'aed',
  Aud = 'aud',
  Eur = 'eur',
  Gbp = 'gbp',
  Mxn = 'mxn',
  Usd = 'usd'
}

export type DatetimeChangeEventData = {
  __typename?: 'DatetimeChangeEventData';
  fromValue?: Maybe<Scalars['DateTime']>;
  toValue?: Maybe<Scalars['DateTime']>;
};

export type DeleteHoldingBinEntryCommentInput = {
  id: Scalars['ID'];
};

export type DeleteMyEmailSubscriptionInput = {
  listId: Scalars['ID'];
};

export type DeleteResponse = {
  __typename?: 'DeleteResponse';
  deleted: Scalars['Int'];
};

export type DeleteSlackSubscriptionInput = {
  listId: Scalars['ID'];
  slackChannelId: Scalars['ID'];
};

export type DimUnitsResponse = Pageable & {
  __typename?: 'DimUnitsResponse';
  results: Array<DimensionUnit>;
  totalResults: Scalars['Int'];
};

export type Dimension = {
  __typename?: 'Dimension';
  name: Scalars['String'];
};

export type DimensionUnit = {
  __typename?: 'DimensionUnit';
  name: Scalars['String'];
};

export type DirectorCategory = {
  __typename?: 'DirectorCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Division = {
  __typename?: 'Division';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DocumentRequestInput = {
  documentId: Scalars['String'];
  shippingId: Scalars['String'];
};

export type EditProductReportingSubscriptionResponse = GenericError | ProductReportingSubscriptionEdited;

export type EnableRuleInput = {
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type EnumOption = {
  __typename?: 'EnumOption';
  label: Scalars['String'];
  value: Scalars['String'];
};

export enum EnumSkuServiceType {
  FinishedGood = 'FINISHED_GOOD',
  NonInventoriedItem = 'NON_INVENTORIED_ITEM',
  PackagingComponent = 'PACKAGING_COMPONENT',
  RawMaterial = 'RAW_MATERIAL',
  SoldComponent = 'SOLD_COMPONENT',
  TradingGood = 'TRADING_GOOD'
}

export enum EnumYesNoNa {
  Na = 'NA',
  No = 'NO',
  Yes = 'YES'
}

export type EventData = BooleanChangeEventData | DatetimeChangeEventData | FloatChangeEventData | ImageChangeEventData | IntChangeEventData | TextChangeEventData | TextListChangeEventData | UnknownChangeEventData | UrlChangeEventData | VideoChangeEventData;

export type ExecutePriceRunInput = {
  asOfDate?: InputMaybe<Scalars['DateTime']>;
  ruleSetId: Scalars['ID'];
};

export type ExecutePriceRunResponse = {
  __typename?: 'ExecutePriceRunResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum ExpediteReasons {
  AirFreightLessExpensiveThanSea = 'AIR_FREIGHT_LESS_EXPENSIVE_THAN_SEA',
  CarrierFailure = 'CARRIER_FAILURE',
  CustomerHoldOrAction = 'CUSTOMER_HOLD_OR_ACTION',
  CustomerPullAhead = 'CUSTOMER_PULL_AHEAD',
  CustomerRequestedChange = 'CUSTOMER_REQUESTED_CHANGE',
  DemandIncrease = 'DEMAND_INCREASE',
  LateMarketingInitiative = 'LATE_MARKETING_INITIATIVE',
  MaterialOrBuyerError = 'MATERIAL_OR_BUYER_ERROR',
  Other = 'OTHER',
  PlantIssue = 'PLANT_ISSUE',
  PortClosure = 'PORT_CLOSURE',
  Quality = 'QUALITY',
  QualityIssue = 'QUALITY_ISSUE',
  SoldOutsideOfLeadTimeWindow = 'SOLD_OUTSIDE_OF_LEAD_TIME_WINDOW',
  SupplierLate = 'SUPPLIER_LATE',
  SupplierShipHoldCredit = 'SUPPLIER_SHIP_HOLD_CREDIT',
  SupplierShipHoldPo = 'SUPPLIER_SHIP_HOLD_PO',
  VendorFailure = 'VENDOR_FAILURE',
  Weather = 'WEATHER'
}

export type FbaIdUpdateInput = {
  fbaId: Scalars['String'];
  quantity: Scalars['Int'];
  transferOrders: Array<Scalars['String']>;
};

export type FbaIds = {
  __typename?: 'FbaIds';
  barcodeLink?: Maybe<Scalars['String']>;
  fbaId: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  transferOrders: Array<Scalars['String']>;
};

export enum FilterLogic {
  And = 'AND',
  Or = 'OR'
}

export enum FilterOperator {
  Contains = 'CONTAINS',
  Doesnotcontain = 'DOESNOTCONTAIN',
  Endswith = 'ENDSWITH',
  Eq = 'EQ',
  Gt = 'GT',
  Gte = 'GTE',
  In = 'IN',
  Isempty = 'ISEMPTY',
  Isnotempty = 'ISNOTEMPTY',
  Isnotnull = 'ISNOTNULL',
  Isnull = 'ISNULL',
  Lt = 'LT',
  Lte = 'LTE',
  Neq = 'NEQ',
  Range = 'RANGE',
  Startswith = 'STARTSWITH'
}

export enum FilterType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Datetime = 'DATETIME',
  DatetimeRange = 'DATETIME_RANGE',
  List = 'LIST',
  ModelField = 'MODEL_FIELD'
}

export type FloatChangeEventData = {
  __typename?: 'FloatChangeEventData';
  fromValue?: Maybe<Scalars['Float']>;
  toValue?: Maybe<Scalars['Float']>;
};

export enum FulfillmentChannel {
  Amazon = 'AMAZON',
  Merchant = 'MERCHANT',
  NotAvailable = 'NOT_AVAILABLE',
  Walmart = 'WALMART'
}

export type FulfillmentService = {
  __typename?: 'FulfillmentService';
  name: Scalars['String'];
};

export type FulfillmentType = {
  __typename?: 'FulfillmentType';
  name: Scalars['String'];
};

export type Gs1RegisteredCompany = {
  __typename?: 'GS1RegisteredCompany';
  companyName: Scalars['String'];
  id: Scalars['ID'];
};

export type GenericError = BaseError & {
  __typename?: 'GenericError';
  message?: Maybe<Scalars['String']>;
};

export type GenericMutationResponse = {
  __typename?: 'GenericMutationResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** GenericRecoverableError is useful when you answer yes to this question: 'Can this have a try-again button added to it? Can you retry it safely?' */
export type GenericRecoverableError = BaseError & {
  __typename?: 'GenericRecoverableError';
  isRecoverable?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type GetPackagingInfoInput = {
  asin: Scalars['String'];
  region: Scalars['String'];
};

export type GetPackagingInfoResponse = {
  __typename?: 'GetPackagingInfoResponse';
  casePack?: Maybe<Scalars['Int']>;
  errors: Array<Scalars['String']>;
  palletSize?: Maybe<Scalars['Int']>;
  success: Scalars['Boolean'];
};

export type GetValidationsResponse = {
  __typename?: 'GetValidationsResponse';
  results: Array<AffiliateValidation>;
};

export type GridDataUploadInput = {
  file: Scalars['String'];
  model: Scalars['String'];
};

export type GridDataUploadResult = {
  __typename?: 'GridDataUploadResult';
  success: Scalars['Boolean'];
  totalUpdates: Scalars['Int'];
};

export type GroupPlanningStatusUpdate = {
  asin?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<Scalars['String']>;
  market: Scalars['String'];
  sku?: InputMaybe<Scalars['String']>;
  store?: InputMaybe<Scalars['String']>;
  targetStatus: Scalars['String'];
};

export type GroupedStore = {
  __typename?: 'GroupedStore';
  diligence: Scalars['Boolean'];
  name: Scalars['String'];
  priority: Scalars['Int'];
  regions: Array<Region>;
};

export type Gs1RegisteredCompanyCreateOutput = {
  __typename?: 'Gs1RegisteredCompanyCreateOutput';
  companyId: Scalars['ID'];
  companyName: Scalars['String'];
};

export type Gs1RegisteredCompanyCreateResponse = {
  __typename?: 'Gs1RegisteredCompanyCreateResponse';
  error?: Maybe<Scalars['String']>;
  results?: Maybe<Gs1RegisteredCompanyCreateOutput>;
  success: Scalars['Boolean'];
};

export type GuardrailEnableInput = {
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type HoldingBinCalcTarget = {
  __typename?: 'HoldingBinCalcTarget';
  active: Scalars['Boolean'];
  calcTarget: PriceCalculationTarget;
  currentCalculatedPrice: CalculatedPrice;
  initialCalculatedPrice?: Maybe<CalculatedPrice>;
  isLeader: Scalars['Boolean'];
};

export type HoldingBinCalcTargetHistoryResponse = {
  __typename?: 'HoldingBinCalcTargetHistoryResponse';
  results: Array<CalculatedPrice>;
};

export type HoldingBinEntriesResponse = Pageable & {
  __typename?: 'HoldingBinEntriesResponse';
  results: Array<HoldingBinEntry>;
  totalResults: Scalars['Int'];
};

export type HoldingBinEntry = ActiveTimestamped & {
  __typename?: 'HoldingBinEntry';
  active: Scalars['Boolean'];
  calcTargets: Array<HoldingBinCalcTarget>;
  comments?: Maybe<Array<HoldingBinEntryComment>>;
  createdOn: Scalars['DateTime'];
  currentCalculationStatus: Scalars['String'];
  id: Scalars['ID'];
  initialCalculationStatus: Scalars['String'];
  initialRuleSetExecution?: Maybe<RuleSetExecution>;
  releasedRuleSetExecution?: Maybe<RuleSetExecution>;
  resolution: HoldingBinResolution;
  resolvedDateTime?: Maybe<Scalars['DateTime']>;
  resolverId?: Maybe<InternalUser>;
  updatedOn: Scalars['DateTime'];
};

export type HoldingBinEntryComment = Timestamped & {
  __typename?: 'HoldingBinEntryComment';
  comment: Scalars['String'];
  commenterId: InternalUser;
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  isEdited: Scalars['Boolean'];
  updatedOn: Scalars['DateTime'];
};

export enum HoldingBinEntryTurnOffLevel {
  Calculation = 'CALCULATION',
  Syndication = 'SYNDICATION'
}

export type HoldingBinFilterMetadataResponse = {
  __typename?: 'HoldingBinFilterMetadataResponse';
  calculationStatuses: Array<EnumOption>;
  errorTypes: Array<EnumOption>;
  violationTypes: Array<EnumOption>;
};

export enum HoldingBinResolution {
  NotResolved = 'NOT_RESOLVED',
  Overridden = 'OVERRIDDEN',
  Released = 'RELEASED'
}

export type Image = {
  __typename?: 'Image';
  link: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
};

export type ImageChangeEventData = {
  __typename?: 'ImageChangeEventData';
  fromValue: Array<Maybe<Image>>;
  toValue: Array<Maybe<Image>>;
};

export type IncrementalSyndicationRequest = {
  __typename?: 'IncrementalSyndicationRequest';
  createdOn: Scalars['DateTime'];
  lastSyndicatedPrice?: Maybe<Scalars['Float']>;
  lastSyndicationRequestedAt?: Maybe<Scalars['DateTime']>;
  requestedPrice: Scalars['Float'];
  startingPrice?: Maybe<Scalars['Float']>;
};

export type IncrementalSyndicationRequestHistoryResponse = Pageable & {
  __typename?: 'IncrementalSyndicationRequestHistoryResponse';
  results: Array<IncrementalSyndicationRequest>;
  totalResults: Scalars['Int'];
};

export type IndividualFilter = {
  field: Scalars['String'];
  ignoreCase?: InputMaybe<Scalars['Boolean']>;
  modifier?: InputMaybe<FilterType>;
  operator: FilterOperator;
  value?: InputMaybe<Scalars['String']>;
};

export type IndividualPriceAdjustment = {
  __typename?: 'IndividualPriceAdjustment';
  active: Scalars['Boolean'];
  calcPriceChangeActionType?: Maybe<CalculationActionType>;
  calcPriceChangeAmount?: Maybe<Scalars['Float']>;
  calcPriceChangeAppliesTo?: Maybe<CalculationAppliesTo>;
  calcPriceChangeInputDataSource?: Maybe<InputDataSource>;
  calcPriceChangeInputDataSourceValue?: Maybe<InputDataSourceValue>;
  calcPriceChangeInputValueMultiplier?: Maybe<Scalars['Float']>;
  checkTarget: ConditionalPriceCheckTarget;
  checkType: ConditionalPriceCheckType;
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  filter?: Maybe<RuleSetEntityFilter>;
  id: Scalars['String'];
  maxValue?: Maybe<Scalars['Float']>;
  maxValueInputDataSource?: Maybe<InputDataSource>;
  maxValueInputDataSourceValue?: Maybe<InputDataSourceValue>;
  maxValueMultiplier?: Maybe<Scalars['Float']>;
  minValue?: Maybe<Scalars['Float']>;
  minValueInputDataSource?: Maybe<InputDataSource>;
  minValueInputDataSourceValue?: Maybe<InputDataSourceValue>;
  minValueMultiplier?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  negateRequiredRules: Scalars['Boolean'];
  order: Scalars['Int'];
  priceMarginFormula?: Maybe<PriceMarginFormula>;
  requiredRules: Array<Rule>;
  ruleSet: RuleSet;
  skipIfMissingData: Scalars['Boolean'];
  variableAssignments: Array<VariableAssignment>;
};

export type IndividualPriceAdjustmentEnableInput = {
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type IndividualPriceAdjustmentResponse = {
  __typename?: 'IndividualPriceAdjustmentResponse';
  results: Array<IndividualPriceAdjustment>;
  totalResults: Scalars['Int'];
};

export type InputDataRefreshTrigger = Timestamped & {
  __typename?: 'InputDataRefreshTrigger';
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  refreshType: RefreshType;
  scheduleScalar: Scalars['Int'];
  scheduleUnit: RefreshScheduleUnit;
  updatedOn: Scalars['DateTime'];
};

export type InputDataSource = Timestamped & {
  __typename?: 'InputDataSource';
  applicableEndDateColumnName?: Maybe<Scalars['String']>;
  applicableStartDateColumnName?: Maybe<Scalars['String']>;
  channel?: Maybe<SalesChannel>;
  channelColumnName?: Maybe<Scalars['String']>;
  channelIdColumnName: Scalars['String'];
  createdOn: Scalars['DateTime'];
  endDateColumnName?: Maybe<Scalars['String']>;
  fullStoreNameColumnName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inputDataSourceValues?: Maybe<Array<InputDataSourceValue>>;
  lastSuccessfulSync?: Maybe<SnowflakeDataSourceSyncHistory>;
  lastSync?: Maybe<SnowflakeDataSourceSyncHistory>;
  market?: Maybe<MarketCode>;
  marketColumnName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  refreshTriggers?: Maybe<Array<InputDataRefreshTrigger>>;
  snowflakeDatabaseName: Scalars['String'];
  snowflakeRoleName?: Maybe<Scalars['String']>;
  snowflakeSchemaName: Scalars['String'];
  snowflakeTableName: Scalars['String'];
  startDateColumnName?: Maybe<Scalars['String']>;
  thrasioProductIdColumnName?: Maybe<Scalars['String']>;
  updatedOn: Scalars['DateTime'];
  whereClause?: Maybe<Scalars['String']>;
};

export enum InputDataSourceTypes {
  Date = 'DATE',
  GenericNumber = 'GENERIC_NUMBER',
  Margin = 'MARGIN',
  Price = 'PRICE',
  String = 'STRING'
}

export type InputDataSourceValue = ActiveTimestamped & {
  __typename?: 'InputDataSourceValue';
  active: Scalars['Boolean'];
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  inputDataSource: InputDataSource;
  inputType?: Maybe<InputDataSourceTypes>;
  inputValueColumnName?: Maybe<Scalars['String']>;
  tagColumnName?: Maybe<Scalars['String']>;
  updatedOn: Scalars['DateTime'];
  valueName: Scalars['String'];
};

export type InputDataSourcesResponse = Pageable & {
  __typename?: 'InputDataSourcesResponse';
  results: Array<InputDataSource>;
  totalResults: Scalars['Int'];
};

export type IntChangeEventData = {
  __typename?: 'IntChangeEventData';
  fromValue?: Maybe<Scalars['Int']>;
  toValue?: Maybe<Scalars['Int']>;
};

/** Okta groups that InternalUsers can belong to */
export type InternalGroup = {
  __typename?: 'InternalGroup';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type InternalTool = {
  __typename?: 'InternalTool';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type InternalUser = {
  __typename?: 'InternalUser';
  avatarImageURLs: Array<AvatarImageUrl>;
  displayName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  groups: Array<InternalGroup>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  slackSubscriptions: Array<NotificationSubscription>;
  subscriptions: Array<NotificationSubscription>;
  title?: Maybe<Scalars['String']>;
};

export type InternalUserSuggestion = BaseTypeaheadSuggestion & {
  __typename?: 'InternalUserSuggestion';
  displayText: Scalars['String'];
  key: Scalars['String'];
  user?: Maybe<InternalUser>;
};

export type InternalUserTypeaheadResponse = BaseTypeaheadResponse & {
  __typename?: 'InternalUserTypeaheadResponse';
  prefixes: Array<Scalars['String']>;
  suggestions: Array<InternalUserSuggestion>;
};

export type Issue = {
  __typename?: 'Issue';
  active: Scalars['Boolean'];
  id?: Maybe<Scalars['Int']>;
  issueDescription?: Maybe<Scalars['String']>;
  issues: Scalars['String'];
};

export type IssueResponse = Pageable & {
  __typename?: 'IssueResponse';
  results: Array<Issue>;
  totalResults: Scalars['Int'];
};

export type LabInfo = {
  __typename?: 'LabInfo';
  labAddress: Scalars['String'];
  labContact: Scalars['String'];
  labEmail?: Maybe<Scalars['String']>;
  labFax?: Maybe<Scalars['String']>;
  labId: Scalars['String'];
  labMobileNumber?: Maybe<Scalars['String']>;
  labName: Scalars['String'];
  labPhoneNumber?: Maybe<Scalars['String']>;
};

export type LegacyBrand = {
  __typename?: 'LegacyBrand';
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type LegacyBrandCreate = {
  name: Scalars['String'];
};

export type LegacyBrandMutationResponse = {
  __typename?: 'LegacyBrandMutationResponse';
  error?: Maybe<Scalars['String']>;
  results?: Maybe<Array<LegacyBrand>>;
  success: Scalars['Boolean'];
};

export type LegacyBrandResponse = Pageable & {
  __typename?: 'LegacyBrandResponse';
  results: Array<LegacyBrand>;
  totalResults: Scalars['Int'];
};

export type ListMarketplaces = {
  __typename?: 'ListMarketplaces';
  results: Array<Maybe<MarketplaceWithCountry>>;
};

export type ListMerchants = {
  __typename?: 'ListMerchants';
  results: Array<Maybe<Scalars['String']>>;
};

export type ListMerchantsWithDateRange = {
  __typename?: 'ListMerchantsWithDateRange';
  results: Array<Maybe<MerchantWithDateRange>>;
};

export type ListReports = Pageable & {
  __typename?: 'ListReports';
  results?: Maybe<Array<ScAutomationToolsReport>>;
  totalResults: Scalars['Int'];
};

export type Listing = {
  __typename?: 'Listing';
  channelSku?: Maybe<ChannelSku>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  fulfillmentService?: Maybe<Scalars['String']>;
  groupPlanningStatus?: Maybe<PlanningStatus>;
  id: Scalars['ID'];
  internationalLaunch?: Maybe<Scalars['Boolean']>;
  marketplaceStatus?: Maybe<MarketplaceStatus>;
  name?: Maybe<Scalars['String']>;
  parentChannelId?: Maybe<Scalars['String']>;
  planningStatus?: Maybe<PlanningStatus>;
  planningSuggestionInfo?: Maybe<PlanningSuggestionInfo>;
  productCatalogIgnored?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
  status?: Maybe<ListingStatus>;
  store?: Maybe<Store>;
  updatedAt: Scalars['DateTime'];
};

export type ListingComment = {
  __typename?: 'ListingComment';
  /** Comments that are replies to this comment, empty array if there are no replies */
  childComments: Array<ListingComment>;
  content: Scalars['String'];
  createdOn: Scalars['DateTime'];
  creator: InternalUser;
  id: Scalars['ID'];
  listing: ProductCatalogProductListing;
  updatedOn: Scalars['DateTime'];
};

export type ListingCommentCreateInput = {
  content: Scalars['String'];
  parentCommentId?: InputMaybe<Scalars['ID']>;
  productListingId: Scalars['ID'];
};

export type ListingComplianceRule = {
  __typename?: 'ListingComplianceRule';
  active: Scalars['Boolean'];
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  label: Scalars['String'];
  ruleValues: Array<ListingComplianceRuleValue>;
  updatedOn: Scalars['DateTime'];
};

export type ListingComplianceRuleCreateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  label: Scalars['String'];
  ruleValues?: InputMaybe<Array<ListingComplianceRuleValueInput>>;
};

export type ListingComplianceRuleResult = Pageable & {
  __typename?: 'ListingComplianceRuleResult';
  results: Array<ListingComplianceRule>;
  totalResults: Scalars['Int'];
};

export type ListingComplianceRuleUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  label?: InputMaybe<Scalars['String']>;
  ruleValues?: InputMaybe<Array<ListingComplianceRuleValueInput>>;
};

export type ListingComplianceRuleValue = {
  __typename?: 'ListingComplianceRuleValue';
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  isRegex: Scalars['Boolean'];
  ruleValue: Scalars['String'];
  updatedOn: Scalars['DateTime'];
};

export type ListingComplianceRuleValueInput = {
  isRegex: Scalars['Boolean'];
  ruleValue: Scalars['String'];
};

export type ListingForTpidTool = {
  __typename?: 'ListingForTPIDTool';
  acquiredBrand?: Maybe<Scalars['String']>;
  brandAlias?: Maybe<Scalars['String']>;
  channelMarket?: Maybe<Scalars['String']>;
  channelSku?: Maybe<Scalars['String']>;
  channelid?: Maybe<Scalars['String']>;
  fulfillmentChannel?: Maybe<Scalars['String']>;
  fullStoreName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  thrasioProductId?: Maybe<Scalars['Float']>;
};

export type ListingImage = {
  __typename?: 'ListingImage';
  imageIndex: Scalars['Int'];
  imageUrl: Scalars['String'];
};

export type ListingObjective = {
  __typename?: 'ListingObjective';
  id: Scalars['ID'];
  lastEditor?: Maybe<InternalUser>;
  listing: ProductCatalogProductListing;
  objective: Scalars['String'];
  priority?: Maybe<ObjectivePriority>;
  strategicTactics: Array<StrategicTactic>;
};

export type ListingObjectiveUpdateInput = {
  id: Scalars['ID'];
  objective: Scalars['String'];
  priority?: InputMaybe<Scalars['String']>;
};

export type ListingPlanningStatusMutationResponse = {
  __typename?: 'ListingPlanningStatusMutationResponse';
  error?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Scalars['Int']>>;
  success: Scalars['Boolean'];
};

export type ListingPriceHistory = Timestamped & {
  __typename?: 'ListingPriceHistory';
  createdOn: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  id: Scalars['ID'];
  listing: PricingCatalogListing;
  price: Scalars['Float'];
  priceCurrency: Scalars['String'];
  strikePrice: Scalars['Float'];
  updatedOn: Scalars['DateTime'];
};

export type ListingPriceSyndicationRequest = Timestamped & {
  __typename?: 'ListingPriceSyndicationRequest';
  createdOn: Scalars['DateTime'];
  eventPdpVerificationLastAttempt?: Maybe<Scalars['DateTime']>;
  eventPdpVerificationStatus?: Maybe<SyndicationVerificationStatus>;
  id: Scalars['ID'];
  listing: PricingCatalogListing;
  previousPrice?: Maybe<Scalars['Float']>;
  priceSyndicationRequest: PriceSyndicationRequest;
  pricingCatalogVerificationFailureReason?: Maybe<SyndicationVerificationFailureReason>;
  pricingCatalogVerificationLastAttempt?: Maybe<Scalars['DateTime']>;
  pricingCatalogVerificationStatus?: Maybe<SyndicationVerificationStatus>;
  requestedPrice: Scalars['Float'];
  suppressionReason?: Maybe<SyndicationSuppressionReason>;
  syndicationProviderErrorMessage?: Maybe<Scalars['String']>;
  syndicationProviderVerificationLastAttempt?: Maybe<Scalars['DateTime']>;
  syndicationProviderVerificationStatus?: Maybe<SyndicationVerificationStatus>;
  updatedOn: Scalars['DateTime'];
};

export type ListingPriceSyndicationRequestHistoryResponse = Pageable & {
  __typename?: 'ListingPriceSyndicationRequestHistoryResponse';
  results: Array<ListingPriceSyndicationRequest>;
  totalResults: Scalars['Int'];
};

export type ListingPromotionSyndicationRequest = Timestamped & {
  __typename?: 'ListingPromotionSyndicationRequest';
  childListing: PricingCatalogListing;
  createdOn: Scalars['DateTime'];
  dealCurrency: Scalars['String'];
  dealEndDate: Scalars['DateTime'];
  dealPrice: Scalars['Float'];
  dealStartDate: Scalars['DateTime'];
  dealType: PromotionDealType;
  id: Scalars['ID'];
  isFeaturedAsin: Scalars['Int'];
  marketplaceId: Scalars['String'];
  merchantId: Scalars['String'];
  parentChannelId: Scalars['String'];
  promotionSyndicationRequest: PromotionSyndicationRequest;
  syndicationStatus: SyndicationBatchRequestStatus;
  updatedOn: Scalars['DateTime'];
};

export type ListingPromotionSyndicationRequestHistoryResponse = Pageable & {
  __typename?: 'ListingPromotionSyndicationRequestHistoryResponse';
  results: Array<ListingPromotionSyndicationRequest>;
  totalResults: Scalars['Int'];
};

export type ListingScreenshot = {
  __typename?: 'ListingScreenshot';
  date?: Maybe<Scalars['DateTime']>;
  screenshotUrl?: Maybe<Scalars['String']>;
};

export type ListingStatus = {
  __typename?: 'ListingStatus';
  name: Scalars['String'];
};

export type ListingStatusUpdate = {
  channelId: Scalars['String'];
  channelMarket: Scalars['String'];
  channelSku: Scalars['String'];
  fulfillmentChannel: Scalars['String'];
  fullStoreName: Scalars['String'];
  targetListingStatus: Scalars['String'];
};

export type ListingTitleIssues = {
  __typename?: 'ListingTitleIssues';
  ampersand: Scalars['Boolean'];
  charCount: Scalars['Boolean'];
  noBrandInTitle: Scalars['Boolean'];
  nonCapitalizedWords: Scalars['Boolean'];
  numbers: Scalars['Boolean'];
};

export type ListingToDivest = {
  divestedDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
};

export type ListingsForBrandMapping = {
  __typename?: 'ListingsForBrandMapping';
  brandAliasName?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  brandStatus?: Maybe<Scalars['String']>;
  channelKey?: Maybe<Scalars['String']>;
  channelMarket?: Maybe<Scalars['String']>;
  channelName?: Maybe<Scalars['String']>;
  channelSkuId?: Maybe<Scalars['Int']>;
  channelSkuValue?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  divestedDate?: Maybe<Scalars['Date']>;
  divestmentStatusName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  market?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  planningStatusName?: Maybe<Scalars['String']>;
  storeName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ListingsForBrandMappingResponse = Pageable & {
  __typename?: 'ListingsForBrandMappingResponse';
  results: Array<ListingsForBrandMapping>;
  totalResults: Scalars['Int'];
};

export type ListingsForPlanningStatus = {
  __typename?: 'ListingsForPlanningStatus';
  brandName?: Maybe<Scalars['String']>;
  channelKey?: Maybe<Scalars['String']>;
  channelMarket?: Maybe<Scalars['String']>;
  channelName?: Maybe<Scalars['String']>;
  channelSkuValue?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  fulfillmentTypeKey?: Maybe<Scalars['String']>;
  fulfillmentTypeName?: Maybe<Scalars['String']>;
  grossRevenueLastYear?: Maybe<Scalars['Int']>;
  grossRevenueLastYearForListing?: Maybe<Scalars['Int']>;
  groupPlanningStatusName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inventoryOnHand?: Maybe<Scalars['Int']>;
  inventoryOnHandForListing?: Maybe<Scalars['Int']>;
  isPrimaryListing?: Maybe<Scalars['Boolean']>;
  market?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  planningSuggestionInfoId?: Maybe<Scalars['Int']>;
  podName?: Maybe<Scalars['String']>;
  storeKey?: Maybe<Scalars['String']>;
  storeName?: Maybe<Scalars['String']>;
  suggestedStatusName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ListingsForPlanningStatusReport = {
  __typename?: 'ListingsForPlanningStatusReport';
  brandAliasName?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  channelKey?: Maybe<Scalars['String']>;
  channelMarket?: Maybe<Scalars['String']>;
  channelName?: Maybe<Scalars['String']>;
  channelSkuValue?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currentStatus?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  grossRevenueLastYearForListing?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  inventoryDate?: Maybe<Scalars['Date']>;
  inventoryOnHandForListing?: Maybe<Scalars['Int']>;
  isPrimaryListing?: Maybe<Scalars['Boolean']>;
  launchDate?: Maybe<Scalars['Date']>;
  market?: Maybe<Scalars['String']>;
  overrideReason?: Maybe<Scalars['String']>;
  podName?: Maybe<Scalars['String']>;
  previousStatus?: Maybe<Scalars['String']>;
  statusChangeDate?: Maybe<Scalars['Date']>;
  suggestedStatus?: Maybe<Scalars['String']>;
  totalPipelineInventory?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type ListingsForPlanningStatusReportResponse = Pageable & {
  __typename?: 'ListingsForPlanningStatusReportResponse';
  results: Array<ListingsForPlanningStatusReport>;
  totalResults: Scalars['Int'];
};

export type ListingsForPlanningStatusResponse = Pageable & {
  __typename?: 'ListingsForPlanningStatusResponse';
  results: Array<ListingsForPlanningStatus>;
  totalResults: Scalars['Int'];
};

export type ListingsForTpidToolResponse = Pageable & {
  __typename?: 'ListingsForTpidToolResponse';
  results: Array<ListingForTpidTool>;
  totalResults: Scalars['Int'];
};

export type ListingsResponse = Pageable & {
  __typename?: 'ListingsResponse';
  results: Array<Listing>;
  totalResults: Scalars['Int'];
};

export type LoadTypeaheadJsonState = {
  __typename?: 'LoadTypeaheadJsonState';
  currentNamespace?: Maybe<Scalars['String']>;
  entriesLoaded?: Maybe<Scalars['Int']>;
  errors: Array<Scalars['String']>;
  indexSize?: Maybe<Scalars['Int']>;
  state: Scalars['String'];
  successes: Array<Scalars['String']>;
};

export type MarkListingAsPrimaryInput = {
  asin?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<Scalars['String']>;
  listingId: Scalars['ID'];
  market?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  store?: InputMaybe<Scalars['String']>;
};

export type MarkListingAsPrimaryResponse = {
  __typename?: 'MarkListingAsPrimaryResponse';
  error?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Scalars['Int']>>;
  success: Scalars['Boolean'];
};

export type Market = {
  __typename?: 'Market';
  name: Scalars['String'];
};

export enum MarketCode {
  Ae = 'AE',
  Au = 'AU',
  Br = 'BR',
  Ca = 'CA',
  De = 'DE',
  Es = 'ES',
  Fr = 'FR',
  In = 'IN',
  It = 'IT',
  Jp = 'JP',
  Mx = 'MX',
  Nl = 'NL',
  Pl = 'PL',
  Se = 'SE',
  Sg = 'SG',
  Tr = 'TR',
  Uk = 'UK',
  Us = 'US'
}

export enum Marketplace {
  Australia = 'AUSTRALIA',
  Brazil = 'BRAZIL',
  Canada = 'CANADA',
  France = 'FRANCE',
  Germany = 'GERMANY',
  India = 'INDIA',
  Italy = 'ITALY',
  Japan = 'JAPAN',
  Mexico = 'MEXICO',
  Netherlands = 'NETHERLANDS',
  Poland = 'POLAND',
  Singapore = 'SINGAPORE',
  Spain = 'SPAIN',
  Sweden = 'SWEDEN',
  Turkey = 'TURKEY',
  Uae = 'UAE',
  Uk = 'UK',
  Usa = 'USA'
}

export type MarketplaceStatus = {
  __typename?: 'MarketplaceStatus';
  name: Scalars['String'];
};

export type MarketplaceWithCountry = {
  __typename?: 'MarketplaceWithCountry';
  country: Scalars['String'];
  marketplace: Scalars['String'];
};

export type MerchantWithDateRange = {
  __typename?: 'MerchantWithDateRange';
  from_date?: Maybe<Scalars['Date']>;
  merchant?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['Date']>;
};

export type MergeVariantGroupInput = {
  sourceVgId: Scalars['Int'];
  targetVgId: Scalars['Int'];
};

export type MergeVariantGroupResponse = {
  __typename?: 'MergeVariantGroupResponse';
  error?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Array<Scalars['Int']>>>;
  success: Scalars['Boolean'];
};

export type MultiBrandsSameTpid = {
  __typename?: 'MultiBrandsSameTpid';
  acquiredBrands?: Maybe<Array<Scalars['String']>>;
  brandAliases?: Maybe<Array<Scalars['String']>>;
  channelIds?: Maybe<Array<Scalars['String']>>;
  channelSkus?: Maybe<Array<Scalars['String']>>;
  thrasioProductId?: Maybe<Scalars['Float']>;
};

export type MultiBrandsSameTpidResponse = Pageable & {
  __typename?: 'MultiBrandsSameTpidResponse';
  results: Array<MultiBrandsSameTpid>;
  totalResults: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addBrandUserAssignment: BrandUserAssignment;
  addPod: Pod;
  addStrategicTactic: StrategicTactic;
  addUserAssignment: UserAssignment;
  akeneoProduct: ProductResponse;
  akeneoProductModel: ProductModelResponse;
  assignTpidToChannelKey: AssignTpidToChannelKeyResponse;
  bulkUpdateProductReportingSubscription: BulkEditProductReportingSubscriptionResponse;
  cancelUploadNewDataFiles: CancelUploadNewDataFilesResponseCancelUploadNewDataFilesError;
  copyRuleSet: CopyRuleSetResponse;
  createAsinExclusions: AcosTargetExcludedAsinQuickAddResponse;
  createBrand: BrandMutationResponse;
  createBrandAlias: BrandAliasMutationResponse;
  createCalculatedPriceOverride: GenericMutationResponse;
  createCalculatedPriceOverrideComment: GenericMutationResponse;
  createCompanyListing: Gs1RegisteredCompanyCreateResponse;
  createHoldingBinEntryComment: GenericMutationResponse;
  createIndividualPriceAdjustment: GenericMutationResponse;
  createInputDataSource: GenericMutationResponse;
  createLegacyBrand: LegacyBrandMutationResponse;
  createListingComment: ListingComment;
  createListingComplianceRule: ListingComplianceRule;
  createPowerBIDashboard: PowerBiDashboard;
  createPriceCalculationTargetGroup: CreateUpdatePriceCalculationTargetGroupResponse;
  createPriceGuardrail: GenericMutationResponse;
  createPriceSyndicationRequest: CreatePriceSyndicationRequestResponse;
  createProductEvent: CreateProductEventResponse;
  createProductEventComment: ProductEventComment;
  createProductReportingSubscription: CreateProductReportingSubscriptionResponse;
  createPromotionSyndicationRequest: CreatePromotionSyndicationRequestResponse;
  createRawSku: RawSkuCreateResponse;
  createRule: CreateUpdateRuleResponse;
  createRuleSet: CreateUpdateRuleSetResponse;
  createRuleSetEntityFilter: CreateUpdateRuleSetEntityFilterResponse;
  createS3UploadUrl: S3PresignedUrlResponse;
  createSegment: GenericMutationResponse;
  createSkuMappingS3UploadUrl: S3PresignedUrlResponse;
  createThrasioSkuRawSkuMappingS3UploadUrl: S3PresignedUrlResponse;
  createUnitEconDataSource: GenericMutationResponse;
  deleteAcosExcludedAsins: AcosDeleteExcludedAsinsResponse;
  deleteAkeneoProductModels: AkeneoDeleteResponse;
  deleteAkeneoProducts: AkeneoDeleteResponse;
  deleteAkeneoTPIDs: AkeneoDeleteResponse;
  deleteBrandUserAssignment: BrandUserAssignmentDeleteResult;
  deleteHoldingBinEntryComment: GenericMutationResponse;
  deleteSCAutomationToolsReport?: Maybe<Scalars['Boolean']>;
  deleteUserAssignment: UserAssignmentDeleteResult;
  divestListing: UpdateListingStatusMutationResponse;
  enableGuardrail: GenericMutationResponse;
  enableIndividualPriceAdjustment: GenericMutationResponse;
  enableRule: CreateUpdateRuleResponse;
  executePriceRun: ExecutePriceRunResponse;
  getAcosExclusionAsinsStatus: AcosTargetExclusionUpdateStatusResponse;
  getBidManagementDataStatus: BidManagementDataStatusResponse;
  getBidManagementValStatus: BidManagementValStatusResponse;
  markListingAsPrimary: MarkListingAsPrimaryResponse;
  mergeVariantGroup: MergeVariantGroupResponse;
  overrideHoldingBinEntries: GenericMutationResponse;
  parseAcosExclusionAsinsFromUploadedFile: AcosTargetExclusionUpdateResponse;
  parseBidDataFromUploadedFile: BidManagementValTaskResponse;
  performGridDataUpload?: Maybe<GridDataUploadResult>;
  plaidCreateAccessToken: PlaidInstitution;
  plaidCreateLinkToken: PlaidLinkToken;
  plannerRequestCancel: PlannerRequestResult;
  plannerRequestCreate: PlannerRequestResult;
  plannerRequestCreateV2: PlannerRequestResult;
  plannerRequestUpdate: PlannerRequestResult;
  poExecutionUploadCsv: PoExecutionUploadFileResult;
  poExecutionUploadUrl: SignedUploadUrl;
  productIntegrityCancelRequest: ProductIntegrityMutationResponse;
  productIntegrityCreateLab: ProductIntegrityNewLabResponse;
  productIntegrityCreateQuoteApprovalLinkObject: ProductIntegrityMutationResponse;
  productIntegrityCreateRequestV3: ProductIntegrityMutationResponse;
  productIntegrityCreateResults: ProductIntegrityMutationResponse;
  productIntegrityCreateReview: ProductIntegrityMutationResponse;
  productIntegrityDropRequest: ProductIntegrityMutationResponse;
  productIntegrityReassignPim: ProductIntegrityResponseReassignPim;
  productIntegrityRejectTestResults: ProductIntegrityMutationResponse;
  productIntegrityRequestNewQuotes: ProductIntegrityMutationResponse;
  productIntegritySubmitCertificates: ProductIntegrityMutationResponse;
  productIntegritySubmitQuotesAcknowledge: ProductIntegrityMutationResponse;
  productIntegritySubmitQuotesApproval: ProductIntegrityMutationResponse;
  productIntegritySubmitQuotesApprovalOnly: ProductIntegrityMutationResponse;
  productIntegritySubmitResultsApproval: ProductIntegrityMutationResponse;
  promoteRuleSet: GenericMutationResponse;
  releaseHoldingBinEntries: GenericMutationResponse;
  replenishmentRequestCsvUpload: ReplenismentRequestResult;
  replnApprove: ReplnGeneralResp;
  replnReject: ReplnGeneralResp;
  replnUpdate: ReplnGeneralResp;
  reportProductEvent: ReportProductEventResponse;
  requestAcosExclusionAsinsUploadUrl: AcosTargetExclusionUploadResponse;
  requestAffiliateDataUploadUrl: AffiliateS3PresignedPostUrl;
  requestBidDataUploadUrl: BidManagementValidationUploadResponse;
  requestBidManagementData: BidManagementDataTaskResponse;
  requestGridDataUploadUrl?: Maybe<SignedUploadUrl>;
  requestLatestListingPriceHistory: RequestLatestListingPriceHistoryResponse;
  requestPriceSyndicationS3UploadUrl: S3PresignedPostUrl;
  requestReplenishmentUploadUrl: SignedUploadUrl;
  requestSCAutomationToolReportFileUrl?: Maybe<Array<Maybe<ScAutomationToolsReportFileUrl>>>;
  requestSCAutomationToolsReportStatus?: Maybe<ScAutomationToolsTaskStatus>;
  requestSCAutomationToolsTriggerGenerateReport?: Maybe<ScAutomationToolsReportId>;
  requestSCAutomationToolsTriggerGenerateReportManual?: Maybe<ScAutomationToolsTaskStatus>;
  requestSCAutomationToolsUploadUrl?: Maybe<SignedUploadUrl>;
  requestSCAutomationToolsUploadUrlForManualDataUpload?: Maybe<SignedUploadUrl>;
  requestSCAutomationToolsValidationForBatchManualDataUpload?: Maybe<ScAutomationToolsTaskStatus>;
  requestTOValidationUploadUrl?: Maybe<SignedUploadUrl>;
  requestValidation: AffiliateRequestValidationResponseAffiliateRequestValidationErrors;
  resetTpid: ResetTpidResponse;
  splatTestServiceExampleCreate: SplatTestServiceExample;
  storeCreate: ShortGroupedStore;
  storeUpdate: ShortGroupedStore;
  /** Subscribe current user's email to a list */
  subscribeMeToList: NotificationSubscriptionResult;
  /** Subscribe a Slack channel ID to a list */
  subscribeSlackChannelToList: NotificationSubscriptionResult;
  triggerAllUnitEconRefresh: GenericMutationResponse;
  triggerIncrementalSyndicationRequestProcessing: GenericMutationResponse;
  triggerInputDataRefresh: GenericMutationResponse;
  triggerProcessReviewChangesTask: AcosTargetTaskStatusResponse;
  triggerProductReportingSubscription: TriggerProductReportingSubscriptionResponse;
  triggerSegmentRefresh: GenericMutationResponse;
  triggerUnitEconRefresh: GenericMutationResponse;
  turnOffHoldingBinEntries: GenericMutationResponse;
  typeaheadDeleteAllKeys: DeleteResponse;
  typeaheadDeleteKeys: DeleteResponse;
  typeaheadInsertData: UploadResponse;
  typeaheadLoadJson: Scalars['ID'];
  typeaheadUploadInternalUsers: Scalars['ID'];
  typeaheadUploadSlackChannels: Scalars['ID'];
  /** Unsubscribe current user's email to a list */
  unsubscribeMeFromList: NotificationUnsubscriptionResult;
  /** Unsubscribe a Slack channel ID from a list */
  unsubscribeSlackChannelFromList: NotificationUnsubscriptionResult;
  updateAffiliateValidation: AffiliateValidationUpdateResponseAffiliateValidationUpdateResponseError;
  updateApprovedReviewChanges: AcosTargetSubmissionResponse;
  updateBrand: BrandMutationResponse;
  updateBrandAlias: Array<BrandAliasMutationResponse>;
  updateBrandUserAssignment: BrandUserAssignment;
  updateBulkSkuMapping: RawSkuBulkUpdateResponse;
  updateCalculatedPriceOverride: GenericMutationResponse;
  updateCalculatedPriceOverrideComment: GenericMutationResponse;
  updateChannelSku: Array<ChannelSkuMutationResponse>;
  updateCompanyListing: Gs1RegisteredCompanyCreateResponse;
  updateDeclinedReviewChanges: AcosTargetSubmissionResponse;
  updateGroupPlanningStatus: ListingPlanningStatusMutationResponse;
  updateHoldingBinEntryComment: GenericMutationResponse;
  updateInBulk: BulkUpdateResponse;
  updateIndividualPriceAdjustment: GenericMutationResponse;
  updateInputDataSource: GenericMutationResponse;
  updateKeywordData: BidManagementKeywordUpdateResponse;
  updateListingComplianceRule: ListingComplianceRule;
  updateListingObjective: ListingObjective;
  updateListingStatus: UpdateListingStatusMutationResponse;
  updateParticipationStatus: UpdateParticipationStatusResponse;
  updatePod: Pod;
  updatePriceCalculationTargetGroup: CreateUpdatePriceCalculationTargetGroupResponse;
  updatePriceGuardrail: GenericMutationResponse;
  updateProductEvent: UpdateProductEventResponse;
  updateProductEventClassification: ProductEventClassificationOption;
  updateProductModelAttribute: ProductModelUpdateMutationResponse;
  updateProductReportingSubscription: EditProductReportingSubscriptionResponse;
  updateRawSku: RawSkuUpdateResponse;
  updateRawSkuCasePackDimension: RawSkuProductDimensionUpdateResponse;
  updateRawSkuCasePackQty: RawSkuGeneralDimUpdateResponse;
  updateRawSkuCasePackVolume: RawSkuGeneralDimUpdateResponse;
  updateRawSkuCasePackWeight: RawSkuGeneralDimUpdateResponse;
  updateRawSkuContainerQty: RawSkuGeneralDimUpdateResponse;
  updateRawSkuInnerCasePackQty: RawSkuGeneralDimUpdateResponse;
  updateRawSkuPalletQty: RawSkuGeneralDimUpdateResponse;
  updateRawSkuProductDimension: RawSkuProductDimensionUpdateResponse;
  updateRawSkuProductVolume: RawSkuGeneralDimUpdateResponse;
  updateRawSkuWeight: RawSkuGeneralDimUpdateResponse;
  updateRule: CreateUpdateRuleResponse;
  updateRuleSet: CreateUpdateRuleSetResponse;
  updateRuleSetEntityFilter: CreateUpdateRuleSetEntityFilterResponse;
  updateRuleSetScheduleParticipation: GenericMutationResponse;
  updateSegment: GenericMutationResponse;
  updateStore: StoreUpdateResponse;
  updateStrategicTactic: StrategicTactic;
  updateThrasioSku: ThrasioSkuUpdateResponse;
  updateThrasioSkuRawSkuMapping: ThrasioSkuRawSkuBulkMappingUpdateResponse;
  updateToolSettings: AcosTargetSettingsResponse;
  updateTpid: UpdateTpidResponse;
  updateUnitEconDataSource: GenericMutationResponse;
  updateUserAssignment: UserAssignment;
  uploadNewDataFiles: UploadNewDataFilesResponseUploadNewDataFilesError;
  validateAcosExclusionUploadedFile: AcosTargetExclusionValidationResponse;
  verifyAllGTINsbyGS1: VerifyAllGtiNsbyGs1Response;
  verifyGTINbyGS1: VerifyGtiNbyGs1Response;
};


export type MutationAddBrandUserAssignmentArgs = {
  input: BrandUserAssignmentCreateInput;
};


export type MutationAddPodArgs = {
  input: PodCreateInput;
};


export type MutationAddStrategicTacticArgs = {
  input: StrategicTacticAddInput;
};


export type MutationAddUserAssignmentArgs = {
  input: UserAssignmentCreateInput;
};


export type MutationAkeneoProductArgs = {
  input: Product;
};


export type MutationAkeneoProductModelArgs = {
  input: ProductModel;
};


export type MutationAssignTpidToChannelKeyArgs = {
  input: AssignTpidToChannelKeyInput;
  userEmail?: InputMaybe<Scalars['String']>;
};


export type MutationBulkUpdateProductReportingSubscriptionArgs = {
  input: ProductReportingSubscriptionListBulkEditInput;
};


export type MutationCancelUploadNewDataFilesArgs = {
  input: CancelUploadNewDataFilesInput;
};


export type MutationCopyRuleSetArgs = {
  input: CopyRuleSetInput;
};


export type MutationCreateAsinExclusionsArgs = {
  input: AcosTargetExcludedAsinQuickAddInput;
};


export type MutationCreateBrandArgs = {
  input: BrandCreate;
};


export type MutationCreateBrandAliasArgs = {
  input: BrandAliasMutation;
};


export type MutationCreateCalculatedPriceOverrideArgs = {
  input: CreateCalculatedPriceOverrideInput;
};


export type MutationCreateCalculatedPriceOverrideCommentArgs = {
  input: CreateCalculatedPriceOverrideCommentInput;
};


export type MutationCreateCompanyListingArgs = {
  input: Scalars['String'];
};


export type MutationCreateHoldingBinEntryCommentArgs = {
  input: CreateHoldingBinEntryCommentInput;
};


export type MutationCreateIndividualPriceAdjustmentArgs = {
  input: CreateIndividualPriceAdjustmentInput;
};


export type MutationCreateInputDataSourceArgs = {
  input: CreateInputDataSourceInput;
};


export type MutationCreateLegacyBrandArgs = {
  input: LegacyBrandCreate;
};


export type MutationCreateListingCommentArgs = {
  input: ListingCommentCreateInput;
};


export type MutationCreateListingComplianceRuleArgs = {
  input: ListingComplianceRuleCreateInput;
};


export type MutationCreatePowerBiDashboardArgs = {
  input: CreatePowerBiDashboardInput;
};


export type MutationCreatePriceCalculationTargetGroupArgs = {
  input: CreatePriceCalculationTargetGroupInput;
};


export type MutationCreatePriceGuardrailArgs = {
  input: CreatePriceGuardrailInput;
};


export type MutationCreatePriceSyndicationRequestArgs = {
  input: CreatePriceSyndicationRequestInput;
};


export type MutationCreateProductEventArgs = {
  input: ProductEventCreateInput;
};


export type MutationCreateProductEventCommentArgs = {
  input: ProductEventCommentCreateInput;
};


export type MutationCreateProductReportingSubscriptionArgs = {
  input: ProductReportingSubscriptionListCreateInput;
};


export type MutationCreatePromotionSyndicationRequestArgs = {
  input: CreatePromotionSyndicationRequestInput;
};


export type MutationCreateRawSkuArgs = {
  input: RawSkuCreate;
};


export type MutationCreateRuleArgs = {
  input: CreateRuleInput;
};


export type MutationCreateRuleSetArgs = {
  input: CreateRuleSetInput;
};


export type MutationCreateRuleSetEntityFilterArgs = {
  input: CreateRuleSetEntityFilterInput;
};


export type MutationCreateS3UploadUrlArgs = {
  filename: Scalars['String'];
  foldername: Scalars['String'];
};


export type MutationCreateSegmentArgs = {
  input: CreateSegmentInput;
};


export type MutationCreateSkuMappingS3UploadUrlArgs = {
  filename: Scalars['String'];
};


export type MutationCreateThrasioSkuRawSkuMappingS3UploadUrlArgs = {
  filename: Scalars['String'];
};


export type MutationCreateUnitEconDataSourceArgs = {
  input: CreateUnitEconDataSourceInput;
};


export type MutationDeleteAcosExcludedAsinsArgs = {
  input: AcosDeleteExcludedAsinsRequestInput;
};


export type MutationDeleteAkeneoProductModelsArgs = {
  input: Array<ProductModel>;
};


export type MutationDeleteAkeneoProductsArgs = {
  input: Array<Product>;
};


export type MutationDeleteAkeneoTpiDsArgs = {
  input: Array<ThrasioProductId>;
};


export type MutationDeleteBrandUserAssignmentArgs = {
  input: BrandUserAssignmentDeleteInput;
};


export type MutationDeleteHoldingBinEntryCommentArgs = {
  input: DeleteHoldingBinEntryCommentInput;
};


export type MutationDeleteScAutomationToolsReportArgs = {
  input?: InputMaybe<RequestScAutomationToolsReportStatusInput>;
};


export type MutationDeleteUserAssignmentArgs = {
  input: UserAssignmentDeleteInput;
};


export type MutationDivestListingArgs = {
  input: ListingToDivest;
  userEmail?: InputMaybe<Scalars['String']>;
};


export type MutationEnableGuardrailArgs = {
  input: GuardrailEnableInput;
};


export type MutationEnableIndividualPriceAdjustmentArgs = {
  input: IndividualPriceAdjustmentEnableInput;
};


export type MutationEnableRuleArgs = {
  input: EnableRuleInput;
};


export type MutationExecutePriceRunArgs = {
  input: ExecutePriceRunInput;
};


export type MutationGetAcosExclusionAsinsStatusArgs = {
  input: AcosTargetExclusionUpdateStatusRequestInput;
};


export type MutationGetBidManagementDataStatusArgs = {
  input: BidManagementDataStatusRequestInput;
};


export type MutationGetBidManagementValStatusArgs = {
  input: BidManagementValStatusRequestInput;
};


export type MutationMarkListingAsPrimaryArgs = {
  input: MarkListingAsPrimaryInput;
  userEmail?: InputMaybe<Scalars['String']>;
};


export type MutationMergeVariantGroupArgs = {
  input: MergeVariantGroupInput;
};


export type MutationOverrideHoldingBinEntriesArgs = {
  mutationInput: OverrideHoldingBinEntriesInput;
};


export type MutationParseAcosExclusionAsinsFromUploadedFileArgs = {
  input: AcosTargetExclusionImportInput;
};


export type MutationParseBidDataFromUploadedFileArgs = {
  input: BidManagementValidationRequestInput;
};


export type MutationPerformGridDataUploadArgs = {
  input: GridDataUploadInput;
};


export type MutationPlaidCreateAccessTokenArgs = {
  input: PlaidCreateAccessTokenInput;
};


export type MutationPlannerRequestCancelArgs = {
  id: Scalars['String'];
  userEmail: Scalars['String'];
};


export type MutationPlannerRequestCreateArgs = {
  input: PlannerRequestInput;
};


export type MutationPlannerRequestCreateV2Args = {
  input: PlannerRequestInputV2;
};


export type MutationPlannerRequestUpdateArgs = {
  input: PlannerRequestUpdateInput;
};


export type MutationPoExecutionUploadCsvArgs = {
  input: PoCsvUploadDocumentInput;
};


export type MutationProductIntegrityCancelRequestArgs = {
  id: Scalars['String'];
  userEmail: Scalars['String'];
};


export type MutationProductIntegrityCreateLabArgs = {
  lab: ProductIntegrityLabInput;
};


export type MutationProductIntegrityCreateQuoteApprovalLinkObjectArgs = {
  links: ProductIntegrityQuoteApprovalLinkInputV2;
};


export type MutationProductIntegrityCreateRequestV3Args = {
  request: ProductIntegrityRequestInputV3;
};


export type MutationProductIntegrityCreateResultsArgs = {
  links: ProductIntegrityCreateResultInput;
};


export type MutationProductIntegrityCreateReviewArgs = {
  requestId: Scalars['String'];
  reviewInput: ProductIntegrityRequestReviewInput;
};


export type MutationProductIntegrityDropRequestArgs = {
  id: Scalars['String'];
  userEmail: Scalars['String'];
};


export type MutationProductIntegrityReassignPimArgs = {
  input: ProductIntegrityReAssignPimInput;
};


export type MutationProductIntegrityRejectTestResultsArgs = {
  requestNewTestsInput: ProductIntegrityRequestNewTestsInput;
};


export type MutationProductIntegrityRequestNewQuotesArgs = {
  requestNewQuotesInput: ProductIntegrityRequestNewQuotesInput;
};


export type MutationProductIntegritySubmitCertificatesArgs = {
  input: ProductIntegrityStepFourSubmitInput;
};


export type MutationProductIntegritySubmitQuotesAcknowledgeArgs = {
  quotes: ProductIntegritySubmitQuotesAcknowledgeInput;
};


export type MutationProductIntegritySubmitQuotesApprovalArgs = {
  quotes: ProductIntegritySubmitQuotesApprovalInput;
};


export type MutationProductIntegritySubmitQuotesApprovalOnlyArgs = {
  quotes: ProductIntegritySubmitQuotesApprovalInput;
};


export type MutationProductIntegritySubmitResultsApprovalArgs = {
  result: ProductIntegritySubmitResultApprovalInput;
};


export type MutationPromoteRuleSetArgs = {
  input: PromoteRuleSetInput;
};


export type MutationReleaseHoldingBinEntriesArgs = {
  mutationInput: ReleaseHoldingBinEntriesInput;
};


export type MutationReplenishmentRequestCsvUploadArgs = {
  input: CsvUploadInput;
};


export type MutationReplnApproveArgs = {
  input: ReplAcceptInput;
};


export type MutationReplnRejectArgs = {
  input: ReplnRejectInput;
};


export type MutationReplnUpdateArgs = {
  input: ReplnUpdateInput;
};


export type MutationReportProductEventArgs = {
  input: ReportProductEventInput;
};


export type MutationRequestAcosExclusionAsinsUploadUrlArgs = {
  input: AcosTargetExclusionUploadRequestInput;
};


export type MutationRequestAffiliateDataUploadUrlArgs = {
  input: AffiliateS3PresignedPostUrlInput;
};


export type MutationRequestBidDataUploadUrlArgs = {
  input: BidManagementValidationUploadRequestInput;
};


export type MutationRequestBidManagementDataArgs = {
  input: BidManagementRequestInput;
};


export type MutationRequestLatestListingPriceHistoryArgs = {
  input: RequestLatestListingPriceHistoryInput;
};


export type MutationRequestScAutomationToolReportFileUrlArgs = {
  report_id: Scalars['Int'];
};


export type MutationRequestScAutomationToolsReportStatusArgs = {
  input?: InputMaybe<RequestScAutomationToolsReportStatusInput>;
};


export type MutationRequestScAutomationToolsTriggerGenerateReportArgs = {
  input?: InputMaybe<RequestScAutomationToolsTriggerGenerateReportInput>;
};


export type MutationRequestScAutomationToolsTriggerGenerateReportManualArgs = {
  input?: InputMaybe<RequestScAutomationToolsTriggerGenerateReportManualInput>;
};


export type MutationRequestScAutomationToolsUploadUrlForManualDataUploadArgs = {
  market?: InputMaybe<Scalars['String']>;
  merchant?: InputMaybe<Scalars['String']>;
};


export type MutationRequestScAutomationToolsValidationForBatchManualDataUploadArgs = {
  merchants?: InputMaybe<Array<InputMaybe<ValidationForBatchUpload>>>;
};


export type MutationRequestValidationArgs = {
  input: AffiliateValidationErrorRequestInput;
};


export type MutationResetTpidArgs = {
  input: ResetTpid;
  userEmail?: InputMaybe<Scalars['String']>;
};


export type MutationSplatTestServiceExampleCreateArgs = {
  content: Scalars['String'];
};


export type MutationStoreCreateArgs = {
  diligence?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  priority: Scalars['Int'];
  regions: Array<RegionCreateInput>;
};


export type MutationStoreUpdateArgs = {
  diligence?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  priority: Scalars['Int'];
  regions: Array<RegionUpdateInput>;
};


export type MutationSubscribeMeToListArgs = {
  requestInput: CreateMyEmailSubscriptionInput;
};


export type MutationSubscribeSlackChannelToListArgs = {
  requestInput: CreateSlackSubscriptionInput;
};


export type MutationTriggerInputDataRefreshArgs = {
  input: TriggerInputDataRefreshInput;
};


export type MutationTriggerProductReportingSubscriptionArgs = {
  id: Scalars['ID'];
};


export type MutationTriggerSegmentRefreshArgs = {
  input: TriggerSegmentRefreshInput;
};


export type MutationTriggerUnitEconRefreshArgs = {
  input: TriggerUnitEconRefreshInput;
};


export type MutationTurnOffHoldingBinEntriesArgs = {
  mutationInput: TurnOffHoldingBinEntriesInput;
};


export type MutationTypeaheadDeleteAllKeysArgs = {
  namespace: Scalars['String'];
};


export type MutationTypeaheadDeleteKeysArgs = {
  keys: Array<Scalars['String']>;
  namespace: Scalars['String'];
};


export type MutationTypeaheadInsertDataArgs = {
  entries: Array<TypeaheadUploadData>;
  namespace: Scalars['String'];
};


export type MutationUnsubscribeMeFromListArgs = {
  requestInput: DeleteMyEmailSubscriptionInput;
};


export type MutationUnsubscribeSlackChannelFromListArgs = {
  requestInput: DeleteSlackSubscriptionInput;
};


export type MutationUpdateAffiliateValidationArgs = {
  input: AffiliateValidationUpdateInput;
};


export type MutationUpdateApprovedReviewChangesArgs = {
  input: AcosTargetReviewAcceptInput;
};


export type MutationUpdateBrandArgs = {
  input: BrandUpdate;
};


export type MutationUpdateBrandAliasArgs = {
  input: Array<BrandAliasMutation>;
};


export type MutationUpdateBrandUserAssignmentArgs = {
  input: BrandUserAssignmentUpdateInput;
};


export type MutationUpdateBulkSkuMappingArgs = {
  creator?: InputMaybe<Scalars['String']>;
  fileKey: Scalars['String'];
};


export type MutationUpdateCalculatedPriceOverrideArgs = {
  input: UpdateCalculatedPriceOverrideInput;
};


export type MutationUpdateCalculatedPriceOverrideCommentArgs = {
  input: UpdateCalculatedPriceOverrideCommentInput;
};


export type MutationUpdateChannelSkuArgs = {
  input: Array<ChannelSkuUpdate>;
};


export type MutationUpdateCompanyListingArgs = {
  name: Scalars['String'];
  newName: Scalars['String'];
};


export type MutationUpdateDeclinedReviewChangesArgs = {
  input: AcosTargetReviewDeclinesInput;
};


export type MutationUpdateGroupPlanningStatusArgs = {
  input: GroupPlanningStatusUpdate;
  userEmail?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateHoldingBinEntryCommentArgs = {
  input: UpdateHoldingBinEntryCommentInput;
};


export type MutationUpdateInBulkArgs = {
  fileKey: Scalars['String'];
  type: Scalars['String'];
  userEmail: Scalars['String'];
};


export type MutationUpdateIndividualPriceAdjustmentArgs = {
  input: UpdateIndividualPriceAdjustmentInput;
};


export type MutationUpdateInputDataSourceArgs = {
  input: UpdateInputDataSourceInput;
};


export type MutationUpdateKeywordDataArgs = {
  input: BidManagementKeywordUpdateRequestInput;
};


export type MutationUpdateListingComplianceRuleArgs = {
  input: ListingComplianceRuleUpdateInput;
};


export type MutationUpdateListingObjectiveArgs = {
  input: ListingObjectiveUpdateInput;
};


export type MutationUpdateListingStatusArgs = {
  input: ListingStatusUpdate;
};


export type MutationUpdateParticipationStatusArgs = {
  input: UpdateParticipationStatusInput;
};


export type MutationUpdatePodArgs = {
  input: PodUpdateInput;
};


export type MutationUpdatePriceCalculationTargetGroupArgs = {
  input: UpdatePriceCalculationTargetGroupInput;
};


export type MutationUpdatePriceGuardrailArgs = {
  input: UpdatePriceGuardrailInput;
};


export type MutationUpdateProductEventArgs = {
  input: ProductEventUpdateInput;
};


export type MutationUpdateProductEventClassificationArgs = {
  input: ProductEventClassificationUpdateInput;
};


export type MutationUpdateProductModelAttributeArgs = {
  input: ProductModelUpdate;
};


export type MutationUpdateProductReportingSubscriptionArgs = {
  input: ProductReportingSubscriptionListEditInput;
};


export type MutationUpdateRawSkuArgs = {
  input: RawSkuUpdate;
};


export type MutationUpdateRawSkuCasePackDimensionArgs = {
  input: RawSkuTripleDimensionUpdate;
};


export type MutationUpdateRawSkuCasePackQtyArgs = {
  input: RawSkuGeneralDimUpdate;
};


export type MutationUpdateRawSkuCasePackVolumeArgs = {
  input: RawSkuGeneralDimUpdate;
};


export type MutationUpdateRawSkuCasePackWeightArgs = {
  input: RawSkuGeneralDimUpdate;
};


export type MutationUpdateRawSkuContainerQtyArgs = {
  input: RawSkuGeneralDimUpdate;
};


export type MutationUpdateRawSkuInnerCasePackQtyArgs = {
  input: RawSkuGeneralDimUpdate;
};


export type MutationUpdateRawSkuPalletQtyArgs = {
  input: RawSkuGeneralDimUpdate;
};


export type MutationUpdateRawSkuProductDimensionArgs = {
  input: RawSkuTripleDimensionUpdate;
};


export type MutationUpdateRawSkuProductVolumeArgs = {
  input: RawSkuGeneralDimUpdate;
};


export type MutationUpdateRawSkuWeightArgs = {
  input: RawSkuGeneralDimUpdate;
};


export type MutationUpdateRuleArgs = {
  input: UpdateRuleInput;
};


export type MutationUpdateRuleSetArgs = {
  input: UpdateRuleSetInput;
};


export type MutationUpdateRuleSetEntityFilterArgs = {
  input: UpdateRuleSetEntityFilterInput;
};


export type MutationUpdateRuleSetScheduleParticipationArgs = {
  input: UpdateRuleSetScheduleParticipationInput;
};


export type MutationUpdateSegmentArgs = {
  input: UpdateSegmentInput;
};


export type MutationUpdateStoreArgs = {
  input: StoreUpdate;
};


export type MutationUpdateStrategicTacticArgs = {
  input: StrategicTacticUpdateInput;
};


export type MutationUpdateThrasioSkuArgs = {
  input: ThrasioSkuUpdate;
};


export type MutationUpdateThrasioSkuRawSkuMappingArgs = {
  creator?: InputMaybe<Scalars['String']>;
  fileKey: Scalars['String'];
};


export type MutationUpdateToolSettingsArgs = {
  segmentToolSettings: SegmentToolSettingsInput;
};


export type MutationUpdateTpidArgs = {
  input: ProductTpidUpdate;
  userEmail?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateUnitEconDataSourceArgs = {
  input: UpdateUnitEconDataSourceInput;
};


export type MutationUpdateUserAssignmentArgs = {
  input: UserAssignmentUpdateInput;
};


export type MutationUploadNewDataFilesArgs = {
  input: UploadNewDataFilesInput;
};


export type MutationValidateAcosExclusionUploadedFileArgs = {
  input: AcosTargetExclusionValidationInput;
};


export type MutationVerifyGtiNbyGs1Args = {
  GTIN: Scalars['String'];
};

/** Email, push, or Slack notification wrapper type */
export type NotificationSubscription = {
  __typename?: 'NotificationSubscription';
  listId: Scalars['ID'];
  productReportingSubscriptionList?: Maybe<ProductReportingSubscriptionList>;
  recipientId: Scalars['ID'];
  slackChannel?: Maybe<SlackChannel>;
  slackChannelId?: Maybe<Scalars['ID']>;
};

/** Notification subscription result wrapper type */
export type NotificationSubscriptionResult = {
  __typename?: 'NotificationSubscriptionResult';
  errors: Array<Scalars['String']>;
  subscriptions: Array<NotificationSubscription>;
  success: Scalars['Boolean'];
};

/** Notification unsubscription result wrapper type */
export type NotificationUnsubscriptionResult = {
  __typename?: 'NotificationUnsubscriptionResult';
  errors: Array<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum ObjectivePriority {
  Critical = 'CRITICAL',
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export type OverrideHoldingBinEntriesInput = {
  bypassIncrementalSyndication?: InputMaybe<Scalars['Boolean']>;
  comment: Scalars['String'];
  endDatetime: Scalars['DateTime'];
  ids: Array<Scalars['ID']>;
  price: Scalars['String'];
};

export type Pageable = {
  totalResults: Scalars['Int'];
};

export type Pagination = {
  offset: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type ParentChildAsinPair = {
  __typename?: 'ParentChildASINPair';
  childIDs: Array<Maybe<Scalars['String']>>;
  parentID: Scalars['String'];
};

export type ParentChildAsinPairResult = {
  __typename?: 'ParentChildASINPairResult';
  results: Array<Maybe<ParentChildAsinPair>>;
};

export type PassthroughResponse = {
  __typename?: 'PassthroughResponse';
  errors: Array<Scalars['String']>;
  found: Array<Scalars['String']>;
  notFound: Array<Scalars['String']>;
};

/** Input for Catalog/Listing Items Passthrough */
export type PassthroughSpapi = {
  identifier: Scalars['String'];
  marketplace: Scalars['String'];
  storeName: Scalars['String'];
};

export type PlaidCreateAccessTokenInput = {
  institutionId: Scalars['String'];
  institutionName: Scalars['String'];
  publicToken: Scalars['String'];
};

export type PlaidInstitution = {
  __typename?: 'PlaidInstitution';
  accessToken?: Maybe<Scalars['String']>;
  balanceLastUpdated?: Maybe<Scalars['Date']>;
  institutionId?: Maybe<Scalars['ID']>;
  institutionName?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['String']>;
  lastDateCompleted?: Maybe<Scalars['Date']>;
};

export type PlaidLinkToken = {
  __typename?: 'PlaidLinkToken';
  linkToken: Scalars['String'];
};

export type PlannerRequest = Timestamped & {
  __typename?: 'PlannerRequest';
  amzDaysOfSupply?: Maybe<Scalars['String']>;
  arriveByDate: Scalars['DateTime'];
  asin: Scalars['String'];
  brand: Scalars['String'];
  confirmedQuantity?: Maybe<Scalars['Int']>;
  coordinatorEmail: Scalars['String'];
  coordinatorName: Scalars['String'];
  createdOn: Scalars['DateTime'];
  expediteReason?: Maybe<ExpediteReasons>;
  fbaIds: Array<FbaIds>;
  fromWarehouse?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isExpedite?: Maybe<Scalars['Boolean']>;
  issue?: Maybe<Scalars['Int']>;
  issueName: Scalars['String'];
  issueStatus: Scalars['String'];
  listingSku?: Maybe<Scalars['String']>;
  marketplace?: Maybe<Scalars['String']>;
  multiChannelFulfillment: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  orderByDate?: Maybe<Scalars['DateTime']>;
  plannerEmail: Scalars['String'];
  plannerName: Scalars['String'];
  priority: Scalars['String'];
  quantity: Scalars['Int'];
  queenSku?: Maybe<Scalars['String']>;
  region: Scalars['String'];
  sellerCentralAccount: Scalars['String'];
  status: Scalars['String'];
  uniqueId?: Maybe<Scalars['String']>;
  updatedOn: Scalars['DateTime'];
};

export type PlannerRequestInfoInput = {
  asin: Scalars['String'];
  region: Scalars['String'];
};

export type PlannerRequestInput = {
  arriveByDate: Scalars['DateTime'];
  asin: Scalars['String'];
  expediteReason?: InputMaybe<ExpediteReasons>;
  isExpedite: Scalars['Boolean'];
  multiChannelFulfillment: Scalars['String'];
  notes: Scalars['String'];
  plannerEmail: Scalars['String'];
  plannerName: Scalars['String'];
  quantity: Scalars['Int'];
  region: Scalars['String'];
};

export type PlannerRequestInputV2 = {
  arriveByDate: Scalars['DateTime'];
  asin: Scalars['String'];
  brand?: InputMaybe<Scalars['String']>;
  expediteReason?: InputMaybe<ExpediteReasons>;
  isExpedite: Scalars['Boolean'];
  isManualMode: Scalars['Boolean'];
  listingSku?: InputMaybe<Scalars['String']>;
  multiChannelFulfillment: Scalars['String'];
  notes: Scalars['String'];
  plannerEmail: Scalars['String'];
  plannerName: Scalars['String'];
  quantity: Scalars['Int'];
  region: Scalars['String'];
  sellerCentralAccount?: InputMaybe<Scalars['String']>;
};

export type PlannerRequestResponse = Pageable & {
  __typename?: 'PlannerRequestResponse';
  results: Array<PlannerRequest>;
  totalResults: Scalars['Int'];
};

/** Planner request result wrapper type */
export type PlannerRequestResult = {
  __typename?: 'PlannerRequestResult';
  errors: Array<Scalars['String']>;
  requests: Array<PlannerRequest>;
  success: Scalars['Boolean'];
};

export type PlannerRequestUpdateInput = {
  closeRequest: Scalars['Boolean'];
  confirmedQuantity?: InputMaybe<Scalars['Int']>;
  fbaIds?: InputMaybe<Array<FbaIdUpdateInput>>;
  id: Scalars['ID'];
  issue?: InputMaybe<Scalars['Int']>;
  issueStatus: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  sellerCentralAccount: Scalars['String'];
  status: Scalars['String'];
  updatedOn: Scalars['DateTime'];
  userEmail: Scalars['String'];
};

export type PlanningStatus = {
  __typename?: 'PlanningStatus';
  name: Scalars['String'];
};

export type PlanningSuggestionInfo = {
  __typename?: 'PlanningSuggestionInfo';
  grossProfitLastYear?: Maybe<Scalars['Int']>;
  grossProfitLastYearForListing?: Maybe<Scalars['Int']>;
  grossRevenueLastYear?: Maybe<Scalars['Int']>;
  grossRevenueLastYearForListing?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  inventoryOnHand?: Maybe<Scalars['Int']>;
  inventoryOnHandForListing?: Maybe<Scalars['Int']>;
  isPrimaryListing?: Maybe<Scalars['Boolean']>;
  plannedInventory?: Maybe<Scalars['Int']>;
  suggestedStatus?: Maybe<PlanningStatus>;
  unitsSold?: Maybe<Scalars['Int']>;
  unitsSoldForListing?: Maybe<Scalars['Int']>;
};

export type PoCsvUploadDocumentInput = {
  fileName: Scalars['String'];
  userEmail: Scalars['String'];
  userName: Scalars['String'];
};

export type PoCreationData = {
  __typename?: 'PoCreationData';
  brand: Scalars['String'];
  buyer: Scalars['String'];
  channelId: Scalars['String'];
  dateAdded: Scalars['Date'];
  erd: Scalars['Date'];
  incoterms: Scalars['String'];
  latestDeliveryDate: Scalars['Date'];
  leadTime: Scalars['Int'];
  listingStatus: Scalars['String'];
  market: Scalars['String'];
  moq: Scalars['Int'];
  paymentTerms: Scalars['String'];
  poInstructionId: Scalars['String'];
  prepayment?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  sku: Scalars['String'];
  status: Scalars['String'];
  totalCost: Scalars['Float'];
  unitCost: Scalars['Float'];
  vendor: Scalars['String'];
};

export type PoCreationResponse = {
  __typename?: 'PoCreationResponse';
  result: Array<PoCreationData>;
};

export type PoExecutionLineItemData = {
  __typename?: 'PoExecutionLineItemData';
  amzError?: Maybe<Scalars['String']>;
  casepackSize?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  createdDate?: Maybe<Scalars['String']>;
  daysUntilErd: Scalars['Int'];
  destinationType?: Maybe<Scalars['String']>;
  erd: Scalars['Date'];
  fromCountry?: Maybe<Scalars['String']>;
  gmlCoordinator?: Maybe<Scalars['String']>;
  issue?: Maybe<Scalars['String']>;
  market: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  omniCoordinator?: Maybe<Scalars['String']>;
  poLineId: Scalars['String'];
  poNumber: Scalars['String'];
  quantity: Scalars['Int'];
  sellerAccount?: Maybe<Scalars['String']>;
  shipmentOwner?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  status: Scalars['String'];
};

export type PoExecutionResponse = {
  __typename?: 'PoExecutionResponse';
  result: Array<PoExecutionLineItemData>;
};

export type PoExecutionUploadFileResult = {
  __typename?: 'PoExecutionUploadFileResult';
  documentID?: Maybe<Scalars['UUID']>;
  errors: Array<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Pod = {
  __typename?: 'Pod';
  assignments: Array<PodAssignment>;
  businessUnit: BusinessUnit;
  createdOn: Scalars['DateTime'];
  directorCategory: DirectorCategory;
  division: Division;
  id: Scalars['ID'];
  name: Scalars['String'];
  productOverrides: Array<PodProductOverride>;
  updatedOn: Scalars['DateTime'];
};

export type PodAssignment = {
  __typename?: 'PodAssignment';
  brand: Brand;
  category: Category;
  channel: SalesChannel;
  id: Scalars['ID'];
  market: Marketplace;
  pod: Pod;
};

export type PodAssignmentCreateInput = {
  brandId: Scalars['String'];
  categoryId?: InputMaybe<Scalars['String']>;
  channel: SalesChannel;
  market: Marketplace;
  podId?: InputMaybe<Scalars['String']>;
};

export type PodAssignmentResult = Pageable & {
  __typename?: 'PodAssignmentResult';
  results?: Maybe<Array<PodAssignment>>;
  totalResults: Scalars['Int'];
};

export type PodCreateInput = {
  assignments?: InputMaybe<Array<PodAssignmentCreateInput>>;
  businessUnitId: Scalars['String'];
  directorCategoryId: Scalars['String'];
  divisionId: Scalars['String'];
  name: Scalars['String'];
  overrides?: InputMaybe<Scalars['String']>;
};

export type PodProductOverride = {
  __typename?: 'PodProductOverride';
  channel: SalesChannel;
  channelId: Scalars['String'];
  market: Marketplace;
  pod: Pod;
};

export type PodProductOverrideInput = {
  channel: SalesChannel;
  channelId: Scalars['String'];
  market: Marketplace;
};

export type PodResult = Pageable & {
  __typename?: 'PodResult';
  results?: Maybe<Array<Pod>>;
  totalResults: Scalars['Int'];
};

export type PodUpdateInput = {
  assignments?: InputMaybe<Array<PodAssignmentCreateInput>>;
  businessUnitId?: InputMaybe<Scalars['String']>;
  directorCategoryId?: InputMaybe<Scalars['String']>;
  divisionId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  overrides?: InputMaybe<Scalars['String']>;
};

export type PowerBiDashboard = {
  __typename?: 'PowerBIDashboard';
  embed: PowerBiEmbed;
  id: Scalars['ID'];
  slug: Scalars['String'];
};

export type PowerBiEmbed = {
  __typename?: 'PowerBIEmbed';
  embedToken: Scalars['String'];
  embedUrl: Scalars['String'];
  name: Scalars['String'];
};

export type Price = {
  __typename?: 'Price';
  currencyCode: Scalars['String'];
  value: Scalars['Float'];
};

export type PriceCalculationLog = Timestamped & {
  __typename?: 'PriceCalculationLog';
  category: PriceCalculationLogCategory;
  contributesToPrice: Scalars['Boolean'];
  createdOn: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  isForInformationOnly: Scalars['Boolean'];
  priceCalcTarget: PriceCalculationTarget;
  ruleSetExecution: RuleSetExecution;
  sequenceNumber: Scalars['Int'];
  source: Scalars['String'];
  updatedOn: Scalars['DateTime'];
  value?: Maybe<Scalars['Float']>;
};

export enum PriceCalculationLogCategory {
  ActionCalculation = 'ACTION_CALCULATION',
  AdjustmentConflict = 'ADJUSTMENT_CONFLICT',
  CrossTargetAdjustment = 'CROSS_TARGET_ADJUSTMENT',
  Formatting = 'FORMATTING',
  FormattingFilterEvaluation = 'FORMATTING_FILTER_EVALUATION',
  FormattingRequiredRuleEvaluation = 'FORMATTING_REQUIRED_RULE_EVALUATION',
  Guardrails = 'GUARDRAILS',
  GuardrailFilterEvaluation = 'GUARDRAIL_FILTER_EVALUATION',
  GuardrailRequiredRuleEvaluation = 'GUARDRAIL_REQUIRED_RULE_EVALUATION',
  IndividualAdjustment = 'INDIVIDUAL_ADJUSTMENT',
  IndividualAdjustmentFilterEvaluation = 'INDIVIDUAL_ADJUSTMENT_FILTER_EVALUATION',
  IndividualAdjustmentRequiredRuleEvaluation = 'INDIVIDUAL_ADJUSTMENT_REQUIRED_RULE_EVALUATION',
  Other = 'OTHER',
  Rounding = 'ROUNDING',
  RuleFilterEvaluation = 'RULE_FILTER_EVALUATION'
}

export type PriceCalculationLogResponse = {
  __typename?: 'PriceCalculationLogResponse';
  results: Array<PriceCalculationLog>;
};

export type PriceCalculationTarget = ActiveTimestamped & {
  __typename?: 'PriceCalculationTarget';
  active: Scalars['Boolean'];
  activePriceOverride?: Maybe<CalculatedPriceOverride>;
  autoCalculatePrices?: Maybe<Scalars['Boolean']>;
  brandName?: Maybe<Scalars['String']>;
  calcTargetGroupFollower?: Maybe<PriceCalculationTargetGroup>;
  calcTargetGroupLeader?: Maybe<PriceCalculationTargetGroup>;
  calculatedPrice?: Maybe<CalculatedPrice>;
  channel: SalesChannel;
  channelId: Scalars['String'];
  createdOn: Scalars['DateTime'];
  fulfillmentChannel?: Maybe<FulfillmentChannel>;
  fullStoreName?: Maybe<Scalars['String']>;
  holdingBinEntry?: Maybe<PriceCalculationTargetHoldingBinEntry>;
  id: Scalars['ID'];
  incrementalSyndicationRequest?: Maybe<IncrementalSyndicationRequest>;
  listingStatus?: Maybe<PricingListingStatus>;
  marketCode: MarketCode;
  numRelatedListings?: Maybe<Scalars['Int']>;
  subBrand?: Maybe<Scalars['String']>;
  thrasioProductId?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['Int']>;
  unitEcon?: Maybe<UnitEcon>;
  updatedOn: Scalars['DateTime'];
};

export type PriceCalculationTargetGroup = ActiveTimestamped & {
  __typename?: 'PriceCalculationTargetGroup';
  active: Scalars['Boolean'];
  createdOn: Scalars['DateTime'];
  groupFollowers: Array<PriceCalculationTarget>;
  groupLeader: PriceCalculationTarget;
  id: Scalars['ID'];
  updatedOn: Scalars['DateTime'];
};

export type PriceCalculationTargetHoldingBinEntry = ActiveTimestamped & {
  __typename?: 'PriceCalculationTargetHoldingBinEntry';
  active: Scalars['Boolean'];
  createdOn: Scalars['DateTime'];
  currentCalculationStatus: Scalars['String'];
  id: Scalars['ID'];
  initialCalculationStatus: Scalars['String'];
  resolution: HoldingBinResolution;
  resolvedDateTime?: Maybe<Scalars['DateTime']>;
  resolverId?: Maybe<InternalUser>;
  updatedOn: Scalars['DateTime'];
};

export type PriceCalculationTargetResponse = Pageable & {
  __typename?: 'PriceCalculationTargetResponse';
  results: Array<PriceCalculationTarget>;
  totalResults: Scalars['Int'];
};

export type PriceGuardRailResponse = {
  __typename?: 'PriceGuardRailResponse';
  results: Array<PriceGuardrail>;
  totalResults: Scalars['Int'];
};

export type PriceGuardrail = {
  __typename?: 'PriceGuardrail';
  active: Scalars['Boolean'];
  calcPriceChangeActionType?: Maybe<CalculationActionType>;
  calcPriceChangeAmount?: Maybe<Scalars['Float']>;
  calcPriceChangeAppliesTo?: Maybe<CalculationAppliesTo>;
  calcPriceChangeInputDataSource?: Maybe<InputDataSource>;
  calcPriceChangeInputDataSourceValue?: Maybe<InputDataSourceValue>;
  calcPriceChangeInputValueMultiplier?: Maybe<Scalars['Float']>;
  checkTarget: ConditionalPriceCheckTarget;
  checkType: ConditionalPriceCheckType;
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  filter?: Maybe<RuleSetEntityFilter>;
  id: Scalars['String'];
  maxValue?: Maybe<Scalars['Float']>;
  maxValueInputDataSource?: Maybe<InputDataSource>;
  maxValueInputDataSourceValue?: Maybe<InputDataSourceValue>;
  maxValueMultiplier?: Maybe<Scalars['Float']>;
  minValue?: Maybe<Scalars['Float']>;
  minValueInputDataSource?: Maybe<InputDataSource>;
  minValueInputDataSourceValue?: Maybe<InputDataSourceValue>;
  minValueMultiplier?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  negateRequiredRules: Scalars['Boolean'];
  order: Scalars['Int'];
  priceMarginFormula?: Maybe<PriceMarginFormula>;
  requiredRules: Array<Rule>;
  ruleSet: RuleSet;
  skipIfMissingData: Scalars['Boolean'];
  variableAssignments: Array<VariableAssignment>;
};

export enum PriceMarginFormula {
  Financial = 'FINANCIAL',
  Pricing = 'PRICING'
}

export enum PriceParticipationStatus {
  CalculationOnly = 'CALCULATION_ONLY',
  NotParticipating = 'NOT_PARTICIPATING',
  SyndicationAndCalculation = 'SYNDICATION_AND_CALCULATION'
}

export type PriceSyndicationRequest = Timestamped & {
  __typename?: 'PriceSyndicationRequest';
  channel?: Maybe<SalesChannel>;
  createdOn: Scalars['DateTime'];
  fileTransferStatus: SyndicationVerificationStatus;
  id: Scalars['ID'];
  isFullUpdate: Scalars['Boolean'];
  numFailedPriceChanges: Scalars['Int'];
  numIgnoredPriceChanges?: Maybe<Scalars['Int']>;
  numSuccessfulPriceChanges: Scalars['Int'];
  numTotalListings: Scalars['Int'];
  numUniqueChannelStorename: Scalars['Int'];
  pushToFeedonomics: Scalars['Boolean'];
  syndicationReferenceField?: Maybe<SyndicationReferenceField>;
  syndicationRequestType: SyndicationRequestType;
  syndicationRequestorId: InternalUser;
  syndicationS3Filename?: Maybe<Scalars['String']>;
  syndicationStatus: SyndicationBatchRequestStatus;
  syndicationUploadedS3Filename?: Maybe<Scalars['String']>;
  updatedOn: Scalars['DateTime'];
  uploadErrors?: Maybe<Array<SyndicationUploadError>>;
};

export type PriceSyndicationRequestHistoryResponse = Pageable & {
  __typename?: 'PriceSyndicationRequestHistoryResponse';
  results: Array<PriceSyndicationRequest>;
  totalResults: Scalars['Int'];
};

export type PriceSyndicationRequestS3DownloadUrl = {
  __typename?: 'PriceSyndicationRequestS3DownloadURL';
  error?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PricingAmazonStore = ActiveTimestamped & {
  __typename?: 'PricingAmazonStore';
  active: Scalars['Boolean'];
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  marketCode?: Maybe<Scalars['String']>;
  merchantToken?: Maybe<Scalars['String']>;
  mwsMarketplaceId: Scalars['String'];
  mwsSellerId: Scalars['String'];
  name: Scalars['String'];
  spapiStoreName?: Maybe<Scalars['String']>;
  updatedOn: Scalars['DateTime'];
};

export type PricingCatalogListing = ActiveTimestamped & {
  __typename?: 'PricingCatalogListing';
  active: Scalars['Boolean'];
  autoSyndicatePrices?: Maybe<Scalars['Boolean']>;
  b2bPriceHistory: Array<B2BListingPriceHistory>;
  brandName?: Maybe<Scalars['String']>;
  channel: SalesChannel;
  channelId: Scalars['String'];
  channelSku: Scalars['String'];
  createdOn: Scalars['DateTime'];
  fulfillmentChannel: FulfillmentChannel;
  fullStoreName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  latestB2bPrice?: Maybe<B2BListingPriceHistory>;
  latestPrice?: Maybe<ListingPriceHistory>;
  listingStatus?: Maybe<PricingListingStatus>;
  marketCode: MarketCode;
  merchantId?: Maybe<Scalars['String']>;
  merchantName: Scalars['String'];
  participationStatus?: Maybe<PriceParticipationStatus>;
  priceCalculationTarget?: Maybe<PriceCalculationTarget>;
  priceHistory: Array<ListingPriceHistory>;
  productName?: Maybe<Scalars['String']>;
  status?: Maybe<PricingCatalogStatus>;
  store?: Maybe<PricingAmazonStore>;
  subBrand?: Maybe<Scalars['String']>;
  thrasioProductId?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['Int']>;
  updatedOn: Scalars['DateTime'];
};

export type PricingCatalogListingResponse = Pageable & {
  __typename?: 'PricingCatalogListingResponse';
  results: Array<PricingCatalogListing>;
  totalResults: Scalars['Int'];
};

export enum PricingCatalogStatus {
  Active = 'ACTIVE',
  Discontinued = 'DISCONTINUED',
  Divested = 'DIVESTED',
  Launch = 'LAUNCH',
  PostActive = 'POST_ACTIVE',
  PreLaunch = 'PRE_LAUNCH',
  Unsellable = 'UNSELLABLE'
}

export enum PricingListingStatus {
  Active = 'ACTIVE',
  Discontinued = 'DISCONTINUED',
  Divested = 'DIVESTED',
  Launch = 'LAUNCH',
  PostActive = 'POST_ACTIVE',
  PreLaunch = 'PRE_LAUNCH',
  Unsellable = 'UNSELLABLE'
}

export type PricingLookupBrand = {
  __typename?: 'PricingLookupBrand';
  name: Scalars['String'];
};

export type PriorityRule = {
  __typename?: 'PriorityRule';
  operator: Scalars['String'];
  priority: Scalars['String'];
  property: Scalars['String'];
  sentiment: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type PriorityRuleInput = {
  operator: Scalars['String'];
  priority: Scalars['String'];
  property: Scalars['String'];
  sentiment: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export enum PriorityRuleOperator {
  Contains = 'CONTAINS',
  DoesNotExist = 'DOES_NOT_EXIST',
  Eq = 'EQ',
  Exists = 'EXISTS',
  Gt = 'GT',
  Gte = 'GTE',
  Lt = 'LT',
  Lte = 'LTE',
  RegexMatches = 'REGEX_MATCHES'
}

export enum PriorityRuleProperty {
  AbsoluteDifference = 'ABSOLUTE_DIFFERENCE',
  FromValue = 'FROM_VALUE',
  FromValueAndToValue = 'FROM_VALUE_AND_TO_VALUE',
  PercentDifference = 'PERCENT_DIFFERENCE',
  ToValue = 'TO_VALUE'
}

/** Akeneo product input */
export type Product = {
  channel?: InputMaybe<Scalars['String']>;
  channelId?: InputMaybe<Scalars['String']>;
  marketplace?: InputMaybe<Scalars['String']>;
  sku: Scalars['String'];
  storeName: Scalars['String'];
  tpid?: InputMaybe<Scalars['ID']>;
};

export type ProductCatalogBrand = {
  __typename?: 'ProductCatalogBrand';
  acquisitionDate?: Maybe<Scalars['Date']>;
  brandAliases?: Maybe<Array<BrandAlias>>;
  createdAt: Scalars['DateTime'];
  divestedDate?: Maybe<Scalars['Date']>;
  legacyBrand?: Maybe<LegacyBrand>;
  name: Scalars['String'];
  revenueStartDate?: Maybe<Scalars['Date']>;
  status: BrandStatus;
  updatedAt: Scalars['DateTime'];
  wholeBusiness?: Maybe<EnumYesNoNa>;
  wholeDivestBusiness?: Maybe<EnumYesNoNa>;
};

export type ProductCatalogChannel = {
  __typename?: 'ProductCatalogChannel';
  channelType: ChannelType;
  name: Scalars['String'];
};

export type ProductCatalogProduct = {
  __typename?: 'ProductCatalogProduct';
  brand: Brand;
  createdOn: Scalars['DateTime'];
  /** Unique product UUID */
  id: Scalars['ID'];
  listings: Array<ProductCatalogProductListing>;
  name: Scalars['String'];
  /** System ID for linking to other objects */
  thrasioId: Scalars['ID'];
  updatedOn: Scalars['DateTime'];
  variants: Array<ProductCatalogProductVariant>;
};

export type ProductCatalogProductInput = {
  thrasioId: Scalars['ID'];
};

export type ProductCatalogProductListing = {
  __typename?: 'ProductCatalogProductListing';
  activeObjective: ListingObjective;
  channelId: Scalars['String'];
  comments: Array<ListingComment>;
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  marketplace: Marketplace;
  name: Scalars['String'];
  product: ProductCatalogProduct;
  salesChannel: SalesChannel;
  updatedOn: Scalars['DateTime'];
  variantListings: Array<ProductCatalogProductVariantListing>;
};

export type ProductCatalogProductListingResult = Pageable & {
  __typename?: 'ProductCatalogProductListingResult';
  results: Array<ProductCatalogProductListing>;
  totalResults: Scalars['Int'];
};

export type ProductCatalogProductResult = Pageable & {
  __typename?: 'ProductCatalogProductResult';
  results: Array<ProductCatalogProduct>;
  totalResults: Scalars['Int'];
};

export type ProductCatalogProductVariant = {
  __typename?: 'ProductCatalogProductVariant';
  active: Scalars['Boolean'];
  createdOn: Scalars['DateTime'];
  /** Unique product variant UUID */
  id: Scalars['ID'];
  listings: Array<ProductCatalogProductVariantListing>;
  name: Scalars['String'];
  product: ProductCatalogProduct;
  status?: Maybe<ProductStatus>;
  /** System ID for linking to other objects */
  thrasioId: Scalars['ID'];
  updatedOn: Scalars['DateTime'];
};

export type ProductCatalogProductVariantListing = {
  __typename?: 'ProductCatalogProductVariantListing';
  bestSellerRanks: Array<AmazonBestSellerRank>;
  channelId: Scalars['String'];
  createdOn: Scalars['DateTime'];
  /** Unique product variant listing UUID */
  id: Scalars['ID'];
  images: Array<ListingImage>;
  infoReport?: Maybe<AmazonListing>;
  marketplace: Marketplace;
  name?: Maybe<Scalars['String']>;
  productListing: ProductCatalogProductListing;
  productVariant: ProductCatalogProductVariant;
  productVariantId: Scalars['String'];
  salesChannel: SalesChannel;
  status?: Maybe<ProductStatus>;
  store?: Maybe<AmazonStore>;
  updatedOn: Scalars['DateTime'];
};

export type ProductCatalogProductVariantListingResult = Pageable & {
  __typename?: 'ProductCatalogProductVariantListingResult';
  results: Array<ProductCatalogProductVariantListing>;
  totalResults: Scalars['Int'];
};

export type ProductCatalogProductVariantResult = Pageable & {
  __typename?: 'ProductCatalogProductVariantResult';
  results: Array<ProductCatalogProductVariant>;
  totalResults: Scalars['Int'];
};

export type ProductCatalogV3 = {
  __typename?: 'ProductCatalogV3';
  acquiredBrand?: Maybe<Scalars['String']>;
  acquiredBrandStatus?: Maybe<Scalars['String']>;
  acquisitionDate?: Maybe<Scalars['DateTime']>;
  brandAlias?: Maybe<Scalars['String']>;
  brandDivestedDate?: Maybe<Scalars['DateTime']>;
  casePackDimHeight?: Maybe<Scalars['String']>;
  casePackDimLength?: Maybe<Scalars['String']>;
  casePackDimVolume?: Maybe<Scalars['String']>;
  casePackDimWeight?: Maybe<Scalars['String']>;
  casePackDimWidth?: Maybe<Scalars['String']>;
  casePackQuantity?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  channelMarket?: Maybe<Scalars['String']>;
  channelName?: Maybe<Scalars['String']>;
  channelSku?: Maybe<Scalars['String']>;
  channelSkuChannelIdChannelMarket?: Maybe<Scalars['String']>;
  channelStatus?: Maybe<Scalars['String']>;
  channelid?: Maybe<Scalars['String']>;
  containerQuantity?: Maybe<Scalars['String']>;
  diligence?: Maybe<Scalars['Boolean']>;
  ean?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalSku?: Maybe<Scalars['String']>;
  externalSkuStatus?: Maybe<Scalars['String']>;
  externalsku?: Maybe<Scalars['String']>;
  fnsku?: Maybe<Scalars['String']>;
  fulfillmentChannel?: Maybe<Scalars['String']>;
  fullStoreName?: Maybe<Scalars['String']>;
  gs1RegisteredCompanyNames?: Maybe<Scalars['String']>;
  innerCasePackQuantity?: Maybe<Scalars['String']>;
  internationalLaunch?: Maybe<Scalars['Boolean']>;
  isPrimaryListing?: Maybe<Scalars['Boolean']>;
  isProductExpirable?: Maybe<Scalars['Boolean']>;
  launchDate?: Maybe<Scalars['DateTime']>;
  legacyCompany?: Maybe<Scalars['String']>;
  listingDivestedDate?: Maybe<Scalars['DateTime']>;
  listingDivestmentStatus?: Maybe<Scalars['String']>;
  listingId: Scalars['Int'];
  listingStatus?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['String']>;
  marketplaceStatus?: Maybe<Scalars['String']>;
  minimumOrderQuantity?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  newProductLaunch?: Maybe<Scalars['Boolean']>;
  parentKey?: Maybe<Scalars['String']>;
  productCatalogIgnored?: Maybe<Scalars['Boolean']>;
  productDescription?: Maybe<Scalars['String']>;
  productDimHeight?: Maybe<Scalars['String']>;
  productDimLength?: Maybe<Scalars['String']>;
  productDimVolume?: Maybe<Scalars['String']>;
  productDimWeight?: Maybe<Scalars['String']>;
  productDimWidth?: Maybe<Scalars['String']>;
  productExternalSkuHeight?: Maybe<Scalars['String']>;
  productExternalSkuLength?: Maybe<Scalars['String']>;
  productExternalSkuVolume?: Maybe<Scalars['String']>;
  productExternalSkuWeight?: Maybe<Scalars['String']>;
  productExternalSkuWidth?: Maybe<Scalars['String']>;
  productGroup?: Maybe<Scalars['String']>;
  productOwnership?: Maybe<Scalars['String']>;
  productShortName?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  productTypeColor?: Maybe<Scalars['String']>;
  productTypeOther?: Maybe<Scalars['String']>;
  productTypePackSize?: Maybe<Scalars['String']>;
  productTypeSize?: Maybe<Scalars['String']>;
  purchaseLeadTime?: Maybe<Scalars['Int']>;
  purchaseSku?: Maybe<Scalars['String']>;
  salesEntity?: Maybe<Scalars['String']>;
  skuBaseUnitType?: Maybe<Scalars['String']>;
  skuPurchasePrice?: Maybe<Scalars['Float']>;
  standardizedChannelStatus?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  storeDivestedDate?: Maybe<Scalars['DateTime']>;
  storeStatus?: Maybe<Scalars['String']>;
  thrasioOwnedGtin?: Maybe<Scalars['Boolean']>;
  thrasioProductId?: Maybe<Scalars['Float']>;
  thrasioSku?: Maybe<Scalars['Float']>;
  thrasioSkuStatus?: Maybe<Scalars['String']>;
  thrasioSkuType?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  vendor?: Maybe<Scalars['String']>;
};

export type ProductCatalogV3Response = Pageable & {
  __typename?: 'ProductCatalogV3Response';
  results: Array<ProductCatalogV3>;
  totalResults: Scalars['Int'];
};

export type ProductEvent = {
  __typename?: 'ProductEvent';
  brand?: Maybe<Scalars['String']>;
  channel: SalesChannel;
  channelId: Scalars['String'];
  comments: Array<ProductEventComment>;
  competitorId?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  creator: ProductEventCreator;
  creatorId: Scalars['String'];
  creatorType: RequesterType;
  details: Scalars['String'];
  endDate?: Maybe<Scalars['DateTime']>;
  eventClassificationTypeOption: ProductEventClassificationOption;
  eventData?: Maybe<EventData>;
  eventDataType: ProductEventDataType;
  eventPriority: ProductEventPriority;
  eventType: ProductEventType;
  fromScreenshotUrl?: Maybe<Scalars['String']>;
  /** Unique product UUID */
  id: Scalars['ID'];
  marketplace: Marketplace;
  source: ProductEventSource;
  startDate: Scalars['DateTime'];
  thrasioProductId?: Maybe<Scalars['ID']>;
  toScreenshotUrl?: Maybe<Scalars['String']>;
  updatedOn: Scalars['DateTime'];
};

export type ProductEventClassificationOption = {
  __typename?: 'ProductEventClassificationOption';
  defaultPriority: ProductEventPriority;
  defaultSentiment: ProductEventSentiment;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  priorityRules?: Maybe<Array<PriorityRule>>;
  productEventType: ProductEventTypeOption;
};

export type ProductEventClassificationResult = Pageable & {
  __typename?: 'ProductEventClassificationResult';
  results: Array<ProductEventClassificationOption>;
  totalResults: Scalars['Int'];
};

export type ProductEventClassificationUpdateInput = {
  defaultPriority?: InputMaybe<ProductEventPriority>;
  defaultSentiment?: InputMaybe<ProductEventSentiment>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  priorityRules?: InputMaybe<Array<PriorityRuleInput>>;
};

export type ProductEventComment = {
  __typename?: 'ProductEventComment';
  childComments: Array<ProductEventComment>;
  content: Scalars['String'];
  createdOn: Scalars['DateTime'];
  creator: InternalUser;
  id: Scalars['ID'];
  productEvent: ProductEvent;
  updatedOn: Scalars['DateTime'];
};

export type ProductEventCommentCreateInput = {
  content: Scalars['String'];
  productEventId: Scalars['ID'];
};

export type ProductEventCreateInput = {
  channel: SalesChannel;
  channelId: Scalars['String'];
  details: Scalars['String'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  eventPriority?: InputMaybe<ProductEventPriority>;
  eventType: ProductEventType;
  marketplace: Marketplace;
  startDate: Scalars['DateTime'];
};

export type ProductEventCreated = {
  __typename?: 'ProductEventCreated';
  productEvent: ProductEvent;
};

export type ProductEventCreator = InternalTool | InternalUser;

export enum ProductEventDataType {
  BooleanChangeEventData = 'BOOLEAN_CHANGE_EVENT_DATA',
  DatetimeChangeEventData = 'DATETIME_CHANGE_EVENT_DATA',
  FloatChangeEventData = 'FLOAT_CHANGE_EVENT_DATA',
  ImageChangeEventData = 'IMAGE_CHANGE_EVENT_DATA',
  IntChangeEventData = 'INT_CHANGE_EVENT_DATA',
  TextChangeEventData = 'TEXT_CHANGE_EVENT_DATA',
  TextListChangeEventData = 'TEXT_LIST_CHANGE_EVENT_DATA',
  UnknownChangeEventData = 'UNKNOWN_CHANGE_EVENT_DATA',
  UrlChangeEventData = 'URL_CHANGE_EVENT_DATA',
  VideoChangeEventData = 'VIDEO_CHANGE_EVENT_DATA'
}

export type ProductEventInput = {
  channelId: Scalars['String'];
};

export enum ProductEventPriority {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export type ProductEventRangesStat = {
  __typename?: 'ProductEventRangesStat';
  endDate?: Maybe<Scalars['Date']>;
  startDate: Scalars['Date'];
};

export type ProductEventRangesValue = {
  __typename?: 'ProductEventRangesValue';
  field: Scalars['String'];
  ranges: Array<Maybe<ProductEventRangesStat>>;
};

export type ProductEventResult = Pageable & {
  __typename?: 'ProductEventResult';
  results: Array<ProductEvent>;
  totalResults: Scalars['Int'];
};

export enum ProductEventSentiment {
  Negative = 'NEGATIVE',
  Neutral = 'NEUTRAL',
  Positive = 'POSITIVE'
}

export enum ProductEventSource {
  Coupon = 'COUPON',
  Custom = 'CUSTOM',
  Deals = 'DEALS',
  ProductChangelog = 'PRODUCT_CHANGELOG',
  SharkRetailProductChangelog = 'SHARK_RETAIL_PRODUCT_CHANGELOG',
  User = 'USER'
}

export enum ProductEventType {
  Availability = 'AVAILABILITY',
  Badges = 'BADGES',
  CategoriesAndRanks = 'CATEGORIES_AND_RANKS',
  Competition = 'COMPETITION',
  Experiments = 'EXPERIMENTS',
  HijackerSeller = 'HIJACKER_SELLER',
  HijackSeller = 'HIJACK_SELLER',
  HolidaysAndEvents = 'HOLIDAYS_AND_EVENTS',
  Inventory = 'INVENTORY',
  ListingContent = 'LISTING_CONTENT',
  MarketingAndAffiliate = 'MARKETING_AND_AFFILIATE',
  Other = 'OTHER',
  PricingAndPromotions = 'PRICING_AND_PROMOTIONS',
  ProductVariations = 'PRODUCT_VARIATIONS',
  RatingsAndReviews = 'RATINGS_AND_REVIEWS',
  Search = 'SEARCH'
}

export type ProductEventTypeOption = {
  __typename?: 'ProductEventTypeOption';
  classifications: Array<ProductEventClassificationOption>;
  key: Scalars['String'];
};

export type ProductEventUpdateInput = {
  channel?: InputMaybe<SalesChannel>;
  channelId?: InputMaybe<Scalars['String']>;
  details?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  eventPriority?: InputMaybe<ProductEventPriority>;
  eventType: ProductEventType;
  id: Scalars['ID'];
  marketplace?: InputMaybe<Marketplace>;
  source?: InputMaybe<ProductEventSource>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type ProductEventUpdated = {
  __typename?: 'ProductEventUpdated';
  productEvent: ProductEvent;
};

export type ProductIntegrityCreateResultInput = {
  requestId: Scalars['String'];
  tester: Scalars['String'];
  urls: Array<Scalars['String']>;
};

export type ProductIntegrityDetail = {
  __typename?: 'ProductIntegrityDetail';
  /** The detail's name. eg. Status */
  label: Scalars['String'];
  /** @deprecated Changed to optional because value might be null */
  value: Scalars['String'];
  valueV2?: Maybe<Scalars['String']>;
};

export type ProductIntegrityDetailListListResponse = {
  __typename?: 'ProductIntegrityDetailListListResponse';
  errors: Array<Scalars['String']>;
  results: Array<Array<ProductIntegrityDetail>>;
  success: Scalars['Boolean'];
};

export type ProductIntegrityDetailListResponse = {
  __typename?: 'ProductIntegrityDetailListResponse';
  errors: Array<Scalars['String']>;
  results: Array<ProductIntegrityDetail>;
  success: Scalars['Boolean'];
};

export type ProductIntegrityLabInfo = {
  __typename?: 'ProductIntegrityLabInfo';
  contactName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  location: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type ProductIntegrityLabInfoListResponse = {
  __typename?: 'ProductIntegrityLabInfoListResponse';
  errors: Array<Scalars['String']>;
  results: Array<ProductIntegrityLabInfo>;
  success: Scalars['Boolean'];
};

export type ProductIntegrityLabInput = {
  contactName: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  faxNumber?: InputMaybe<Scalars['String']>;
  location: Scalars['String'];
  mobileNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type ProductIntegrityMutationResponse = {
  __typename?: 'ProductIntegrityMutationResponse';
  errors: Array<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ProductIntegrityNewLabResponse = {
  __typename?: 'ProductIntegrityNewLabResponse';
  errors: Array<Scalars['String']>;
  labInfo: LabInfo;
  success: Scalars['Boolean'];
};

export type ProductIntegrityQuickLink = {
  __typename?: 'ProductIntegrityQuickLink';
  step: Scalars['Int'];
  url: Scalars['String'];
};

export type ProductIntegrityQuoteApprovalLinkInputV2 = {
  requestId: Scalars['String'];
  tester: Scalars['String'];
  urls: Array<Scalars['String']>;
};

export type ProductIntegrityQuoteApprovalLinkV2 = {
  __typename?: 'ProductIntegrityQuoteApprovalLinkV2';
  approval: Scalars['String'];
  id: Scalars['String'];
  url: Scalars['String'];
};

export type ProductIntegrityReAssignPimInput = {
  country: Scalars['String'];
  pimName: Scalars['String'];
  requestId: Scalars['String'];
  userEmail: Scalars['String'];
};

export enum ProductIntegrityRegionEnum {
  Canada = 'CANADA',
  Europe = 'EUROPE',
  Japan = 'JAPAN',
  UnitedStates = 'UNITED_STATES'
}

export type ProductIntegrityRegionInput = {
  comment?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  regionAttributes: Array<Scalars['String']>;
};

export type ProductIntegrityRejectQuoteInput = {
  id: Scalars['String'];
};

export type ProductIntegrityRejectTestInput = {
  id: Scalars['String'];
};

export type ProductIntegrityRequest = Timestamped & {
  __typename?: 'ProductIntegrityRequest';
  asin: Scalars['String'];
  brand: Scalars['String'];
  chinaAssignedPim?: Maybe<Scalars['String']>;
  chinaAssignedPimEmail?: Maybe<Scalars['String']>;
  chinaProductIntegrityManager?: Maybe<Scalars['String']>;
  chinaProductIntegrityManagerEmail?: Maybe<Scalars['String']>;
  chinaQa?: Maybe<Scalars['String']>;
  chinaQaEmail?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  /** @deprecated Replaced by legalName. */
  legal?: Maybe<Scalars['String']>;
  legalEmail?: Maybe<Scalars['String']>;
  legalName?: Maybe<Scalars['String']>;
  linkToListing: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  productCategory: Scalars['String'];
  productType?: Maybe<Scalars['String']>;
  quoteApprovalOnly: Scalars['Boolean'];
  requesterEmail: Scalars['String'];
  requesterFunction: Scalars['String'];
  requesterName: Scalars['String'];
  retailChannel?: Maybe<Array<Scalars['String']>>;
  status: Scalars['String'];
  suppliers?: Maybe<Array<Scalars['String']>>;
  /** datetime format = MM/dd/YYYY */
  testNeededByDate: Scalars['DateTime'];
  testType: Scalars['String'];
  uniqueId?: Maybe<Scalars['String']>;
  updatedOn: Scalars['DateTime'];
  usAssignedPim?: Maybe<Scalars['String']>;
  usAssignedPimEmail?: Maybe<Scalars['String']>;
  usProductIntegrityManager?: Maybe<Scalars['String']>;
  usProductIntegrityManagerEmail?: Maybe<Scalars['String']>;
};

export type ProductIntegrityRequestInputV3 = {
  childAsin: Scalars['String'];
  linkToListing: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  productCategory: Scalars['String'];
  quoteApprovalOnly: Scalars['Boolean'];
  regions: Array<Scalars['String']>;
  relatedChildAsins: Array<Scalars['String']>;
  requesterEmail: Scalars['String'];
  requesterFunction: Scalars['String'];
  requesterName: Scalars['String'];
  retailChannel?: InputMaybe<Array<Scalars['String']>>;
  testNeededByDate: Scalars['DateTime'];
  testType: Scalars['String'];
  usTeamOnly?: InputMaybe<Scalars['Boolean']>;
};

export type ProductIntegrityRequestNewQuotesInput = {
  comment: Scalars['String'];
  links: Array<ProductIntegrityRejectQuoteInput>;
  quoteApprovalId: Scalars['String'];
  requestId: Scalars['String'];
  userEmail: Scalars['String'];
};

export type ProductIntegrityRequestNewTestsInput = {
  comment: Scalars['String'];
  links: Array<ProductIntegrityRejectTestInput>;
  resultId: Scalars['String'];
  userEmail: Scalars['String'];
};

export type ProductIntegrityRequestRegion = {
  __typename?: 'ProductIntegrityRequestRegion';
  checked: Scalars['Boolean'];
  comment: Scalars['String'];
  name: Scalars['String'];
  regionAttributes: Array<RegionAttributes>;
};

export type ProductIntegrityRequestReviewInput = {
  certificateNeeded: Scalars['Boolean'];
  childAsins: Array<Scalars['String']>;
  otherLabs: Array<Scalars['String']>;
  regions: Array<ProductIntegrityRegionInput>;
  reviewerEmail: Scalars['String'];
  reviewerName: Scalars['String'];
  sampleQuantity: Scalars['Int'];
  sampleType: Scalars['String'];
  testTurnAroundTime: Scalars['String'];
  testingLabs: Array<ProductIntegrityLabInput>;
};

export enum ProductIntegrityRequesterFunctionEnum {
  DueDiligence = 'DUE_DILIGENCE',
  InternationalExpansion = 'INTERNATIONAL_EXPANSION',
  MAndA = 'M_AND_A',
  ProductIntegrity = 'PRODUCT_INTEGRITY',
  ProductLaunchTeam = 'PRODUCT_LAUNCH_TEAM',
  RevenueBrandProtection = 'REVENUE_BRAND_PROTECTION',
  StrategicSourcing = 'STRATEGIC_SOURCING'
}

export type ProductIntegrityResponse = Pageable & {
  __typename?: 'ProductIntegrityResponse';
  results: Array<ProductIntegrityRequest>;
  totalResults: Scalars['Int'];
};

export type ProductIntegrityResponseGetQuickLinks = {
  __typename?: 'ProductIntegrityResponseGetQuickLinks';
  errors: Array<Scalars['String']>;
  results: Array<ProductIntegrityQuickLink>;
  success: Scalars['Boolean'];
};

export type ProductIntegrityResponseGetRequestRegions = {
  __typename?: 'ProductIntegrityResponseGetRequestRegions';
  errors: Array<Scalars['String']>;
  results: Array<ProductIntegrityRequestRegion>;
  success: Scalars['Boolean'];
};

export type ProductIntegrityResponseGetStepFour = {
  __typename?: 'ProductIntegrityResponseGetStepFour';
  errors: Array<Scalars['String']>;
  result?: Maybe<ProductIntegrityStepFour>;
  success: Scalars['Boolean'];
};

export type ProductIntegrityResponseGetStepOne = {
  __typename?: 'ProductIntegrityResponseGetStepOne';
  errors: Array<Scalars['String']>;
  result?: Maybe<ProductIntegrityReviewInfo>;
  success: Scalars['Boolean'];
};

export type ProductIntegrityResponseGetStepThreeObjs = {
  __typename?: 'ProductIntegrityResponseGetStepThreeObjs';
  errors: Array<Scalars['String']>;
  result: Array<ProductIntegrityStepThree>;
  success: Scalars['Boolean'];
};

export type ProductIntegrityResponseReassignPim = {
  __typename?: 'ProductIntegrityResponseReassignPIM';
  error: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ProductIntegrityResultLink = {
  __typename?: 'ProductIntegrityResultLink';
  approval: Scalars['String'];
  id: Scalars['String'];
  url: Scalars['String'];
};

export type ProductIntegrityReviewInfo = Timestamped & {
  __typename?: 'ProductIntegrityReviewInfo';
  certificateNeeded: Scalars['Boolean'];
  childAsins: Array<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  otherLabs: Array<LabInfo>;
  reviewerEmail: Scalars['String'];
  reviewerName: Scalars['String'];
  sampleQuantity: Scalars['String'];
  sampleType: Scalars['String'];
  status: Scalars['String'];
  /** test_turnaround_time format example: Rush (100% Surcharge) */
  testTurnaroundTime: Scalars['String'];
  testingLabs: Array<ProductIntegrityLabInfo>;
  updatedOn: Scalars['DateTime'];
};

export enum ProductIntegritySampleQuantityEnum {
  Six = 'SIX',
  Three = 'THREE'
}

export enum ProductIntegritySampleTypeEnum {
  DevelopmentSample = 'DEVELOPMENT_SAMPLE',
  FinishedComponents = 'FINISHED_COMPONENTS',
  FinishedGoods = 'FINISHED_GOODS',
  RawMaterials = 'RAW_MATERIALS'
}

export type ProductIntegrityStepFour = {
  __typename?: 'ProductIntegrityStepFour';
  id: Scalars['String'];
  status: Scalars['String'];
};

export type ProductIntegrityStepFourSubmitInput = {
  certs: Array<Scalars['String']>;
  docs: Array<Scalars['String']>;
  requestId: Scalars['String'];
  userEmail: Scalars['String'];
};

export type ProductIntegrityStepThree = {
  __typename?: 'ProductIntegrityStepThree';
  comment: Scalars['String'];
  id: Scalars['String'];
  links: Array<ProductIntegrityResultLink>;
  status: Scalars['String'];
};

export type ProductIntegrityStepTwo = {
  __typename?: 'ProductIntegrityStepTwo';
  action: Scalars['String'];
  comment: Scalars['String'];
  id: Scalars['String'];
  links: Array<ProductIntegrityQuoteApprovalLinkV2>;
  status: Scalars['String'];
  /** datetime format = MM/dd/YYYY */
  testingStartDate?: Maybe<Scalars['DateTime']>;
};

export type ProductIntegrityStepTwoListResponse = {
  __typename?: 'ProductIntegrityStepTwoListResponse';
  errors: Array<Scalars['String']>;
  results: Array<ProductIntegrityStepTwo>;
  success: Scalars['Boolean'];
};

export type ProductIntegritySubmitQuoteInput = {
  approval: Scalars['String'];
  id: Scalars['String'];
};

export type ProductIntegritySubmitQuotesAcknowledgeInput = {
  requestId: Scalars['String'];
  testingDate: Scalars['DateTime'];
  userEmail: Scalars['String'];
};

export type ProductIntegritySubmitQuotesApprovalInput = {
  comment?: InputMaybe<Scalars['String']>;
  links: Array<ProductIntegritySubmitQuoteInput>;
  manager: Scalars['String'];
  requestId: Scalars['String'];
};

export type ProductIntegritySubmitResultApprovalInput = {
  comment?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  manager: Scalars['String'];
};

export type ProductIntegrityTestResultLink = {
  __typename?: 'ProductIntegrityTestResultLink';
  approval: Scalars['String'];
  id: Scalars['String'];
  url: Scalars['String'];
};

export type ProductIntegrityTestResultLinkListResponse = {
  __typename?: 'ProductIntegrityTestResultLinkListResponse';
  errors: Array<Scalars['String']>;
  results: Array<ProductIntegrityTestResultLink>;
  success: Scalars['Boolean'];
};

export enum ProductIntegrityTestTypeEnum {
  Benchmark = 'BENCHMARK',
  Compliance = 'COMPLIANCE',
  PerformanceIntegrity = 'PERFORMANCE_INTEGRITY'
}

export enum ProductIntegrityTurnaroundTimeEnum {
  Express = 'EXPRESS',
  Regular = 'REGULAR',
  Rush = 'RUSH',
  SameDay = 'SAME_DAY'
}

/** Akeneo product model input */
export type ProductModel = {
  channel?: InputMaybe<Scalars['String']>;
  channelId: Scalars['String'];
  marketplace: Scalars['String'];
  tpid?: InputMaybe<Scalars['ID']>;
};

export type ProductModelResponse = {
  __typename?: 'ProductModelResponse';
  errors?: Maybe<Array<Scalars['String']>>;
  results?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Akeneo product model update input */
export type ProductModelUpdate = {
  attributeName: Scalars['String'];
  attributeValue: Scalars['String'];
  channelId: Scalars['String'];
  channelName?: InputMaybe<Scalars['String']>;
  familyVariant?: InputMaybe<Scalars['String']>;
  marketplace: Scalars['String'];
  parent?: InputMaybe<Scalars['String']>;
  thrasioProductId?: InputMaybe<Scalars['ID']>;
};

export type ProductModelUpdateMutationResponse = {
  __typename?: 'ProductModelUpdateMutationResponse';
  errors?: Maybe<Array<Scalars['String']>>;
  results?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type ProductReportingSubscriptionBulkEdited = {
  __typename?: 'ProductReportingSubscriptionBulkEdited';
  subscriptionLists: Array<ProductReportingSubscriptionList>;
};

export type ProductReportingSubscriptionCreated = {
  __typename?: 'ProductReportingSubscriptionCreated';
  subscriptionList: ProductReportingSubscriptionList;
};

export type ProductReportingSubscriptionEdited = {
  __typename?: 'ProductReportingSubscriptionEdited';
  subscriptionList: ProductReportingSubscriptionList;
};

export type ProductReportingSubscriptionList = {
  __typename?: 'ProductReportingSubscriptionList';
  allAsinsEnabled: Scalars['Boolean'];
  brands: Array<Brand>;
  channelIds: Array<Scalars['String']>;
  competitorAlertsEnabled: Scalars['Boolean'];
  courierId: Scalars['String'];
  createdOn: Scalars['DateTime'];
  creator?: Maybe<InternalUser>;
  eventClassificationTypes: Array<Scalars['String']>;
  eventTypes: Array<ProductEventType>;
  frequency: SubscriptionFrequency;
  id: Scalars['ID'];
  name: Scalars['String'];
  numberOfEventsInThePastDay: Scalars['Int'];
  numberOfEventsInThePastWeek: Scalars['Int'];
  numberOfReceiptsInThePastDay: Scalars['Int'];
  numberOfReceiptsInThePastWeek: Scalars['Int'];
  thrasioProductIds: Array<Scalars['String']>;
  updatedOn: Scalars['DateTime'];
};

export type ProductReportingSubscriptionListBulkEditInput = {
  brands?: InputMaybe<Array<Scalars['String']>>;
  channelIds?: InputMaybe<Array<Scalars['String']>>;
  eventClassificationTypes?: InputMaybe<Array<Scalars['String']>>;
  eventClassifications?: InputMaybe<Array<Scalars['String']>>;
  eventTypes?: InputMaybe<Array<ProductEventType>>;
  frequency?: InputMaybe<SubscriptionFrequency>;
  name?: InputMaybe<Scalars['String']>;
  subscriptionIds: Array<Scalars['ID']>;
  thrasioProductIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ProductReportingSubscriptionListCreateInput = {
  allAsinsEnabled?: InputMaybe<Scalars['Boolean']>;
  brands: Array<Scalars['String']>;
  channelIds: Array<Scalars['String']>;
  competitorAlertsEnabled?: InputMaybe<Scalars['Boolean']>;
  eventClassificationTypes?: InputMaybe<Array<Scalars['String']>>;
  eventClassifications?: InputMaybe<Array<Scalars['String']>>;
  eventTypes?: InputMaybe<Array<ProductEventType>>;
  frequency: SubscriptionFrequency;
  thrasioProductIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ProductReportingSubscriptionListEditInput = {
  allAsinsEnabled?: InputMaybe<Scalars['Boolean']>;
  brands: Array<Scalars['String']>;
  channelIds: Array<Scalars['String']>;
  competitorAlertsEnabled?: InputMaybe<Scalars['Boolean']>;
  eventClassificationTypes?: InputMaybe<Array<Scalars['String']>>;
  eventClassifications?: InputMaybe<Array<Scalars['String']>>;
  eventTypes?: InputMaybe<Array<ProductEventType>>;
  frequency: SubscriptionFrequency;
  id: Scalars['ID'];
  thrasioProductIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ProductReportingSubscriptionListResult = Pageable & {
  __typename?: 'ProductReportingSubscriptionListResult';
  results: Array<ProductReportingSubscriptionList>;
  totalResults: Scalars['Int'];
};

export type ProductResponse = {
  __typename?: 'ProductResponse';
  errors?: Maybe<Array<Scalars['String']>>;
  results?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export enum ProductStatus {
  Active = 'ACTIVE',
  Discontinued = 'DISCONTINUED',
  Draft = 'DRAFT',
  IsParent = 'IS_PARENT',
  NotThrasio = 'NOT_THRASIO',
  PostActive = 'POST_ACTIVE',
  PreActive = 'PRE_ACTIVE',
  Unknown = 'UNKNOWN'
}

export type ProductTpid = {
  __typename?: 'ProductTPID';
  createdAt: Scalars['DateTime'];
  internallyDeveloped: Scalars['Boolean'];
  ownershipStatus: Scalars['String'];
  productCategory?: Maybe<Scalars['String']>;
  productDivestDate?: Maybe<Scalars['DateTime']>;
  productGroup?: Maybe<Scalars['String']>;
  productLaunch?: Maybe<Scalars['Boolean']>;
  shortName?: Maybe<Scalars['String']>;
  thrasioProductId?: Maybe<Scalars['Float']>;
};

export type ProductTpidUpdate = {
  ownershipStatus?: InputMaybe<Scalars['String']>;
  productDivestDate?: InputMaybe<Scalars['DateTime']>;
  productLaunch?: InputMaybe<Scalars['Boolean']>;
  thrasioProductId?: InputMaybe<Scalars['Float']>;
};

export type ProductTimelineGraphStat = {
  __typename?: 'ProductTimelineGraphStat';
  date: Scalars['Date'];
  value: Scalars['Float'];
};

export type ProductTimelineGraphValue = {
  __typename?: 'ProductTimelineGraphValue';
  field: Scalars['String'];
  stats: Array<Maybe<ProductTimelineGraphStat>>;
};

export type PromoteRuleSetInput = {
  keepOriginal?: InputMaybe<Scalars['Boolean']>;
  ruleSetId: Scalars['String'];
  toRuleSetName?: InputMaybe<Scalars['String']>;
};

export enum PromotionDealType {
  BestDeal = 'BEST_DEAL',
  LightningDeal = 'LIGHTNING_DEAL'
}

export type PromotionSyndicationRequest = Timestamped & {
  __typename?: 'PromotionSyndicationRequest';
  createdOn: Scalars['DateTime'];
  cretedOn: Scalars['DateTime'];
  id: Scalars['ID'];
  isDryRun: Scalars['Boolean'];
  mutationType: PromotionSyndicationRequestMutationType;
  numTotalListings: Scalars['Int'];
  syndicationRequestorId: InternalUser;
  syndicationStatus: SyndicationBatchRequestStatus;
  syndicationUploadedS3Filename?: Maybe<Scalars['String']>;
  updatedOn: Scalars['DateTime'];
};

export type PromotionSyndicationRequestHistoryResponse = Pageable & {
  __typename?: 'PromotionSyndicationRequestHistoryResponse';
  results: Array<PromotionSyndicationRequest>;
  totalResults: Scalars['Int'];
};

export enum PromotionSyndicationRequestMutationType {
  Create = 'CREATE',
  Disable = 'DISABLE',
  Update = 'UPDATE'
}

export type Query = {
  __typename?: 'Query';
  acosExcludedAsins: AcosExcludedAsinsResponse;
  affiliateValidation: GetValidationsResponse;
  affiliateValidationErrors: AffiliateValidationErrorResponse;
  /** List all Slack subscriptions for all channels */
  allSlackSubscriptions: NotificationSubscriptionResult;
  amazonListings: AmazonListingResult;
  asinCoordinatorMapping?: Maybe<Array<Maybe<AsinCoordinatorMapping>>>;
  b2cPricingHistory: B2CPricingHistoryResponse;
  barcodeType: BarCodeTypeResponse;
  bidManagementAccountFilterData: BidManagementAccountOptionsResponse;
  brand: BrandResponse;
  brandAlias: BrandAliasResponse;
  brandLookup: Array<PricingLookupBrand>;
  brandOptions: Array<Brand>;
  brandStatus: BrandStatusResponse;
  brandUserAssignments: BrandUserAssignmentResult;
  brands: BrandResult;
  bulkUpdateResponse: Scalars['String'];
  businessUnitOptions: Array<BusinessUnit>;
  calculatedPriceHistory: CalculatedPriceHistoryResolver;
  calculatedPriceOverrideComments: CalculatedPriceOverrideCommentsResponse;
  calculatedPriceOverrideMaxDuration: CalculatedPriceOverrideMaxDurationResponse;
  calculatedPriceOverrides: CalculatedPriceOverridesResponse;
  canaryQuery: CanaryResponse;
  catalogItem: PassthroughResponse;
  categoryOptions: Array<Category>;
  channelKeyWithMultipleTpidReport: ChannelKeyMultipleTpidResponse;
  checkDocumentWasProcessed: CheckDocumentProcessedResponse;
  dimUnits: DimUnitsResponse;
  directorCategoryOptions: Array<DirectorCategory>;
  divisionOptions: Array<Division>;
  getAuditLogs: AcosTargetAuditLogsResponse;
  getChangeReport: AcosTargetChangeReportResponse;
  getDeclineReasonTypes: AcosTargetDeclineReasonsResponse;
  getHelpfulReviewRates: RaMetricResult;
  getLatestReviewChanges: AcosTargetReviewChangesResponse;
  getPoCreationData: PoCreationResponse;
  getPoData: PoExecutionResponse;
  getReviewRates: RaMetricResult;
  getReviewToTotalRating: RaMetricResult;
  getSCAutomationToolsValidationStatus?: Maybe<StatusWithResults>;
  getTaskStatus: AcosTargetTaskStatusResponse;
  getToolSettings: AcosTargetSettingsResponse;
  getTypeaheadLoadJsonState: LoadTypeaheadJsonState;
  holdingBinCalcTargetHistory: HoldingBinCalcTargetHistoryResponse;
  holdingBinEntries: HoldingBinEntriesResponse;
  holdingBinFilterMetadata: HoldingBinFilterMetadataResponse;
  incrementalSyndicationRequestHistory: IncrementalSyndicationRequestHistoryResponse;
  individualPriceAdjustment: IndividualPriceAdjustmentResponse;
  inputDataSources: InputDataSourcesResponse;
  irisGetPackagingInfo: GetPackagingInfoResponse;
  issuesQuery: IssueResponse;
  legacyBrand: LegacyBrandResponse;
  listSCAutomationToolsCountryMarketplaceMap?: Maybe<ListMarketplaces>;
  listSCAutomationToolsMerchants?: Maybe<ListMerchants>;
  listSCAutomationToolsMerchantsWithDateRange?: Maybe<ListMerchantsWithDateRange>;
  listSCAutomationToolsReports: ListReports;
  listing: ListingsResponse;
  listingComplianceRules: ListingComplianceRuleResult;
  listingItem: PassthroughResponse;
  listingPriceSyndicationRequestHistory: ListingPriceSyndicationRequestHistoryResponse;
  listingPromotionSyndicationRequestHistory: ListingPromotionSyndicationRequestHistoryResponse;
  listingScreenshot: ListingScreenshot;
  listingsForBrandMapping: ListingsForBrandMappingResponse;
  listingsForPlanningStatus: ListingsForPlanningStatusResponse;
  listingsForPlanningStatusReport: ListingsForPlanningStatusReportResponse;
  listingsForReviewPlanningStatus: ListingsForPlanningStatusResponse;
  listingsForTPID: ListingsForTpidToolResponse;
  me?: Maybe<InternalUser>;
  multipleBrandsForSameTpidReport: MultiBrandsSameTpidResponse;
  /** Notifications configuration for the current user */
  myNotificationConfig: UserNotificationConfig;
  parentChildASINPairs: ParentChildAsinPairResult;
  performTOValidation: ToValidationRequestResults;
  plannerRequestSkuListing: SkuInfoResponse;
  plannerRequestsQuery: PlannerRequestResponse;
  podAssignments: PodAssignmentResult;
  podOptions: Array<Pod>;
  pods: PodResult;
  powerBIDashboard: PowerBiDashboard;
  powerBIEmbed: PowerBiEmbed;
  priceCalculationLogs: PriceCalculationLogResponse;
  priceCalculationTargets: PriceCalculationTargetResponse;
  priceGuardrail: PriceGuardRailResponse;
  priceSyndicationRequestDownloadUrl: PriceSyndicationRequestS3DownloadUrl;
  priceSyndicationRequestHistory: PriceSyndicationRequestHistoryResponse;
  pricingCatalog: PricingCatalogListingResponse;
  pricingCatalogListingsFromCalcTarget: PricingCatalogListingResponse;
  pricingRuleSetExecutions: RuleSetExecutionsSpfResponse;
  pricingRuleSets: RuleSetSpfResponse;
  pricingRules: RuleSpfResponse;
  productCatalogV3: ProductCatalogV3Response;
  productCompetitorEvents: Array<Maybe<ProductEvent>>;
  /** Single event query for getting all the specific details of an event, such as event data */
  productEvent: ProductEvent;
  productEventClassifications: ProductEventClassificationResult;
  productEventOptions?: Maybe<Array<Scalars['String']>>;
  productEventRanges: Array<ProductEventRangesValue>;
  productEventTypeOptions: Array<ProductEventTypeOption>;
  /**
   * SortPaginateFilter query for getting product events in bulk.
   * Avoid querying the "eventData" attribute on this query as it risks efficiency by
   * performing many database joins per each result
   */
  productEvents: ProductEventResult;
  productIntegrityGetLabInfo: ProductIntegrityLabInfoListResponse;
  productIntegrityGetRequestLinks: ProductIntegrityResponseGetQuickLinks;
  productIntegrityGetRequestRegions: ProductIntegrityResponseGetRequestRegions;
  productIntegrityGetReview: ProductIntegrityResponseGetStepOne;
  productIntegrityGetStepFourObj: ProductIntegrityResponseGetStepFour;
  productIntegrityGetStepThreeObjs: ProductIntegrityResponseGetStepThreeObjs;
  productIntegrityGetStepTwoObjs: ProductIntegrityStepTwoListResponse;
  productIntegrityGetTestResultLinks: ProductIntegrityTestResultLinkListResponse;
  productIntegrityLabInfo: StrListResponse;
  productIntegrityLabs: StrListResponse;
  productIntegrityProductCategories: StrListResponse;
  productIntegrityRelatedChildAsins: StrListResponse;
  productIntegrityRequestDetails: ProductIntegrityDetailListResponse;
  productIntegrityRequestMoreDetails: ProductIntegrityDetailListResponse;
  productIntegrityRequests: ProductIntegrityResponse;
  productIntegrityVendorInformation: ProductIntegrityDetailListListResponse;
  productListing: ProductCatalogProductListingResult;
  /** @deprecated Use productVariantListingOptions query instead */
  productListingOptions?: Maybe<Array<Scalars['String']>>;
  productReportingSubscriptionList?: Maybe<ProductReportingSubscriptionList>;
  productReportingSubscriptionLists: ProductReportingSubscriptionListResult;
  productTimelineGraph: Array<ProductTimelineGraphValue>;
  productVariantListingOptions?: Maybe<Array<Scalars['String']>>;
  productVariantListings: ProductCatalogProductVariantListingResult;
  productVariants: ProductCatalogProductVariantResult;
  products: ProductCatalogProductResult;
  promotionSyndicationRequestHistory: PromotionSyndicationRequestHistoryResponse;
  rawSku: RawSkuResponse;
  rawSkuList: RawSkuListResponse;
  rawSkuStatus: RawSkuStatusResponse;
  replnRecommendations: ReplnRecommendationsResponse;
  reportedProductEvents: ReportedProductEvents;
  requestAffiliateDataDownloadUrl: AffiliateS3PresignedDownloadUrl;
  requestPartners: AffiliatePartnerRequestResponse;
  reviewAuditDiligenceBrands: Array<RaBrand>;
  reviewAuditReferenceBrands: Array<RaBrand>;
  roleOptions: Array<Role>;
  ruleSetEntityFilters: RuleSetEntityFilterResponse;
  segments: SegmentsResponse;
  skuType: SkuTypeResponse;
  slackChannels: Array<Maybe<SlackChannel>>;
  /** List all Slack subscriptions for a particular channel */
  slackSubscriptions: NotificationSubscriptionResult;
  sourceType: SourceTypeResponse;
  splatTestServiceExampleQuery: SplatTestServiceExampleResponse;
  store: StoresResponse;
  storeByName: GroupedStore;
  stores: ShortGroupedStoreResponse;
  storesMarketplace: Array<StoreMarketplace>;
  teamOptions: Array<Team>;
  thrasioSku: ThrasioSkuResponse;
  thrasioSkuStatus: ThrasioSkuStatusResponse;
  tpidMissingAmazonChannelReport: TpidMissingAmazonChannelResponse;
  tpmlDocument: TpmlDocumentResponse;
  typeaheadInternalUsers: InternalUserTypeaheadResponse;
  typeaheadRaw: RawTypeaheadResponse;
  typeaheadSlackChannels: SlackChannelTypeaheadResponse;
  typeaheadUploadStatusInternalUsers: TypeaheadUploadInternalUsersTaskState;
  typeaheadUploadStatusSlackChannels: TypeaheadUploadSlackChannelsTaskState;
  unitEconDataSources: UnitEconDataSourcesResponse;
  userAssignments: UserAssignmentResult;
  users: Array<Maybe<InternalUser>>;
  variationThemeAttributes: VariationThemeAttributeResponse;
  verificationAllGTINList: CompanyBarcodeOwnershipVerificationStatusResponse;
};


export type QueryAcosExcludedAsinsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryAffiliateValidationArgs = {
  input: AffiliateValidationRequestInput;
};


export type QueryAffiliateValidationErrorsArgs = {
  input: AffiliateValidationErrorRequestInput;
};


export type QueryAmazonListingsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<InputMaybe<SortDescriptor>>>;
};


export type QueryAsinCoordinatorMappingArgs = {
  asin: Scalars['ID'];
};


export type QueryB2cPricingHistoryArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryBarcodeTypeArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryBrandArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryBrandAliasArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryBrandLookupArgs = {
  limit: Scalars['Int'];
  searchText: Scalars['String'];
};


export type QueryBrandOptionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};


export type QueryBrandStatusArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryBrandUserAssignmentsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<InputMaybe<SortDescriptor>>>;
};


export type QueryBrandsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<InputMaybe<SortDescriptor>>>;
};


export type QueryBulkUpdateResponseArgs = {
  fileKey: Scalars['String'];
};


export type QueryBusinessUnitOptionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};


export type QueryCalculatedPriceHistoryArgs = {
  pagination?: InputMaybe<Pagination>;
  priceCalcTargetId: Scalars['String'];
};


export type QueryCalculatedPriceOverrideCommentsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryCalculatedPriceOverridesArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryCatalogItemArgs = {
  input: Array<PassthroughSpapi>;
};


export type QueryCategoryOptionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};


export type QueryChannelKeyWithMultipleTpidReportArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type QueryCheckDocumentWasProcessedArgs = {
  input: CheckDocumentProcessedInput;
};


export type QueryDimUnitsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryDirectorCategoryOptionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};


export type QueryDivisionOptionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};


export type QueryGetHelpfulReviewRatesArgs = {
  isTargetThrasioBrand?: Scalars['Boolean'];
  referenceBrandList?: Array<Scalars['ID']>;
  targetAsins?: Array<Scalars['ID']>;
  targetBrand: Scalars['ID'];
};


export type QueryGetReviewRatesArgs = {
  isTargetThrasioBrand?: Scalars['Boolean'];
  referenceBrandList?: Array<Scalars['ID']>;
  targetAsins?: Array<Scalars['ID']>;
  targetBrand: Scalars['ID'];
};


export type QueryGetReviewToTotalRatingArgs = {
  isTargetThrasioBrand?: Scalars['Boolean'];
  referenceBrandList?: Array<Scalars['ID']>;
  targetAsins?: Array<Scalars['ID']>;
  targetBrand: Scalars['ID'];
};


export type QueryGetScAutomationToolsValidationStatusArgs = {
  task?: InputMaybe<Scalars['String']>;
};


export type QueryGetTypeaheadLoadJsonStateArgs = {
  taskId: Scalars['ID'];
};


export type QueryHoldingBinCalcTargetHistoryArgs = {
  calcTargetId: Scalars['String'];
  holdingBinEntryId: Scalars['String'];
};


export type QueryHoldingBinEntriesArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryIncrementalSyndicationRequestHistoryArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryIndividualPriceAdjustmentArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryInputDataSourcesArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryIrisGetPackagingInfoArgs = {
  input: GetPackagingInfoInput;
};


export type QueryIssuesQueryArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryLegacyBrandArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryListScAutomationToolsReportsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<InputMaybe<SortDescriptor>>>;
};


export type QueryListingArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryListingComplianceRulesArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<InputMaybe<SortDescriptor>>>;
};


export type QueryListingItemArgs = {
  input: Array<PassthroughSpapi>;
};


export type QueryListingPriceSyndicationRequestHistoryArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryListingPromotionSyndicationRequestHistoryArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryListingScreenshotArgs = {
  channel: SalesChannel;
  channelId: Scalars['String'];
  date?: InputMaybe<Scalars['Date']>;
  marketplace: Marketplace;
};


export type QueryListingsForBrandMappingArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
  userEmail?: InputMaybe<Scalars['String']>;
};


export type QueryListingsForPlanningStatusArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
  userEmail?: InputMaybe<Scalars['String']>;
};


export type QueryListingsForPlanningStatusReportArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
  userEmail?: InputMaybe<Scalars['String']>;
};


export type QueryListingsForReviewPlanningStatusArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
  userEmail?: InputMaybe<Scalars['String']>;
};


export type QueryListingsForTpidArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryMultipleBrandsForSameTpidReportArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type QueryPerformToValidationArgs = {
  input: ToValidationInput;
};


export type QueryPlannerRequestSkuListingArgs = {
  input: PlannerRequestInfoInput;
};


export type QueryPlannerRequestsQueryArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryPodAssignmentsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<InputMaybe<SortDescriptor>>>;
};


export type QueryPodOptionsArgs = {
  limit: Scalars['Int'];
  value?: InputMaybe<Scalars['String']>;
};


export type QueryPodsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<InputMaybe<SortDescriptor>>>;
};


export type QueryPowerBiDashboardArgs = {
  slug: Scalars['String'];
};


export type QueryPowerBiEmbedArgs = {
  datasetId: Scalars['ID'];
  reportId: Scalars['ID'];
  workspaceId: Scalars['ID'];
};


export type QueryPriceCalculationLogsArgs = {
  priceCalcTargetId: Scalars['String'];
  ruleSetExecutionId: Scalars['String'];
};


export type QueryPriceCalculationTargetsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryPriceGuardrailArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryPriceSyndicationRequestDownloadUrlArgs = {
  key: Scalars['String'];
};


export type QueryPriceSyndicationRequestHistoryArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryPricingCatalogArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryPricingCatalogListingsFromCalcTargetArgs = {
  priceCalcTargetId: Scalars['String'];
};


export type QueryPricingRuleSetExecutionsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryPricingRuleSetsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryPricingRulesArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryProductCatalogV3Args = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryProductCompetitorEventsArgs = {
  channelId: Scalars['String'];
  date: Scalars['Date'];
};


export type QueryProductEventArgs = {
  id: Scalars['ID'];
};


export type QueryProductEventClassificationsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<InputMaybe<SortDescriptor>>>;
};


export type QueryProductEventOptionsArgs = {
  field: Scalars['String'];
  filter?: InputMaybe<RequestFilter>;
  limit: Scalars['Int'];
  value?: InputMaybe<Scalars['String']>;
};


export type QueryProductEventRangesArgs = {
  filter?: InputMaybe<RequestFilter>;
};


export type QueryProductEventsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<InputMaybe<SortDescriptor>>>;
};


export type QueryProductIntegrityGetRequestLinksArgs = {
  requestId: Scalars['String'];
};


export type QueryProductIntegrityGetRequestRegionsArgs = {
  requestId: Scalars['String'];
};


export type QueryProductIntegrityGetReviewArgs = {
  id: Scalars['String'];
};


export type QueryProductIntegrityGetStepFourObjArgs = {
  requestId: Scalars['String'];
};


export type QueryProductIntegrityGetStepThreeObjsArgs = {
  id: Scalars['String'];
};


export type QueryProductIntegrityGetStepTwoObjsArgs = {
  id: Scalars['String'];
};


export type QueryProductIntegrityGetTestResultLinksArgs = {
  id: Scalars['String'];
};


export type QueryProductIntegrityRelatedChildAsinsArgs = {
  childAsin: Scalars['String'];
};


export type QueryProductIntegrityRequestDetailsArgs = {
  id: Scalars['String'];
};


export type QueryProductIntegrityRequestMoreDetailsArgs = {
  id: Scalars['String'];
};


export type QueryProductIntegrityRequestsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryProductIntegrityVendorInformationArgs = {
  requestId: Scalars['String'];
};


export type QueryProductListingArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<InputMaybe<SortDescriptor>>>;
};


export type QueryProductListingOptionsArgs = {
  field: Scalars['String'];
  filter?: InputMaybe<RequestFilter>;
  limit: Scalars['Int'];
  value?: InputMaybe<Scalars['String']>;
};


export type QueryProductReportingSubscriptionListArgs = {
  id: Scalars['ID'];
};


export type QueryProductReportingSubscriptionListsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<InputMaybe<SortDescriptor>>>;
};


export type QueryProductTimelineGraphArgs = {
  filter?: InputMaybe<RequestFilter>;
};


export type QueryProductVariantListingOptionsArgs = {
  field: Scalars['String'];
  filter?: InputMaybe<RequestFilter>;
  limit: Scalars['Int'];
  value?: InputMaybe<Scalars['String']>;
};


export type QueryProductVariantListingsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<InputMaybe<SortDescriptor>>>;
};


export type QueryProductVariantsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<InputMaybe<SortDescriptor>>>;
};


export type QueryProductsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<InputMaybe<SortDescriptor>>>;
};


export type QueryPromotionSyndicationRequestHistoryArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryRawSkuArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryRawSkuListArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryRawSkuStatusArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryReplnRecommendationsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryReportedProductEventsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<InputMaybe<SortDescriptor>>>;
};


export type QueryRequestAffiliateDataDownloadUrlArgs = {
  input: AffiliateDownloadUrlRequest;
};


export type QueryRequestPartnersArgs = {
  input: AffiliatePartnerRequestInput;
};


export type QueryReviewAuditDiligenceBrandsArgs = {
  filter: Scalars['String'];
};


export type QueryRoleOptionsArgs = {
  filter?: InputMaybe<RequestFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};


export type QueryRuleSetEntityFiltersArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QuerySegmentsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QuerySkuTypeArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QuerySlackChannelsArgs = {
  channelIds: Array<Scalars['ID']>;
};


export type QuerySlackSubscriptionsArgs = {
  slackChannelId: Scalars['String'];
};


export type QuerySourceTypeArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QuerySplatTestServiceExampleQueryArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryStoreArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryStoreByNameArgs = {
  name: Scalars['String'];
};


export type QueryStoresArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryTeamOptionsArgs = {
  filter?: InputMaybe<RequestFilter>;
  limit: Scalars['Int'];
  value?: InputMaybe<Scalars['String']>;
};


export type QueryThrasioSkuArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryThrasioSkuStatusArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryTpidMissingAmazonChannelReportArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type QueryTpmlDocumentArgs = {
  request: DocumentRequestInput;
};


export type QueryTypeaheadInternalUsersArgs = {
  limit: Scalars['Int'];
  searchTerms: Array<Scalars['String']>;
};


export type QueryTypeaheadRawArgs = {
  limit?: Scalars['Int'];
  namespace: Scalars['String'];
  searchTerms: Array<Scalars['String']>;
};


export type QueryTypeaheadSlackChannelsArgs = {
  limit: Scalars['Int'];
  searchTerms: Array<Scalars['String']>;
};


export type QueryTypeaheadUploadStatusInternalUsersArgs = {
  taskId: Scalars['ID'];
};


export type QueryTypeaheadUploadStatusSlackChannelsArgs = {
  taskId: Scalars['ID'];
};


export type QueryUnitEconDataSourcesArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};


export type QueryUserAssignmentsArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<InputMaybe<SortDescriptor>>>;
};


export type QueryUsersArgs = {
  emails: Array<Scalars['String']>;
};


export type QueryVerificationAllGtinListArgs = {
  filter?: InputMaybe<RequestFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Array<SortDescriptor>>;
};

export type RaBrand = {
  __typename?: 'RABrand';
  asins: Array<Scalars['ID']>;
  name: Scalars['ID'];
};

export type RaErrorInvalidTargetAsins = {
  __typename?: 'RAErrorInvalidTargetAsins';
  asins: Array<Scalars['ID']>;
};

export type RaErrorNotEnoughReferenceData = {
  __typename?: 'RAErrorNotEnoughReferenceData';
  available: Scalars['Int'];
  required: Scalars['Int'];
};

export type RaErrorNotEnoughTargetData = {
  __typename?: 'RAErrorNotEnoughTargetData';
  available: Scalars['Int'];
  required: Scalars['Int'];
};

export type RaHistogramParams = {
  __typename?: 'RAHistogramParams';
  binEdges: Array<Scalars['Float']>;
  reference: Array<Scalars['Float']>;
  target: Array<Scalars['Float']>;
};

export type RaMetricError = RaErrorInvalidTargetAsins | RaErrorNotEnoughReferenceData | RaErrorNotEnoughTargetData;

export type RaMetricResult = {
  __typename?: 'RAMetricResult';
  errors: Array<RaMetricError>;
  histograms: RaHistogramParams;
  referenceBrandList: Array<Scalars['ID']>;
  reviewAuditSummary: RaSummary;
  targetBrand: Scalars['ID'];
};

export enum RaStatus {
  Fail = 'FAIL',
  Invalid = 'INVALID',
  Pass = 'PASS'
}

export type RaSummary = {
  __typename?: 'RASummary';
  actualVal: Scalars['Float'];
  status: RaStatus;
  threshold: Scalars['Float'];
};

export type RawSku = {
  __typename?: 'RawSku';
  barCodes?: Maybe<Array<BarCode>>;
  baseUnitTypePerSku?: Maybe<Scalars['String']>;
  brand?: Maybe<ProductCatalogBrand>;
  completeness: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  creator: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  moq?: Maybe<Scalars['Int']>;
  purchaseLeadTime?: Maybe<Scalars['Int']>;
  purchasePrice?: Maybe<Scalars['Float']>;
  skuDimensions?: Maybe<Array<SkuDimension>>;
  sourceOfData?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  thrasioSku?: Maybe<ThrasioSku>;
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
  vendor?: Maybe<Scalars['String']>;
  vendorSubsidiary?: Maybe<Scalars['String']>;
};

export type RawSkuBulkUpdateResponse = {
  __typename?: 'RawSkuBulkUpdateResponse';
  error?: Maybe<Scalars['String']>;
  results?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type RawSkuCreate = {
  acquiredBrand: Scalars['String'];
  barcode?: InputMaybe<Scalars['String']>;
  barcodeType?: InputMaybe<Scalars['String']>;
  creator: Scalars['String'];
  rawSku?: InputMaybe<Scalars['String']>;
  skuType: Scalars['String'];
  source?: InputMaybe<Scalars['String']>;
  sourceType?: InputMaybe<Scalars['String']>;
};

export type RawSkuCreateOutput = {
  __typename?: 'RawSkuCreateOutput';
  acquiredBrand: Scalars['String'];
  barcode?: Maybe<Scalars['String']>;
  barcodeType?: Maybe<Scalars['String']>;
  creator: Scalars['String'];
  id: Scalars['ID'];
  rawSku: Scalars['String'];
  skuType: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  sourceType?: Maybe<Scalars['String']>;
  thrasioSku: Scalars['String'];
};

export type RawSkuCreateResponse = {
  __typename?: 'RawSkuCreateResponse';
  error?: Maybe<Scalars['String']>;
  results?: Maybe<RawSkuCreateOutput>;
  success: Scalars['Boolean'];
};

export type RawSkuElement = {
  __typename?: 'RawSkuElement';
  barcodeSourceName?: Maybe<Scalars['String']>;
  barcodeType?: Maybe<Scalars['String']>;
  barcodeValue?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  completeness: Scalars['Boolean'];
  creator: Scalars['String'];
  dateIntroduced?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  shortName?: Maybe<Scalars['String']>;
  sourceOfData?: Maybe<Scalars['String']>;
  statusName?: Maybe<Scalars['String']>;
  thrasioOwnedGtin?: Maybe<Scalars['Boolean']>;
  thrasioSkuValue?: Maybe<Scalars['String']>;
  typeName?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type RawSkuGeneralDimUpdate = {
  id: Scalars['ID'];
  unit: Scalars['String'];
  value: Scalars['Float'];
};

export type RawSkuGeneralDimUpdateOutput = {
  __typename?: 'RawSkuGeneralDimUpdateOutput';
  id: Scalars['ID'];
  unit: Scalars['String'];
  value: Scalars['Float'];
};

export type RawSkuGeneralDimUpdateResponse = {
  __typename?: 'RawSkuGeneralDimUpdateResponse';
  error?: Maybe<Scalars['String']>;
  results?: Maybe<Array<RawSkuGeneralDimUpdateOutput>>;
  success: Scalars['Boolean'];
};

export type RawSkuListResponse = Pageable & {
  __typename?: 'RawSkuListResponse';
  results: Array<RawSkuElement>;
  totalResults: Scalars['Int'];
};

export type RawSkuProductDimensionUpdateResponse = {
  __typename?: 'RawSkuProductDimensionUpdateResponse';
  error?: Maybe<Scalars['String']>;
  results?: Maybe<Array<RawSkuTripleDimensionUpdateOutput>>;
  success: Scalars['Boolean'];
};

export type RawSkuResponse = Pageable & {
  __typename?: 'RawSkuResponse';
  results: Array<RawSku>;
  totalResults: Scalars['Int'];
};

export type RawSkuStatus = {
  __typename?: 'RawSkuStatus';
  name: Scalars['String'];
};

export type RawSkuStatusResponse = Pageable & {
  __typename?: 'RawSkuStatusResponse';
  results: Array<RawSkuStatus>;
  totalResults: Scalars['Int'];
};

export type RawSkuTripleDimensionUpdate = {
  height: Scalars['Float'];
  id: Scalars['ID'];
  length: Scalars['Float'];
  unit: Scalars['String'];
  width: Scalars['Float'];
};

export type RawSkuTripleDimensionUpdateOutput = {
  __typename?: 'RawSkuTripleDimensionUpdateOutput';
  height: Scalars['Float'];
  id: Scalars['ID'];
  length: Scalars['Float'];
  unit: Scalars['String'];
  width: Scalars['Float'];
};

export type RawSkuUpdate = {
  baseUnitTypePerSku?: InputMaybe<Scalars['String']>;
  brand?: InputMaybe<Scalars['String']>;
  creator: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  moq?: InputMaybe<Scalars['Int']>;
  purchaseLeadTime?: InputMaybe<Scalars['Int']>;
  purchasePrice?: InputMaybe<Scalars['Float']>;
  skuType?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<Scalars['String']>;
  vendorSubsidiary?: InputMaybe<Scalars['String']>;
};

export type RawSkuUpdateOutput = {
  __typename?: 'RawSkuUpdateOutput';
  baseUnitTypePerSku?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  creator: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  moq?: Maybe<Scalars['Int']>;
  purchaseLeadTime?: Maybe<Scalars['Int']>;
  purchasePrice?: Maybe<Scalars['Float']>;
  skuType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
  vendorSubsidiary?: Maybe<Scalars['String']>;
};

export type RawSkuUpdateResponse = {
  __typename?: 'RawSkuUpdateResponse';
  error?: Maybe<Scalars['String']>;
  results?: Maybe<Array<RawSkuUpdateOutput>>;
  success: Scalars['Boolean'];
};

export type RawTypeaheadResponse = BaseTypeaheadResponse & {
  __typename?: 'RawTypeaheadResponse';
  namespace: Scalars['String'];
  prefixes: Array<Scalars['String']>;
  suggestions: Array<RawTypeaheadSuggestion>;
};

export type RawTypeaheadSuggestion = BaseTypeaheadSuggestion & {
  __typename?: 'RawTypeaheadSuggestion';
  displayText: Scalars['String'];
  key: Scalars['String'];
};

export enum RefreshScheduleUnit {
  Day = 'DAY',
  Hour = 'HOUR'
}

export type RefreshTriggerInput = {
  id?: InputMaybe<Scalars['String']>;
  refreshType: RefreshType;
  scheduleScalar?: InputMaybe<Scalars['Int']>;
  scheduleUnit?: InputMaybe<RefreshScheduleUnit>;
};

export enum RefreshType {
  Event = 'EVENT',
  Schedule = 'SCHEDULE'
}

export type Region = {
  __typename?: 'Region';
  marketplaces: Array<Scalars['String']>;
  mwsSellerId: Scalars['String'];
  regionName: Array<Scalars['String']>;
};

export type RegionAttributes = {
  __typename?: 'RegionAttributes';
  checked: Scalars['Boolean'];
  name: Scalars['String'];
};

export type RegionCreateInput = {
  marketplaces: Array<Scalars['String']>;
  mwsSellerId: Scalars['String'];
  regionName: Array<Scalars['String']>;
};

export type RegionUpdateInput = {
  marketplaces: Array<Scalars['String']>;
  mwsSellerId: Scalars['String'];
  regionName: Array<Scalars['String']>;
};

export type ReleaseHoldingBinEntriesInput = {
  comment: Scalars['String'];
  holdingBinEntryIds: Array<Scalars['ID']>;
};

export type ReplAcceptInput = {
  reviewData: Array<ReplnReviewDataInput>;
};

export type ReplenishmentRequest = Timestamped & {
  __typename?: 'ReplenishmentRequest';
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedOn: Scalars['DateTime'];
};

export type ReplenismentRequestResult = {
  __typename?: 'ReplenismentRequestResult';
  errors: Array<Scalars['String']>;
  requests: Array<ReplenishmentRequest>;
  success: Scalars['Boolean'];
};

export type ReplnAdjustment = {
  __typename?: 'ReplnAdjustment';
  adjustmentReason: ReplnReviewReason;
  adjustmentValue: Scalars['Int'];
  userEmail: Scalars['String'];
};

export type ReplnGeneralResp = {
  __typename?: 'ReplnGeneralResp';
  error?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type ReplnRecommendation = {
  __typename?: 'ReplnRecommendation';
  adjustment?: Maybe<ReplnAdjustment>;
  amzOnhand: Scalars['Int'];
  brandName: Scalars['String'];
  channelId: Scalars['ID'];
  demandForecast: Scalars['Int'];
  expectedOnhand: Scalars['Int'];
  latestDeliveryDate: Scalars['Date'];
  multiChannelFulfillment: Scalars['Boolean'];
  onOrderPlannerRequest: Scalars['Int'];
  onOrderTransferOrder: Scalars['Int'];
  orderByDate: Scalars['Date'];
  planner: Scalars['String'];
  quantity: Scalars['Int'];
  region: ReplnRegionCode;
  restockLimit: Scalars['Int'];
  revenue?: Maybe<Scalars['Float']>;
  sellerAccountInfo?: Maybe<SellerAccountInfo>;
  threePlOnhand: Scalars['Int'];
};

export type ReplnRecommendationsResponse = Pageable & {
  __typename?: 'ReplnRecommendationsResponse';
  results: Array<ReplnRecommendation>;
  totalResults: Scalars['Int'];
};

export enum ReplnRegionCode {
  Us = 'US'
}

export type ReplnRejectInput = {
  rejectReason: ReplnReviewReason;
  reviewData: ReplnReviewDataInput;
};

export type ReplnReviewDataInput = {
  channelId: Scalars['ID'];
  quantity: Scalars['Int'];
  region: ReplnRegionCode;
  userEmail: Scalars['String'];
};

export enum ReplnReviewReason {
  AsinBlacklisted = 'ASIN_BLACKLISTED',
  AsinBlacklistedAtWarehouse = 'ASIN_BLACKLISTED_AT_WAREHOUSE',
  CasePackSizeOverLimit = 'CASE_PACK_SIZE_OVER_LIMIT',
  DemandCorrection = 'DEMAND_CORRECTION',
  MissAsinLookup = 'MISS_ASIN_LOOKUP',
  MissCasePack = 'MISS_CASE_PACK',
  MissCoordinatorEmail = 'MISS_COORDINATOR_EMAIL',
  MissPlannerEmail = 'MISS_PLANNER_EMAIL',
  PartialCases = 'PARTIAL_CASES',
  ProductExpirable = 'PRODUCT_EXPIRABLE',
  RequiresAttention = 'REQUIRES_ATTENTION',
  WarehouseBlacklisted = 'WAREHOUSE_BLACKLISTED',
  Wrong_3PlOh = 'WRONG_3PL_OH',
  WrongAmazonOh = 'WRONG_AMAZON_OH',
  WrongWarehouse = 'WRONG_WAREHOUSE'
}

export type ReplnUpdateInput = {
  reviewData: ReplnReviewDataInput;
  updateReason: ReplnReviewReason;
  withApproval: Scalars['Boolean'];
};

export type ReportProductEventInput = {
  comment?: InputMaybe<Scalars['String']>;
  eventId: Scalars['ID'];
};

export type ReportProductEventResponse = GenericError | ReportedProductEvent;

export type ReportedProductEvent = {
  __typename?: 'ReportedProductEvent';
  comment?: Maybe<Scalars['String']>;
  eventId: Scalars['ID'];
  reporterId: Scalars['String'];
};

export type ReportedProductEventList = {
  __typename?: 'ReportedProductEventList';
  comment?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  productEvent: ProductEvent;
  reporter: InternalUser;
  reporterId: Scalars['ID'];
};

export type ReportedProductEvents = Pageable & {
  __typename?: 'ReportedProductEvents';
  results: Array<ReportedProductEventList>;
  totalResults: Scalars['Int'];
};

export type RequestFilter = {
  filters?: InputMaybe<Array<InputMaybe<IndividualFilter>>>;
  logic?: InputMaybe<FilterLogic>;
};

export type RequestLatestListingPriceHistoryInput = {
  channel: SalesChannel;
  channelId: Scalars['String'];
  channelSku: Scalars['String'];
  fulfillmentChannel: FulfillmentChannel;
  fullStoreName: Scalars['String'];
  marketCode: MarketCode;
  merchantName?: InputMaybe<Scalars['String']>;
};

export type RequestLatestListingPriceHistoryResponse = {
  __typename?: 'RequestLatestListingPriceHistoryResponse';
  error?: Maybe<Scalars['String']>;
  listing?: Maybe<PricingCatalogListing>;
  success: Scalars['Boolean'];
};

export enum RequesterType {
  Tool = 'TOOL',
  User = 'USER'
}

export type ResetTpid = {
  channelSkuIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type ResetTpidResponse = {
  __typename?: 'ResetTPIDResponse';
  error?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Scalars['Int']>>;
  success: Scalars['Boolean'];
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Rule = ActiveTimestamped & {
  __typename?: 'Rule';
  actionType: Scalars['String'];
  active: Scalars['Boolean'];
  amount?: Maybe<Scalars['Float']>;
  calcAppliesTo: Scalars['String'];
  calculationAmountInputDataSource?: Maybe<InputDataSource>;
  calculationAmountInputDataSourceValue?: Maybe<InputDataSourceValue>;
  createdOn: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  filter?: Maybe<RuleSetEntityFilter>;
  id: Scalars['ID'];
  inputDataSource: InputDataSource;
  inputDataSourceValue: InputDataSourceValue;
  name: Scalars['String'];
  order: Scalars['Int'];
  participateInPriceFormatting: Scalars['Boolean'];
  ruleSet?: Maybe<RuleSet>;
  skipIfMissingData: Scalars['Boolean'];
  updatedOn: Scalars['DateTime'];
  useOptimalMarginFormula: Scalars['Boolean'];
  variableAssignments: Array<VariableAssignment>;
};

export type RuleSpfResponse = Pageable & {
  __typename?: 'RuleSPFResponse';
  results: Array<Rule>;
  totalResults: Scalars['Int'];
};

export type RuleSet = ActiveTimestamped & {
  __typename?: 'RuleSet';
  active: Scalars['Boolean'];
  alternateUnitEconDataSource?: Maybe<UnitEconDataSource>;
  createdOn: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastRun?: Maybe<RuleSetLastRun>;
  minPriceCalcDenominator: Scalars['Float'];
  name: Scalars['String'];
  ruleSetType: RuleSetType;
  rules: Array<RuleTypeForRuleSetQuery>;
  scheduleParticipation: Scalars['String'];
  updatedOn: Scalars['DateTime'];
};

export type RuleSetEntityFilter = ActiveTimestamped & {
  __typename?: 'RuleSetEntityFilter';
  active: Scalars['Boolean'];
  channels: Array<SalesChannel>;
  createdOn: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  marketplaces: Array<MarketCode>;
  name: Scalars['String'];
  negateChannels: Scalars['Boolean'];
  negateMarketplaces: Scalars['Boolean'];
  negateSegments: Scalars['Boolean'];
  segments: Array<Segment>;
  updatedOn: Scalars['DateTime'];
};

export type RuleSetEntityFilterResponse = Pageable & {
  __typename?: 'RuleSetEntityFilterResponse';
  results: Array<RuleSetEntityFilter>;
  totalResults: Scalars['Int'];
};

export type RuleSetExecution = Timestamped & {
  __typename?: 'RuleSetExecution';
  anomalyDetectionStatus: AnomalyDetectionStatus;
  asOfDate?: Maybe<Scalars['DateTime']>;
  createdOn: Scalars['DateTime'];
  executionStatus: RuleSetExecutionStatus;
  hasCustomDate: Scalars['Boolean'];
  id: Scalars['ID'];
  requestType: Scalars['String'];
  ruleSetId: Scalars['ID'];
  ruleSetType: RuleSetType;
  runDate: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
};

export enum RuleSetExecutionStatus {
  Error = 'ERROR',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type RuleSetExecutionsSpfResponse = Pageable & {
  __typename?: 'RuleSetExecutionsSPFResponse';
  results: Array<RuleSetExecution>;
  totalResults: Scalars['Int'];
};

export type RuleSetLastRun = {
  __typename?: 'RuleSetLastRun';
  lastRunDate?: Maybe<Scalars['DateTime']>;
  lastRunExecutionStatus?: Maybe<Scalars['String']>;
};

export type RuleSetSpfResponse = Pageable & {
  __typename?: 'RuleSetSPFResponse';
  results: Array<RuleSet>;
  totalResults: Scalars['Int'];
};

export enum RuleSetScheduleParticipation {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
  Na = 'NA'
}

export enum RuleSetType {
  Experiment = 'EXPERIMENT',
  Production = 'PRODUCTION',
  Staging = 'STAGING'
}

export type RuleTypeForRuleSetQuery = ActiveTimestamped & {
  __typename?: 'RuleTypeForRuleSetQuery';
  actionType: Scalars['String'];
  active: Scalars['Boolean'];
  amount: Scalars['Float'];
  calcAppliesTo: Scalars['String'];
  createdOn: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  filter?: Maybe<RuleSetEntityFilter>;
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  updatedOn: Scalars['DateTime'];
};

export type S3PresignedPostUrl = {
  __typename?: 'S3PresignedPostUrl';
  fields: Scalars['String'];
  key: Scalars['String'];
  url: Scalars['String'];
};

export type S3PresignedUrlResponse = {
  __typename?: 'S3PresignedUrlResponse';
  fields?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  url: Scalars['String'];
};

export type ScAutomationToolsReport = {
  __typename?: 'SCAutomationToolsReport';
  created_at?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Scalars['String']>;
  from_date?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Int']>;
  manual?: Maybe<Scalars['Boolean']>;
  merchant?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['Date']>;
};

export type ScAutomationToolsReportFileUrl = {
  __typename?: 'SCAutomationToolsReportFileUrl';
  url: Scalars['String'];
};

export type ScAutomationToolsReportId = {
  __typename?: 'SCAutomationToolsReportId';
  report_id: Scalars['Int'];
};

export type ScAutomationToolsTaskStatus = {
  __typename?: 'SCAutomationToolsTaskStatus';
  status: Scalars['String'];
};

export enum SalesChannel {
  Amazon = 'AMAZON',
  Shopify = 'SHOPIFY',
  Target = 'TARGET',
  Tiktok = 'TIKTOK',
  Walmart = 'WALMART'
}

export type Segment = Timestamped & {
  __typename?: 'Segment';
  applicableEndDateColumnName?: Maybe<Scalars['String']>;
  applicableStartDateColumnName?: Maybe<Scalars['String']>;
  channel?: Maybe<SalesChannel>;
  channelColumnName?: Maybe<Scalars['String']>;
  channelIdColumnName: Scalars['String'];
  createdOn: Scalars['DateTime'];
  endDateColumnName?: Maybe<Scalars['String']>;
  fullStoreNameColumnName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastSuccessfulSync?: Maybe<SnowflakeDataSourceSyncHistory>;
  lastSync?: Maybe<SnowflakeDataSourceSyncHistory>;
  market?: Maybe<MarketCode>;
  marketColumnName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  refreshTriggers?: Maybe<Array<SegmentRefreshTrigger>>;
  snowflakeDatabaseName: Scalars['String'];
  snowflakeRoleName?: Maybe<Scalars['String']>;
  snowflakeSchemaName: Scalars['String'];
  snowflakeTableName: Scalars['String'];
  startDateColumnName?: Maybe<Scalars['String']>;
  thrasioProductIdColumnName?: Maybe<Scalars['String']>;
  updatedOn: Scalars['DateTime'];
  whereClause?: Maybe<Scalars['String']>;
};

export type SegmentRefreshTrigger = Timestamped & {
  __typename?: 'SegmentRefreshTrigger';
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  refreshType: RefreshType;
  scheduleScalar: Scalars['Int'];
  scheduleUnit: RefreshScheduleUnit;
  updatedOn: Scalars['DateTime'];
};

export type SegmentToolSettings = {
  __typename?: 'SegmentToolSettings';
  segmentId: Scalars['UUID'];
  segmentName: Scalars['String'];
  toolSettings: Array<ToolSetting>;
};

export type SegmentToolSettingsInput = {
  segmentId: Scalars['UUID'];
  toolSettings: Array<ToolSettingInput>;
};

export type SegmentsResponse = Pageable & {
  __typename?: 'SegmentsResponse';
  results: Array<Segment>;
  totalResults: Scalars['Int'];
};

export type SellerAccountInfo = {
  __typename?: 'SellerAccountInfo';
  name: Scalars['String'];
  remainingStorage: Scalars['Int'];
};

export type ShortGroupedStore = {
  __typename?: 'ShortGroupedStore';
  diligence?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  status: Scalars['String'];
};

export type ShortGroupedStoreResponse = Pageable & {
  __typename?: 'ShortGroupedStoreResponse';
  results: Array<ShortGroupedStore>;
  totalResults: Scalars['Int'];
};

/** Upload Signed URL result wrapper type */
export type SignedUploadUrl = {
  __typename?: 'SignedUploadUrl';
  fields: Scalars['String'];
  key: Scalars['String'];
  url: Scalars['String'];
};

export type SkuDimension = {
  __typename?: 'SkuDimension';
  createdAt: Scalars['DateTime'];
  dimension: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  rawSku: RawSku;
  unit: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type SkuInfo = {
  __typename?: 'SkuInfo';
  nsSku: Scalars['String'];
  skuType: Scalars['String'];
  tplLocation: Scalars['String'];
  tplOhQuantity: Scalars['Int'];
  tplSku: Scalars['String'];
  tplStatus: Scalars['String'];
};

export type SkuInfoResponse = {
  __typename?: 'SkuInfoResponse';
  errors: Scalars['String'];
  skuList: Array<SkuInfo>;
  success: Scalars['Boolean'];
};

export type SkuTypeResponse = Pageable & {
  __typename?: 'SkuTypeResponse';
  results: Array<ThrasioSkuType>;
  totalResults: Scalars['Int'];
};

export type SlackChannel = {
  __typename?: 'SlackChannel';
  id: Scalars['ID'];
  memberCount?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type SlackChannelSuggestion = BaseTypeaheadSuggestion & {
  __typename?: 'SlackChannelSuggestion';
  channel?: Maybe<SlackChannel>;
  displayText: Scalars['String'];
  key: Scalars['String'];
};

export type SlackChannelTypeaheadResponse = BaseTypeaheadResponse & {
  __typename?: 'SlackChannelTypeaheadResponse';
  prefixes: Array<Scalars['String']>;
  suggestions: Array<SlackChannelSuggestion>;
};

export type SnowflakeDataSourceSyncHistory = {
  __typename?: 'SnowflakeDataSourceSyncHistory';
  status: Scalars['String'];
  syncEndDateTime?: Maybe<Scalars['DateTime']>;
  syncStartDateTime: Scalars['DateTime'];
  syncTrigger: Scalars['String'];
};

export type SortDescriptor = {
  direction: SortDirection;
  field: Scalars['String'];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SourceTypeResponse = Pageable & {
  __typename?: 'SourceTypeResponse';
  results: Array<BarCodeSourceType>;
  totalResults: Scalars['Int'];
};

export type SplatTestServiceExample = ActiveTimestamped & {
  __typename?: 'SplatTestServiceExample';
  active: Scalars['Boolean'];
  content: Scalars['String'];
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedOn: Scalars['DateTime'];
};

export type SplatTestServiceExampleResponse = Pageable & {
  __typename?: 'SplatTestServiceExampleResponse';
  results: Array<SplatTestServiceExample>;
  totalResults: Scalars['Int'];
};

export type StatusWithResults = {
  __typename?: 'StatusWithResults';
  result?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type Store = {
  __typename?: 'Store';
  channel: ProductCatalogChannel;
  createdAt: Scalars['DateTime'];
  diligence?: Maybe<Scalars['Boolean']>;
  divestedDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  market: Market;
  name: Scalars['String'];
  storeStatus?: Maybe<StoreStatus>;
  updatedAt: Scalars['DateTime'];
};

export type StoreMarketplace = {
  __typename?: 'StoreMarketplace';
  countryCode?: Maybe<Scalars['String']>;
  countryIsoCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  mwsMarketplaceId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
};

export type StoreStatus = {
  __typename?: 'StoreStatus';
  name: Scalars['String'];
};

export type StoreUpdate = {
  divestedDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  storeStatus?: InputMaybe<Scalars['String']>;
};

export type StoreUpdateResponse = {
  __typename?: 'StoreUpdateResponse';
  error?: Maybe<Scalars['String']>;
  results?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type StoresResponse = Pageable & {
  __typename?: 'StoresResponse';
  results: Array<Store>;
  totalResults: Scalars['Int'];
};

export type StrListResponse = {
  __typename?: 'StrListResponse';
  errors: Array<Scalars['String']>;
  results: Array<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type StrategicTactic = {
  __typename?: 'StrategicTactic';
  creator: InternalUser;
  id: Scalars['ID'];
  lastEditor: InternalUser;
  listingObjective: ListingObjective;
  notes?: Maybe<Scalars['String']>;
  status: TacticStatus;
  tactic: Scalars['String'];
  targetCompletionDate: Scalars['Date'];
};

export type StrategicTacticAddInput = {
  listingObjectiveId: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  status: TacticStatus;
  tactic: Scalars['String'];
  targetCompletionDate: Scalars['Date'];
};

export type StrategicTacticUpdateInput = {
  id: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TacticStatus>;
  tactic?: InputMaybe<Scalars['String']>;
  targetCompletionDate?: InputMaybe<Scalars['Date']>;
};

export enum SubscriptionFrequency {
  Daily = 'DAILY',
  Immediately = 'IMMEDIATELY',
  Weekly = 'WEEKLY'
}

export enum SyndicationBatchRequestStatus {
  Completed = 'COMPLETED',
  Failure = 'FAILURE',
  Initiated = 'INITIATED',
  New = 'NEW',
  Submitted = 'SUBMITTED'
}

export enum SyndicationReferenceField {
  ChannelId = 'CHANNEL_ID',
  Sku = 'SKU'
}

export enum SyndicationRequestType {
  BulkUpload = 'BULK_UPLOAD',
  Incremental = 'INCREMENTAL',
  ManuallyTriggered = 'MANUALLY_TRIGGERED',
  PricingEngine = 'PRICING_ENGINE'
}

export enum SyndicationSuppressionReason {
  ActivePromotion = 'ACTIVE_PROMOTION',
  Discontinued = 'DISCONTINUED',
  NoPriceChange = 'NO_PRICE_CHANGE',
  NoRecentPrices = 'NO_RECENT_PRICES'
}

export type SyndicationUploadError = {
  __typename?: 'SyndicationUploadError';
  error: Scalars['String'];
  listing: PricingCatalogListing;
};

export enum SyndicationVerificationFailureReason {
  ApiRetriesExceeded = 'API_RETRIES_EXCEEDED',
  IncorrectPrice = 'INCORRECT_PRICE',
  PriceNotFound = 'PRICE_NOT_FOUND',
  StoreNotFound = 'STORE_NOT_FOUND'
}

export enum SyndicationVerificationStatus {
  Failure = 'FAILURE',
  Ignore = 'IGNORE',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export enum TaMarkets {
  Ae = 'ae',
  Au = 'au',
  Br = 'br',
  Ca = 'ca',
  Com = 'com',
  De = 'de',
  Es = 'es',
  Fr = 'fr',
  In = 'in',
  It = 'it',
  Jp = 'jp',
  Mx = 'mx',
  Nlv = 'nlv',
  Pl = 'pl',
  Sav = 'sav',
  Se = 'se',
  Sg = 'sg',
  Tr = 'tr',
  Uk = 'uk'
}

export type ToValidationError = {
  __typename?: 'TOValidationError';
  errorText: Scalars['String'];
  reason: ToValidationFailureReason;
};

export enum ToValidationFailureReason {
  Notenoughinventory = 'NOTENOUGHINVENTORY',
  Skudoesnotexist = 'SKUDOESNOTEXIST'
}

export type ToValidationInput = {
  file: Scalars['String'];
};

export type ToValidationRequestResults = {
  __typename?: 'TOValidationRequestResults';
  results: Array<ToValidationResult>;
};

export type ToValidationResult = {
  __typename?: 'TOValidationResult';
  error?: Maybe<ToValidationError>;
  sku: Scalars['String'];
  success: Scalars['Boolean'];
};

export type TpidMissingAmazonChannel = {
  __typename?: 'TPIDMissingAmazonChannel';
  acquiredBrand?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  channelMarket?: Maybe<Scalars['String']>;
  channelSku?: Maybe<Scalars['String']>;
  fulfillmentChannel?: Maybe<Scalars['String']>;
  fullStoreName?: Maybe<Scalars['String']>;
  thrasioProductId?: Maybe<Scalars['Float']>;
};

export type TpidMissingAmazonChannelResponse = Pageable & {
  __typename?: 'TPIDMissingAmazonChannelResponse';
  results: Array<TpidMissingAmazonChannel>;
  totalResults: Scalars['Int'];
};

export enum TacticStatus {
  Actionable = 'ACTIONABLE',
  Archived = 'ARCHIVED',
  Blocked = 'BLOCKED',
  Complete = 'COMPLETE',
  InPlanning = 'IN_PLANNING',
  InProgress = 'IN_PROGRESS'
}

export type Team = {
  __typename?: 'Team';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TempTest = {
  __typename?: 'TempTest';
  id?: Maybe<Scalars['ID']>;
  source: Scalars['String'];
  sourceType: Scalars['String'];
  type: Scalars['String'];
  value: Scalars['String'];
};

export type TextChangeEventData = {
  __typename?: 'TextChangeEventData';
  fromValue?: Maybe<Scalars['String']>;
  toValue?: Maybe<Scalars['String']>;
};

export type TextListChangeEventData = {
  __typename?: 'TextListChangeEventData';
  fromValue: Array<Maybe<Scalars['String']>>;
  toValue: Array<Maybe<Scalars['String']>>;
};

/** Thrasio Product ID */
export type ThrasioProductId = {
  tpid: Scalars['ID'];
};

export type ThrasioSku = {
  __typename?: 'ThrasioSku';
  createdAt: Scalars['DateTime'];
  dateIntroduced?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  status: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type ThrasioSkuRawSkuBulkMappingUpdateResponse = {
  __typename?: 'ThrasioSkuRawSkuBulkMappingUpdateResponse';
  error?: Maybe<Scalars['String']>;
  results?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ThrasioSkuResponse = Pageable & {
  __typename?: 'ThrasioSkuResponse';
  results: Array<ThrasioSku>;
  totalResults: Scalars['Int'];
};

export type ThrasioSkuStatus = {
  __typename?: 'ThrasioSkuStatus';
  name: Scalars['String'];
};

export type ThrasioSkuStatusResponse = Pageable & {
  __typename?: 'ThrasioSkuStatusResponse';
  results: Array<ThrasioSkuStatus>;
  totalResults: Scalars['Int'];
};

export type ThrasioSkuType = {
  __typename?: 'ThrasioSkuType';
  name: Scalars['String'];
};

export type ThrasioSkuUpdate = {
  dateIntroduced?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
};

export type ThrasioSkuUpdateResponse = {
  __typename?: 'ThrasioSkuUpdateResponse';
  error?: Maybe<Scalars['String']>;
  results?: Maybe<Array<ThrasioSku>>;
  success: Scalars['Boolean'];
};

export type Timestamped = {
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedOn: Scalars['DateTime'];
};

export type ToolSetting = {
  __typename?: 'ToolSetting';
  active: Scalars['Boolean'];
  id: Scalars['UUID'];
  ruleName: Scalars['String'];
  ruleTypeName: Scalars['String'];
  valueFloat?: Maybe<Scalars['Float']>;
  valueInt?: Maybe<Scalars['Int']>;
  valueString?: Maybe<Scalars['String']>;
  valueType: Scalars['String'];
};

export type ToolSettingInput = {
  active: Scalars['Boolean'];
  id: Scalars['UUID'];
  valueFloat?: InputMaybe<Scalars['Float']>;
  valueInt?: InputMaybe<Scalars['Int']>;
  valueString?: InputMaybe<Scalars['String']>;
  valueType: Scalars['String'];
};

export type TpidResult = {
  __typename?: 'TpidResult';
  channelKey: Scalars['String'];
  message: Scalars['String'];
  oldTpid: Scalars['String'];
  success: Scalars['Boolean'];
};

export type TpmlDocumentResponse = {
  __typename?: 'TpmlDocumentResponse';
  errors: Array<Scalars['String']>;
  success: Scalars['Boolean'];
  url: Scalars['String'];
};

export type TriggerInputDataRefreshInput = {
  inputDataSourceId: Scalars['ID'];
};

export type TriggerProductReportingSubscriptionResponse = BasicMutationResponseInterface & {
  __typename?: 'TriggerProductReportingSubscriptionResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
};

export type TriggerSegmentRefreshInput = {
  segmentId: Scalars['ID'];
};

export type TriggerUnitEconRefreshInput = {
  unitEconDataSourceId: Scalars['ID'];
};

export type TurnOffHoldingBinEntriesInput = {
  ids: Array<Scalars['ID']>;
  level: HoldingBinEntryTurnOffLevel;
};

export type TypeaheadUploadData = {
  displayText: Scalars['String'];
  key: Scalars['String'];
  searchText?: InputMaybe<Scalars['String']>;
};

export type TypeaheadUploadInternalUsersTaskState = {
  __typename?: 'TypeaheadUploadInternalUsersTaskState';
  state: Scalars['String'];
  userDownloadCount?: Maybe<Scalars['Int']>;
};

export type TypeaheadUploadSlackChannelsTaskState = {
  __typename?: 'TypeaheadUploadSlackChannelsTaskState';
  slackChannelsCount?: Maybe<Scalars['Int']>;
  state: Scalars['String'];
};

export type UnitEcon = Timestamped & {
  __typename?: 'UnitEcon';
  amzFbaFee?: Maybe<Scalars['Float']>;
  cogs?: Maybe<Scalars['Float']>;
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  inboundHandlingReplacement?: Maybe<Scalars['Float']>;
  inboundHandlingVasReplacement?: Maybe<Scalars['Float']>;
  middleMileReplacement?: Maybe<Scalars['Float']>;
  outboundHandlingReplacement?: Maybe<Scalars['Float']>;
  outboundHandlingVasReplacement?: Maybe<Scalars['Float']>;
  priceCalcTarget: Scalars['ID'];
  pricingCogs?: Maybe<Scalars['Float']>;
  referralPercentage?: Maybe<Scalars['Float']>;
  returnRate?: Maybe<Scalars['Float']>;
  storageTotal?: Maybe<Scalars['Float']>;
  updatedOn: Scalars['DateTime'];
};

export type UnitEconDataSource = Timestamped & {
  __typename?: 'UnitEconDataSource';
  amzFbaFeeColumnName?: Maybe<Scalars['String']>;
  applicableEndDateColumnName?: Maybe<Scalars['String']>;
  applicableStartDateColumnName?: Maybe<Scalars['String']>;
  channel?: Maybe<SalesChannel>;
  channelColumnName?: Maybe<Scalars['String']>;
  channelIdColumnName: Scalars['String'];
  cogsColumnName?: Maybe<Scalars['String']>;
  cogsDiscountFactorColumnName?: Maybe<Scalars['String']>;
  cogsInflationFactorColumnName?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  endDateColumnName?: Maybe<Scalars['String']>;
  fullStoreNameColumnName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inboundHandlingReplacementColumnName?: Maybe<Scalars['String']>;
  inboundHandlingVasReplacementColumnName?: Maybe<Scalars['String']>;
  lastSuccessfulSync?: Maybe<SnowflakeDataSourceSyncHistory>;
  lastSync?: Maybe<SnowflakeDataSourceSyncHistory>;
  market?: Maybe<MarketCode>;
  marketColumnName?: Maybe<Scalars['String']>;
  middleMileReplacementColumnName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  outboundHandlingReplacementColumnName?: Maybe<Scalars['String']>;
  outboundHandlingVasReplacementColumnName?: Maybe<Scalars['String']>;
  pricingCogsColumnName?: Maybe<Scalars['String']>;
  referralPercentageColumnName?: Maybe<Scalars['String']>;
  returnRateColumnName?: Maybe<Scalars['String']>;
  snowflakeDatabaseName: Scalars['String'];
  snowflakeRoleName?: Maybe<Scalars['String']>;
  snowflakeSchemaName: Scalars['String'];
  snowflakeTableName: Scalars['String'];
  startDateColumnName?: Maybe<Scalars['String']>;
  storageTotalColumnName?: Maybe<Scalars['String']>;
  thrasioProductIdColumnName?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedOn: Scalars['DateTime'];
  whereClause?: Maybe<Scalars['String']>;
};

export type UnitEconDataSourcesResponse = Pageable & {
  __typename?: 'UnitEconDataSourcesResponse';
  results: Array<UnitEconDataSource>;
  totalResults: Scalars['Int'];
};

export type UnknownChangeEventData = {
  __typename?: 'UnknownChangeEventData';
  fromValue?: Maybe<Scalars['String']>;
  toValue?: Maybe<Scalars['String']>;
};

export type UpdateCalculatedPriceOverrideCommentInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  comment?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type UpdateCalculatedPriceOverrideInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  bypassIncrementalSyndication?: InputMaybe<Scalars['Boolean']>;
  comment?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  end?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  price?: InputMaybe<Scalars['Decimal']>;
};

export type UpdateHoldingBinEntryCommentInput = {
  comment: Scalars['String'];
  id: Scalars['ID'];
};

export type UpdateIndividualPriceAdjustmentInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  calcPriceChangeActionType?: InputMaybe<CalculationActionType>;
  calcPriceChangeAmount?: InputMaybe<Scalars['Float']>;
  calcPriceChangeAppliesTo?: InputMaybe<CalculationAppliesTo>;
  calcPriceChangeInputDataSourceId?: InputMaybe<Scalars['ID']>;
  calcPriceChangeInputDataSourceValueId?: InputMaybe<Scalars['ID']>;
  calcPriceChangeInputValueMultiplier?: InputMaybe<Scalars['Float']>;
  checkTarget?: InputMaybe<ConditionalPriceCheckTarget>;
  checkType?: InputMaybe<ConditionalPriceCheckType>;
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  maxValue?: InputMaybe<Scalars['Float']>;
  maxValueInputDataSourceId?: InputMaybe<Scalars['ID']>;
  maxValueInputDataSourceValueId?: InputMaybe<Scalars['ID']>;
  maxValueMultiplier?: InputMaybe<Scalars['Float']>;
  minValue?: InputMaybe<Scalars['Float']>;
  minValueInputDataSourceId?: InputMaybe<Scalars['ID']>;
  minValueInputDataSourceValueId?: InputMaybe<Scalars['ID']>;
  minValueMultiplier?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  negateRequiredRules?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  priceMarginFormula?: InputMaybe<PriceMarginFormula>;
  requiredRuleIds?: InputMaybe<Array<Scalars['String']>>;
  ruleSetEntityFilterId?: InputMaybe<Scalars['ID']>;
  ruleSetId?: InputMaybe<Scalars['ID']>;
  skipIfMissingData?: InputMaybe<Scalars['Boolean']>;
  variableAssignments?: InputMaybe<Array<CreateUpdateVariableAssignmentInput>>;
};

export type UpdateInputDataSourceInput = {
  applicableEndDateColumnName?: InputMaybe<Scalars['String']>;
  applicableStartDateColumnName?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<SalesChannel>;
  channelColumnName?: InputMaybe<Scalars['String']>;
  channelIdColumnName?: InputMaybe<Scalars['String']>;
  endDateColumnName?: InputMaybe<Scalars['String']>;
  fullStoreNameColumnName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inputDataSourceValues?: InputMaybe<Array<CreateOrUpdateInputDataSourceValueInput>>;
  market?: InputMaybe<MarketCode>;
  marketColumnName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  refreshTriggers?: InputMaybe<Array<RefreshTriggerInput>>;
  snowflakeDatabaseName?: InputMaybe<Scalars['String']>;
  snowflakeRoleName?: InputMaybe<Scalars['String']>;
  snowflakeSchemaName?: InputMaybe<Scalars['String']>;
  snowflakeTableName?: InputMaybe<Scalars['String']>;
  startDateColumnName?: InputMaybe<Scalars['String']>;
  thrasioProductIdColumnName?: InputMaybe<Scalars['String']>;
  whereClause?: InputMaybe<Scalars['String']>;
};

export type UpdateListingStatusMutationResponse = {
  __typename?: 'UpdateListingStatusMutationResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type UpdateParticipationStatusInput = {
  updates: Array<UpdateParticipationStatusItem>;
};

export type UpdateParticipationStatusItem = {
  listingId: Scalars['String'];
  participationStatus: PriceParticipationStatus;
};

export type UpdateParticipationStatusResponse = {
  __typename?: 'UpdateParticipationStatusResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  updatedListings: Array<PricingCatalogListing>;
};

export type UpdatePriceCalculationTargetGroupInput = {
  groupFollowerIds: Array<Scalars['ID']>;
  id: Scalars['ID'];
};

export type UpdatePriceGuardrailInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  checkTarget?: InputMaybe<ConditionalPriceCheckTarget>;
  checkType?: InputMaybe<ConditionalPriceCheckType>;
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  maxValue?: InputMaybe<Scalars['Float']>;
  maxValueInputDataSourceId?: InputMaybe<Scalars['ID']>;
  maxValueInputDataSourceValueId?: InputMaybe<Scalars['ID']>;
  maxValueMultiplier?: InputMaybe<Scalars['Float']>;
  minValue?: InputMaybe<Scalars['Float']>;
  minValueInputDataSourceId?: InputMaybe<Scalars['ID']>;
  minValueInputDataSourceValueId?: InputMaybe<Scalars['ID']>;
  minValueMultiplier?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  negateRequiredRules?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  priceMarginFormula?: InputMaybe<PriceMarginFormula>;
  requiredRuleIds?: InputMaybe<Array<Scalars['String']>>;
  ruleSetEntityFilterId?: InputMaybe<Scalars['ID']>;
  ruleSetId?: InputMaybe<Scalars['ID']>;
  skipIfMissingData?: InputMaybe<Scalars['Boolean']>;
  variableAssignments?: InputMaybe<Array<CreateUpdateVariableAssignmentInput>>;
};

export type UpdateProductEventResponse = GenericError | ProductEventUpdated;

export type UpdateRuleInput = {
  actionType?: InputMaybe<CalculationActionType>;
  active?: InputMaybe<Scalars['Boolean']>;
  amount?: InputMaybe<Scalars['Decimal']>;
  calcAppliesTo?: InputMaybe<CalculationAppliesTo>;
  calculationAmountInputDataSourceId?: InputMaybe<Scalars['ID']>;
  calculationAmountInputDataSourceValueId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  filterId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  inputDataSourceId?: InputMaybe<Scalars['ID']>;
  inputDataSourceValueId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  participateInPriceFormatting?: InputMaybe<Scalars['Boolean']>;
  skipIfMissingData?: InputMaybe<Scalars['Boolean']>;
  useOptimalMarginFormula?: InputMaybe<Scalars['Boolean']>;
  variableAssignments?: InputMaybe<Array<CreateUpdateVariableAssignmentInput>>;
};

export type UpdateRuleSetEntityFilterInput = {
  channels?: InputMaybe<Array<SalesChannel>>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  marketplaces?: InputMaybe<Array<MarketCode>>;
  name: Scalars['String'];
  negateChannels?: Scalars['Boolean'];
  negateMarketplaces?: Scalars['Boolean'];
  negateSegments?: Scalars['Boolean'];
  segmentIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateRuleSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  alternateUnitEconDataSourceId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  minPriceCalcDenominator?: InputMaybe<Scalars['Decimal']>;
  name?: InputMaybe<Scalars['String']>;
  scheduleParticipation?: InputMaybe<Scalars['String']>;
};

export type UpdateRuleSetScheduleParticipationInput = {
  id: Scalars['ID'];
  scheduleParticipation: RuleSetScheduleParticipation;
};

export type UpdateSegmentInput = {
  applicableEndDateColumnName?: InputMaybe<Scalars['String']>;
  applicableStartDateColumnName?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<SalesChannel>;
  channelColumnName?: InputMaybe<Scalars['String']>;
  channelIdColumnName?: InputMaybe<Scalars['String']>;
  endDateColumnName?: InputMaybe<Scalars['String']>;
  fullStoreNameColumnName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  market?: InputMaybe<MarketCode>;
  marketColumnName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  refreshTriggers?: InputMaybe<Array<RefreshTriggerInput>>;
  snowflakeDatabaseName?: InputMaybe<Scalars['String']>;
  snowflakeRoleName?: InputMaybe<Scalars['String']>;
  snowflakeSchemaName?: InputMaybe<Scalars['String']>;
  snowflakeTableName?: InputMaybe<Scalars['String']>;
  startDateColumnName?: InputMaybe<Scalars['String']>;
  whereClause?: InputMaybe<Scalars['String']>;
};

export type UpdateTpidResponse = {
  __typename?: 'UpdateTPIDResponse';
  error?: Maybe<Scalars['String']>;
  results?: Maybe<ProductTpid>;
  success: Scalars['Boolean'];
};

export type UpdateUnitEconDataSourceInput = {
  amzFbaFeeColumnName?: InputMaybe<Scalars['String']>;
  applicableEndDateColumnName?: InputMaybe<Scalars['String']>;
  applicableStartDateColumnName?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<SalesChannel>;
  channelColumnName?: InputMaybe<Scalars['String']>;
  channelIdColumnName?: InputMaybe<Scalars['String']>;
  cogsColumnName?: InputMaybe<Scalars['String']>;
  cogsDiscountFactorColumnName?: InputMaybe<Scalars['String']>;
  cogsInflationFactorColumnName?: InputMaybe<Scalars['String']>;
  endDateColumnName?: InputMaybe<Scalars['String']>;
  fullStoreNameColumnName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  inboundHandlingReplacementColumnName?: InputMaybe<Scalars['String']>;
  inboundHandlingVasReplacementColumnName?: InputMaybe<Scalars['String']>;
  market?: InputMaybe<MarketCode>;
  marketColumnName?: InputMaybe<Scalars['String']>;
  middleMileReplacementColumnName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  outboundHandlingReplacementColumnName?: InputMaybe<Scalars['String']>;
  outboundHandlingVasReplacementColumnName?: InputMaybe<Scalars['String']>;
  pricingCogsColumnName?: InputMaybe<Scalars['String']>;
  referralPercentageColumnName?: InputMaybe<Scalars['String']>;
  returnRateColumnName?: InputMaybe<Scalars['String']>;
  snowflakeDatabaseName?: InputMaybe<Scalars['String']>;
  snowflakeRoleName?: InputMaybe<Scalars['String']>;
  snowflakeSchemaName?: InputMaybe<Scalars['String']>;
  snowflakeTableName?: InputMaybe<Scalars['String']>;
  startDateColumnName?: InputMaybe<Scalars['String']>;
  storageTotalColumnName?: InputMaybe<Scalars['String']>;
  thrasioProductIdColumnName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  whereClause?: InputMaybe<Scalars['String']>;
};

export type UploadNewDataFilesError = {
  __typename?: 'UploadNewDataFilesError';
  message: Scalars['String'];
  status: Scalars['String'];
};

export type UploadNewDataFilesInput = {
  removePrevious?: InputMaybe<Scalars['Boolean']>;
  validationId: Scalars['Int'];
};

export type UploadNewDataFilesResponse = {
  __typename?: 'UploadNewDataFilesResponse';
  message: Scalars['String'];
};

export type UploadNewDataFilesResponseUploadNewDataFilesError = UploadNewDataFilesError | UploadNewDataFilesResponse;

export type UploadResponse = {
  __typename?: 'UploadResponse';
  created: Scalars['Int'];
  updated: Scalars['Int'];
};

export type UrlChangeEventData = {
  __typename?: 'UrlChangeEventData';
  fromValue?: Maybe<Scalars['String']>;
  toValue?: Maybe<Scalars['String']>;
};

export type UserAssignment = {
  __typename?: 'UserAssignment';
  id: Scalars['ID'];
  pod: Pod;
  role: Role;
  team: Team;
  user?: Maybe<InternalUser>;
  /** Redundant field to have a fallback on userId if the user does not exist */
  userId: Scalars['ID'];
};

export type UserAssignmentCreateInput = {
  podId: Scalars['ID'];
  roleId: Scalars['ID'];
  teamId: Scalars['ID'];
  userId: Scalars['String'];
};

export type UserAssignmentDeleteInput = {
  id: Scalars['ID'];
};

export type UserAssignmentDeleteResult = {
  __typename?: 'UserAssignmentDeleteResult';
  success: Scalars['Boolean'];
};

export type UserAssignmentResult = Pageable & {
  __typename?: 'UserAssignmentResult';
  results: Array<UserAssignment>;
  totalResults: Scalars['Int'];
};

export type UserAssignmentUpdateInput = {
  id: Scalars['ID'];
  podId?: InputMaybe<Scalars['ID']>;
  roleId?: InputMaybe<Scalars['ID']>;
  teamId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UserNotificationConfig = {
  __typename?: 'UserNotificationConfig';
  hmacAuthSignature: Scalars['String'];
  recipientId: Scalars['String'];
};

export type ValidationForBatchUpload = {
  filename?: InputMaybe<Scalars['String']>;
  market?: InputMaybe<Scalars['String']>;
  merchant?: InputMaybe<Scalars['String']>;
};

export type VariableAssignment = ActiveTimestamped & {
  __typename?: 'VariableAssignment';
  active: Scalars['Boolean'];
  assignmentType: VariableAssignmentType;
  constantValue?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  inputDataSource?: Maybe<InputDataSource>;
  inputDataSourceValue?: Maybe<InputDataSourceValue>;
  updatedOn: Scalars['DateTime'];
  variableName: Scalars['String'];
};

export enum VariableAssignmentType {
  Constant = 'CONSTANT',
  InputValue = 'INPUT_VALUE',
  InputValueTag = 'INPUT_VALUE_TAG'
}

export type VariationThemeAttributeResponse = {
  __typename?: 'VariationThemeAttributeResponse';
  result: Scalars['String'];
};

export type VerifyAllGtiNsOutput = {
  __typename?: 'VerifyAllGTINsOutput';
  result: Array<Scalars['String']>;
};

export type VerifyAllGtiNsbyGs1Response = {
  __typename?: 'VerifyAllGTINsbyGS1Response';
  error?: Maybe<Scalars['String']>;
  results?: Maybe<VerifyAllGtiNsOutput>;
  success: Scalars['Boolean'];
};

export type VerifyGtinListOutput = {
  __typename?: 'VerifyGTINListOutput';
  results: Array<VerifyGtinOutput>;
};

export type VerifyGtinOutput = {
  __typename?: 'VerifyGTINOutput';
  barcode: Scalars['String'];
  gs1ClaimedAddress?: Maybe<Scalars['String']>;
  gs1ClaimedOwner?: Maybe<Scalars['String']>;
  rawSku: Scalars['String'];
  thrasioGs1CompanyId: Scalars['ID'];
};

export type VerifyGtiNbyGs1Response = {
  __typename?: 'VerifyGTINbyGS1Response';
  error?: Maybe<Scalars['String']>;
  results?: Maybe<VerifyGtinListOutput>;
  success: Scalars['Boolean'];
};

export type Video = {
  __typename?: 'Video';
  duration: Scalars['Int'];
  height: Scalars['Int'];
  isHeroVideo?: Maybe<Scalars['Boolean']>;
  link: Scalars['String'];
  order: Scalars['Int'];
  thumbnail: Scalars['String'];
  variant: Scalars['String'];
  width: Scalars['Int'];
};

export type VideoChangeEventData = {
  __typename?: 'VideoChangeEventData';
  fromValue: Array<Maybe<Video>>;
  toValue: Array<Maybe<Video>>;
};

export enum Join__Graph {
  AcosTarget = 'ACOS_TARGET',
  ApDataIngestion = 'AP_DATA_INGESTION',
  BidManagement = 'BID_MANAGEMENT',
  CloudCanaryService = 'CLOUD_CANARY_SERVICE',
  PlaidIntegrationService = 'PLAID_INTEGRATION_SERVICE',
  ProductCatalog = 'PRODUCT_CATALOG',
  ProductCatalogAkeneo = 'PRODUCT_CATALOG_AKENEO',
  ProductPricingService = 'PRODUCT_PRICING_SERVICE',
  Psych = 'PSYCH',
  RestGraphqlBridge = 'REST_GRAPHQL_BRIDGE',
  ReviewAudit = 'REVIEW_AUDIT',
  ScProductTestRequests = 'SC_PRODUCT_TEST_REQUESTS',
  ScThrasioProductMovementLibrary = 'SC_THRASIO_PRODUCT_MOVEMENT_LIBRARY',
  ScWorkflowAutomationService = 'SC_WORKFLOW_AUTOMATION_SERVICE',
  SellerCentralAutomationTools = 'SELLER_CENTRAL_AUTOMATION_TOOLS',
  ServicesPlatformTestService = 'SERVICES_PLATFORM_TEST_SERVICE',
  StoresManagement = 'STORES_MANAGEMENT',
  Test = 'TEST',
  Thrasahead = 'THRASAHEAD',
  TmiUserService = 'TMI_USER_SERVICE',
  ToInventory = 'TO_INVENTORY'
}

export enum Link__Purpose {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  Execution = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  Security = 'SECURITY'
}

export type RequestScAutomationToolsReportStatusInput = {
  report_id?: InputMaybe<Scalars['Int']>;
};

export type RequestScAutomationToolsTriggerGenerateReportInput = {
  currency?: InputMaybe<Currency>;
  from_date: Scalars['Date'];
  manual?: InputMaybe<Scalars['Boolean']>;
  merchant: Scalars['String'];
  to_date: Scalars['Date'];
};

export type RequestScAutomationToolsTriggerGenerateReportManualInput = {
  currency?: InputMaybe<Currency>;
  merchant: Scalars['String'];
};
